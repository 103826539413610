import { QueryResult } from "@apollo/client/react/types/types";
import { useBenefitKeyQuery } from "./useBenefitKeyQuery";
import { FIND_MEMBER_QUERY } from "../atoms/Queries";
import {useCoverage} from "./useCoverage";
import {findKeyByValue} from "../enums/FindKeyByValue";
import {Coverage} from "../enums/Coverage";

export function useMember(): QueryResult {
    const coverage = useCoverage()
    const queryResult = useBenefitKeyQuery(FIND_MEMBER_QUERY, { variables: { coverage: findKeyByValue(Coverage, coverage) } })
    const findMember = queryResult.data?.findMember;
    if(findMember) {
        queryResult.data = findMember
    }
    return queryResult;
}
