import React from "react";
import StatusButton, { StatusButtonProps } from "./StatusButton";
import { findKeyByValue, snakeCaseToCapitalized } from "../enums/FindKeyByValue";
import { ClaimStatus } from "../enums/ClaimStatus";
import findStatusButtonTypeFor = ClaimStatus.findStatusButtonTypeFor;
import { Claim } from "../domain/Claim";

interface ClaimStatusButtonProps extends Omit<StatusButtonProps, 'children'> {
    claim: Claim
}

const ClaimStatusButton = (props: ClaimStatusButtonProps) => {
    const claim = props.claim;
    const status = claim.status ?? ClaimStatus.IN_PROGRESS
    const statusKey = findKeyByValue(ClaimStatus, status);
    const statusSuffix = ClaimStatus.NEEDS_MORE_INFORMATION === status
        ? ` from ${Array.from(claim.requestedInformation.keys()).join(' / ')}`
        : '';
    return (
        <StatusButton variant={findStatusButtonTypeFor(status)} {...props}>
            {`${snakeCaseToCapitalized(statusKey)}${statusSuffix}`}
        </StatusButton>
    );
}
export default ClaimStatusButton;
