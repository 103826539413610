import React, { ReactElement, useEffect, useRef } from 'react';
import { PdsHeading, PdsTable } from "@principal/design-system-react";

export type FlatTableProps = {
    title?: string | ReactElement,
    headers: HeaderProps[],
    rows: any[][],
    tableProps?: React.ComponentProps<typeof PdsTable>,
    children?: React.ReactNode
}

export type HeaderProps = {
    header: string,
    otherProps?: any
}

const FlatTable = (props: FlatTableProps) => {
    const tableRef = useRef(null) as React.RefObject<any>

    useEffect(() => {
        const tableInterval = setInterval(() => {
            const table = tableRef.current?.querySelector('table');
            if(table) {
                table.setAttribute('style', 'background-color: white')
                clearInterval(tableInterval)
            }
        }, 10)
        return () => clearInterval(tableInterval)
    }, [tableRef])

    return (
        <>
            <PdsHeading headingTag="h3">{props.title}</PdsHeading>
            <PdsTable {...props.tableProps} ref={tableRef}>
                <table className="pds-c-table">
                    <thead>
                    <tr>
                        {props.headers.map((header, index) =>
                            <th key={index} {...header.otherProps}>{header.header}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {props.rows.map((row, rowIndex) =>
                        <tr key={rowIndex}>
                            {row.map((col, colIndex) =>
                                <td key={colIndex} {...(col?.props ?? {})}>
                                    {col}
                                </td>)}
                        </tr>)}
                    </tbody>
                </table>
            </PdsTable>
        </>
    )
};
export default FlatTable;