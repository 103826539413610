import * as React from 'react';
import { PdsNumberBadge as PdsNumberBadgeElement } from '@principal/design-system';
import { PdsNumberBadgeProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsNumberBadge = createPdsReactComponent<PdsNumberBadgeProps>()({
  tagName: 'pds-number-badge',
  elementClass: PdsNumberBadgeElement,
  react: React,
});
