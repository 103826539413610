import {QueryResult} from "@apollo/client/react/types/types";
import {FIND_ANNOUNCEMENTS_QUERY} from "../atoms/Queries";
import {findKeyByValue} from "../enums/FindKeyByValue";
import {useCoverage} from "./useCoverage";
import {OperationVariables, useQuery} from "@apollo/client";
import {Coverage} from "../enums/Coverage";

export function useAnnouncements(): QueryResult {
    const coverage = useCoverage();
    const {
        loading: announcementsLoading,
        data: announcementsData,
        error: announcementsError,
        ...announcementsProps
    } = useQuery(FIND_ANNOUNCEMENTS_QUERY, {
        variables: {
            coverage: findKeyByValue(Coverage, coverage)
        } as OperationVariables
    })

    return {
        ...announcementsProps,
        loading: announcementsLoading,
        data: announcementsData?.findAnnouncements,
        error: announcementsError
    }
}
