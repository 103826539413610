import { PdsGrid, PdsGridItem } from "@principal/design-system-react";
import React from "react";
import Tab, { TabPanel } from "@pds-react/tab";
import "@pds-react/tab/dist/tab.min.css"
import _ from "lodash";
import { Relationship } from "../../enums/Relationship";
import { findKeyByValue } from "../../enums/FindKeyByValue";
import { Dependent } from "../../domain/Dependent";
import { Member } from "../../domain/Member";
import { PdsSelect } from "@principal/design-system-react";
import { BenefitsProps } from '../Benefits';
import { useMember } from "../../hooks/useMember";
import findRelationshipHeaderFor = Relationship.findRelationshipHeaderFor;
import TerminationAlert from "../../atoms/TerminationAlert";
import LoadingSpinner from "../../atoms/LoadingSpinner";

interface BenefitTabProps extends BenefitsProps {
    headers: string[],
    sectionsByRelationship: Map<Relationship, any[]>
}

const BenefitsTab = (props: BenefitTabProps) => {
    const [selectedHeader, setSelectedHeader] = React.useState(0)

    const { loading: memberLoading, data: member, error: memberError } = useMember()

    if(memberLoading) {
        return <LoadingSpinner/>
    } else if(memberError || !member) {
        console.log('member error', memberError)
        return <p>Could not load member information, please try again later.</p>;
    }

    const dependents = member.dependents || [];
    const spousesAndDomesticPartners = dependents.filter((d: Dependent) => [Relationship.SPOUSE, Relationship.DOMESTIC_PARTNER].includes(d.relationship));
    const children = dependents.filter((d: Dependent) => Relationship.CHILD === d.relationship);
    const childrenNames = children.map((d: Dependent) => buildName(d)).join(', ')

    const memberBenefits = buildBenefitsFor(member, buildName(member));
    const spouseAndDomesticPartnerBenefits = spousesAndDomesticPartners.map((d: Dependent) => buildBenefitsFor(d, buildName(d)));
    const childrenBenefits = children.length && buildBenefitsFor(children[0], childrenNames);
    const allBenefits = [memberBenefits, spouseAndDomesticPartnerBenefits, childrenBenefits].filter(b => b)

    return (
        <Tab variation="justified">
            {allBenefits}
        </Tab>
    )

    function buildBenefitsFor(memberOrDependent: Member | Dependent, name: string) {
        if(!memberOrDependent.benefit) {
            return null;
        }
        const relationship = (memberOrDependent as Dependent).relationship || Relationship.YOURSELF
        if(props.relationship !== undefined && props.relationship !== relationship) {
            return null;
        }
        const relationshipKey = findKeyByValue(Relationship, relationship);
        const kebabRelationship = _.kebabCase(relationshipKey);
        const sections = props.sectionsByRelationship.get(relationship) as any[]
        return <TabPanel buttonId={`${kebabRelationship}-button`} panelId={`${kebabRelationship}-panel`}
                         label={<><strong>{findRelationshipHeaderFor(relationship)}</strong>
                             <div>{name}</div>
                         </> as any as string}>
            <div className="flex-gap">
                <TerminationAlert benefit={memberOrDependent.benefit} employmentStatus={(memberOrDependent as Member).employment?.status}/>
                <PdsGrid variant="2up">
                    <PdsGridItem>
                        <PdsSelect id={`${kebabRelationship}-benefit-select`} name={`${_.camelCase(relationshipKey)}BenefitSelect`}
                                   label="Benefit" hideLabel={true}
                                   onChange={(e: Event) => {
                                       const value = (e.target as any)?.value;
                                       if (!value) {
                                           return;
                                       }
                                       setSelectedHeader(props.headers.indexOf(value))
                                   }}
                                   value={props.headers[selectedHeader]}>
                            {props.headers.map((header: string) => (
                                <option value={header} key={header}>{header}</option>
                            ))}
                        </PdsSelect>
                    </PdsGridItem>
                </PdsGrid>
                {sections[selectedHeader]}
            </div>
        </TabPanel>
    }

    function buildName(named: Member | Dependent) {
        if(!named) {
            return ''
        }
        return `${named.firstName} ${named.lastName}`;
    }
}
export default BenefitsTab;
