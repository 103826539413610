import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { webAnalytics } from "@principal/web-analytics";

var _ = require('lodash');

export function addPageView(label: string, initialRender?: React.MutableRefObject<boolean>) {
    const pathname = window.location.pathname;
    const camelCaseLabel = label ? `/${_.camelCase(label)}` : '';
    const pageView = {
        content: {
            businessline: 'benefits-and-protection',
            intendedaudience: 'individual',
            purpose: 'engagement',
        },
        page: {
            author: '',
            compliancedate: '',
            contractid: '',
            documenttype: 'single-page-application',
            language: 'english',
            memberid: '',
            publishdate: '',
        },
    } as any

    if(initialRender?.current) {
        webAnalytics.trackEvent('pageViewLoaded', pageView);
        initialRender.current = false;
    } else {
        webAnalytics.trackEvent('virtualPageChange', {
            virtualpagedetails: {
                pagepath: `${pathname}${camelCaseLabel}`,
                pagetitle: document.title,
            },
            ...pageView,
        })
    }
}

const PageViewMetric = () => {
    let location = useLocation();
    const initialRender = useRef(true);

    useEffect(
        () => {
            addPageView('', initialRender)
        },
        [location]
    )

    return (<></>)
};
export default PageViewMetric;