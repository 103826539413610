import { DisabilityClaim } from "../domain/claims/disability/DisabilityClaim";
import { ClaimStatus } from "../enums/ClaimStatus";
import { BenefitDecisionType } from "../enums/claims/disability/BenefitDecisionType";
import _ from "lodash";
import { PremiumPaymentType } from "../enums/claims/disability/PremiumPaymentType";
import { SourceSystem } from "../enums/claims/disability/SourceSystem";
import { BenefitType } from "../enums/claims/disability/BenefitType";
import { RequestedInformation } from "../enums/RequestedInformation";
import { Party } from "../enums/Party";
import isWellness = BenefitType.isWellness;
import isLife = BenefitType.isLife;

export function findClaimStatusFor(claim: DisabilityClaim, sourceSystem: SourceSystem): ClaimStatus {
    const benefits = claim.benefits;
    const hasApproval = benefits.some(b => [BenefitDecisionType.APPROVED, BenefitDecisionType.PARTIALLY_DENIED].includes(b.memberStatusIndicator as BenefitDecisionType))
    if(hasApproval) {
        return ClaimStatus.APPROVED;
    }
    const hasAllDenials = benefits.every(b => b.memberStatusIndicator === BenefitDecisionType.DENIED)
    if(hasAllDenials) {
        return ClaimStatus.DENIED
    }
    if(isClaimsService(sourceSystem) && !claim.assignedUserId) {
        return ClaimStatus.PENDING_REVIEW
    }
    const requestedInformationByParty = findRequestedInformationFor(claim, sourceSystem);
    if(Array.from(requestedInformationByParty.values()).some(requestedInformation => requestedInformation.length)) {
        return ClaimStatus.NEEDS_MORE_INFORMATION
    }
    return ClaimStatus.IN_PROGRESS
}

export function findRequestedInformationFor(claim: DisabilityClaim, sourceSystem: SourceSystem): Map<Party, RequestedInformation[]> {
    const benefits = claim.benefits;
    const isWellnessClaim = benefits.every(b => isWellness(b.type));
    const requestedInformation = new Map<Party, RequestedInformation[]>();
    if(!isClaimsService(sourceSystem) || isWellnessClaim) {
        return requestedInformation;
    }
    const occupation = claim.occupation;
    const isLifeClaim = benefits.every(b => isLife(b.type));
    const requestedEmployerInformation = []
    const isHoursWorkedMissing = !isLifeClaim && _.isNil(occupation.hoursWorkedPerWeek);
    if(isHoursWorkedMissing) {
        requestedEmployerInformation.push(RequestedInformation.HOURS_WORKED_PER_WEEK)
    }
    const nonLifeBenefits = benefits.filter(b => !isLife(b.type))
    const isEmployerContributionMissing = nonLifeBenefits.some(b => _.isNil(b.employerContributionPercentage));
    if(isEmployerContributionMissing) {
        requestedEmployerInformation.push(RequestedInformation.EMPLOYER_CONTRIBUTION_PERCENTAGE)
    }
    const isPremiumPaymentMissing = nonLifeBenefits.some(b => PremiumPaymentType.UNKNOWN === b.premiumPaymentType);
    if(isPremiumPaymentMissing) {
        requestedEmployerInformation.push(RequestedInformation.PREMIUM_PAYMENT_TYPE)
    }
    if(requestedEmployerInformation.length) {
        requestedInformation.set(Party.EMPLOYER, requestedEmployerInformation);
    }
    return requestedInformation;
}

function isClaimsService(sourceSystem: SourceSystem) {
    return SourceSystem.CLAIMS_SERVICE === sourceSystem;
}