import { BenefitType } from "./claims/disability/BenefitType";
import { findMapKeyByValue, findValueByKey } from "./FindKeyByValue";
import { Path } from "./Path";
import { findAdobeSignLinkFor } from "../service/FormService";
import {
    accidentBenefitQueryPart,
    criticalIllnessBenefitQueryPart,
    hospitalIndemnityBenefitQueryPart,
    lifeBenefitQueryPart,
    longTermDisabilityBenefitQueryPart,
    paidFamilyLeaveBenefitQueryPart,
    paidMedicalLeaveBenefitQueryPart,
    shortTermDisabilityBenefitQueryPart,
    voluntaryTermLifeBenefitQueryPart
} from "../atoms/Queries";
import {
    Accident,
    AccidentalDeathAndDismemberment,
    CriticalIllness,
    HospitalIndemnityBenefits,
    Maternity,
    Portability,
    Product,
    ShortTermDisability
} from "benefit-service-types";
import {
    HospitalIndemnityPortability
} from "benefit-service-types/lib/app/src/domain/enums/HospitalIndemnity/HospitalIndemnityPortability";
import { HospitalIndemnityMaternity } from "benefit-service-types/lib/app/src/domain/enums/HospitalIndemnity/HospitalIndemnityMaternity";
import { Member } from "../domain/Member";
import { State } from "./State";
import { SourceSystem } from "./claims/disability/SourceSystem";

export enum Coverage {
    ACCIDENT = 'accident',
    CRITICAL_ILLNESS = 'criticalIllness',
    HOSPITAL_INDEMNITY = 'hospitalIndemnity',
    LIFE = 'life',
    LONG_TERM_DISABILITY = 'longTerm',
    PAID_FAMILY_LEAVE = 'paidFamily',
    PAID_MEDICAL_LEAVE = 'paidMedical',
    SHORT_TERM_DISABILITY = 'shortTerm',
    VOLUNTARY_TERM_LIFE = 'voluntaryLife',
    WELLNESS = 'wellness'
}

const namesByCoverage = new Map([
    [Coverage.ACCIDENT, 'Accident'],
    [Coverage.CRITICAL_ILLNESS, 'Critical illness'],
    [`${Coverage.CRITICAL_ILLNESS}_NY`, 'Specified disease'],
    [Coverage.HOSPITAL_INDEMNITY, 'Hospital indemnity'],
    [Coverage.LIFE, 'Life'],
    [Coverage.LONG_TERM_DISABILITY, 'Long-term disability'],
    [Coverage.PAID_FAMILY_LEAVE, 'Paid family leave'],
    [Coverage.PAID_MEDICAL_LEAVE, 'Paid medical leave'],
    [Coverage.SHORT_TERM_DISABILITY, 'Short-term disability'],
    [Coverage.VOLUNTARY_TERM_LIFE, 'Voluntary term life'],
    [Coverage.WELLNESS, 'Wellness'],
])

const benefitTypesByCoverage = new Map([
    [Coverage.ACCIDENT, [BenefitType.ACCIDENT, BenefitType.ACCIDENT_WELLNESS]],
    [Coverage.CRITICAL_ILLNESS, [BenefitType.CRITICAL_ILLNESS, BenefitType.CRITICAL_ILLNESS_WELLNESS]],
    [Coverage.HOSPITAL_INDEMNITY, [BenefitType.HOSPITAL_INDEMNITY, BenefitType.HOSPITAL_INDEMNITY_WELLNESS]],
    [Coverage.LIFE, [BenefitType.LIFE, BenefitType.LIFE_WAIVER]],
    [Coverage.LONG_TERM_DISABILITY, [BenefitType.LONG_TERM_DISABILITY]],
    [Coverage.PAID_FAMILY_LEAVE, [BenefitType.PAID_FAMILY_LEAVE]],
    [Coverage.PAID_MEDICAL_LEAVE, [BenefitType.PAID_MEDICAL_LEAVE]],
    [Coverage.SHORT_TERM_DISABILITY, [BenefitType.SHORT_TERM_DISABILITY]],
    [Coverage.VOLUNTARY_TERM_LIFE, [BenefitType.LIFE, BenefitType.LIFE_WAIVER]],
    [Coverage.WELLNESS, [BenefitType.ACCIDENT_WELLNESS, BenefitType.CRITICAL_ILLNESS_WELLNESS, BenefitType.HOSPITAL_INDEMNITY_WELLNESS]]
])

const contentNavigatorNamesByCoverage = new Map([
    [Coverage.ACCIDENT, 'ACCIDENT'],
    [Coverage.CRITICAL_ILLNESS, 'CRITICAL ILLNESS'],
    [Coverage.HOSPITAL_INDEMNITY, 'HOSPITAL INDEMNITY'],
    [Coverage.LIFE, 'LIFE'],
    [Coverage.LONG_TERM_DISABILITY, 'LONG TERM DISABILITY'],
    [Coverage.PAID_FAMILY_LEAVE, 'PAID FAMILY AND MEDICAL LEAVE'],
    [Coverage.PAID_MEDICAL_LEAVE, 'PAID FAMILY AND MEDICAL LEAVE'],
    [Coverage.SHORT_TERM_DISABILITY, 'SHORT TERM DISABILITY'],
    [Coverage.VOLUNTARY_TERM_LIFE, 'VOLUNTARY TERM LIFE'],
    [`${Coverage.VOLUNTARY_TERM_LIFE}_PORT`, 'PORT VTL']
])

const paperFormsByCoverage = new Map([
    [Coverage.ACCIDENT, 'CBFCIBAA3AAABLblqZhDllwT7GkTrYHTXMCWn6455LOLuMZn-wsoJe9jxtY8fz8EodKo52AQYzxqx4CwK4nI*'],
    [Coverage.CRITICAL_ILLNESS, 'CBFCIBAA3AAABLblqZhCzUGoURH9SSNQqrMAqKzj_O-%20FQcHkmRIMV8fYEiF5Aa8e4k9SLuqz_W2VJf2QbRiw*'],
    [`${Coverage.CRITICAL_ILLNESS}_NY`, 'CBFCIBAA3AAABLblqZhAu87Jv-o771vxnLPKsn5e3utUblXQFxFrA3b-1U_iSbvHJ7HPZJB66im_OuNoGSWs*'],
    [Coverage.HOSPITAL_INDEMNITY, 'CBFCIBAA3AAABLblqZhB_G6JrGolP_cmBssJJWwduQpZJTAYjgfg41UPkzNT9qakQpmt4B_sn6T56Jlc0VGE*'],
    [Coverage.LIFE, 'CBFCIBAA3AAABLblqZhCf576_9p10JW3ThI1aBIXnULKRbN1uEw_S1_NCaxgFBg79J9NvG48kwn5dE_q1pkQ*'],
    [Coverage.LONG_TERM_DISABILITY, 'CBFCIBAA3AAABLblqZhBYQTyK-JEpvn-r04sI8X9U6PZbBXmceSSqgDL1F9rbLehW51wblNIVJoIFjYn_4bg*'],
    [Coverage.PAID_FAMILY_LEAVE, ''],
    [Coverage.PAID_MEDICAL_LEAVE, ''],
    [Coverage.SHORT_TERM_DISABILITY, 'CBFCIBAA3AAABLblqZhBYQTyK-JEpvn-r04sI8X9U6PZbBXmceSSqgDL1F9rbLehW51wblNIVJoIFjYn_4bg*'],
    [Coverage.VOLUNTARY_TERM_LIFE, 'CBFCIBAA3AAABLblqZhCf576_9p10JW3ThI1aBIXnULKRbN1uEw_S1_NCaxgFBg79J9NvG48kwn5dE_q1pkQ*'],
    [Coverage.WELLNESS, 'CBFCIBAA3AAABLblqZhAeNJVViS4QZJXM5GB6UrusNIUEcH__d_eofgaIh8WKPl60gUb3pjfEvtwW7CIUr28*'],
])

const queryPartsByCoverage = new Map([
    [Coverage.ACCIDENT, accidentBenefitQueryPart],
    [Coverage.CRITICAL_ILLNESS, criticalIllnessBenefitQueryPart],
    [Coverage.HOSPITAL_INDEMNITY, hospitalIndemnityBenefitQueryPart],
    [Coverage.LIFE, lifeBenefitQueryPart],
    [Coverage.LONG_TERM_DISABILITY, longTermDisabilityBenefitQueryPart],
    [Coverage.PAID_FAMILY_LEAVE, paidFamilyLeaveBenefitQueryPart],
    [Coverage.PAID_MEDICAL_LEAVE, paidMedicalLeaveBenefitQueryPart],
    [Coverage.SHORT_TERM_DISABILITY, shortTermDisabilityBenefitQueryPart],
    [Coverage.VOLUNTARY_TERM_LIFE, voluntaryTermLifeBenefitQueryPart]
])

const portabilityRatesByCoverage = new Map([
    [Coverage.ACCIDENT, 'GP62260'],
    [Coverage.CRITICAL_ILLNESS, 'GP61077WOC'],
    [`${Coverage.CRITICAL_ILLNESS}_WC`, 'GP61077WC'],
    [Coverage.HOSPITAL_INDEMNITY, null],
    [Coverage.LIFE, null],
    [Coverage.LONG_TERM_DISABILITY, null],
    [Coverage.PAID_FAMILY_LEAVE, null],
    [Coverage.PAID_MEDICAL_LEAVE, null],
    [Coverage.SHORT_TERM_DISABILITY, null],
    [Coverage.VOLUNTARY_TERM_LIFE, null],
])

const claimFormPathsByCoverage = new Map([
    [Coverage.ACCIDENT, 'accident/employee'],
    [Coverage.CRITICAL_ILLNESS, 'criticalIllness/employee'],
    [Coverage.HOSPITAL_INDEMNITY, 'hospitalIndemnity/employee'],
    [Coverage.LIFE, 'life/beneficiary'],
    [Coverage.LONG_TERM_DISABILITY, 'disability/employee'],
    [Coverage.PAID_FAMILY_LEAVE, 'pfml/employee'],
    [Coverage.PAID_MEDICAL_LEAVE, 'pfml/employee'],
    [Coverage.SHORT_TERM_DISABILITY, 'disability/employee'],
    [Coverage.VOLUNTARY_TERM_LIFE, 'life/beneficiary'],
    [Coverage.WELLNESS, 'wellness/employee'],
])

const benefitServiceNameByCoverage = new Map([
    [Coverage.ACCIDENT, 'accident'],
    [Coverage.CRITICAL_ILLNESS, 'criticalIllness'],
    [Coverage.HOSPITAL_INDEMNITY, 'hospitalIndemnity'],
    [Coverage.LIFE, 'groupTermLife'],
    [Coverage.LONG_TERM_DISABILITY, 'longTermDisability'],
    [Coverage.PAID_FAMILY_LEAVE, 'paidFamilyLeave'],
    [Coverage.PAID_MEDICAL_LEAVE, 'paidMedicalLeave'],
    [Coverage.SHORT_TERM_DISABILITY, 'shortTermDisability'],
    [Coverage.VOLUNTARY_TERM_LIFE, 'voluntaryTermLife']
])

export namespace Coverage {
    export function findCoverageNameFor(coverage: Coverage, member: Member): string {
        const nameKey = coverage + findStateSuffixFor(member, coverage);
        return namesByCoverage.get(nameKey) as string;
    }

    export function findOnlineClaimFormUrlFor(coverage: Coverage, member: Member): string {
        if([Coverage.ACCIDENT, Coverage.CRITICAL_ILLNESS].includes(coverage)) {
            return findAdobeSignLinkFor(findPaperClaimFormNumberFor(coverage, member));
        }
        return `${process.env.REACT_APP_DISABILITY_CLAIM_FORMS_URL}/${claimFormPathsByCoverage.get(coverage)}`
    }

    export function findPaperClaimFormNumberFor(coverage: Coverage, member: Member): string {
        const formKey = coverage + findStateSuffixFor(member, coverage);
        return paperFormsByCoverage.get(formKey) as string;
    }

    export function findCoverageUrlFor(coverage: Coverage, path: Path): string {
        return `/${coverage}/${path}`
    }

    export function findBenefitTypesFor(coverage: Coverage): BenefitType[] {
        return benefitTypesByCoverage.get(coverage) as BenefitType[];
    }

    export function findContentNavigatorNameFor(coverage: Coverage, isPortability: boolean): string {
        const portabilitySuffix = Coverage.VOLUNTARY_TERM_LIFE === coverage && isPortability ? '_PORT' : '';
        const contentNavigatorKey = coverage + portabilitySuffix;
        return contentNavigatorNamesByCoverage.get(contentNavigatorKey) as string;
    }

    export function findBenefitServiceQueryPartFor(coverage: Coverage): string {
        return queryPartsByCoverage.get(coverage) as string;
    }

    export function findPortabilityRatesFormNumberFor(coverage: Coverage, product: Product): string {
        const cancerSuffix = (product as CriticalIllness)?.cancerOneTwo ? '_WC' : '';
        const formKey = coverage + cancerSuffix;
        return portabilityRatesByCoverage.get(formKey) as string;
    }

    export function hasWellness(coverage: Coverage, product: Product): boolean {
        return (Coverage.ACCIDENT === coverage
                && ![null, 'NONE'].includes((product as Accident)?.wellnessBenefitAmount as string))
            || (Coverage.CRITICAL_ILLNESS === coverage
                && (product as CriticalIllness)?.withWellness)
            || (Coverage.HOSPITAL_INDEMNITY === coverage
                && !!(product as HospitalIndemnityBenefits)?.healthScreeningV2?.included)
    }

    export function hasPortability(coverage: Coverage, product: Product): boolean {
        return ([Coverage.ACCIDENT, Coverage.CRITICAL_ILLNESS, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage)
                && ![Portability.None, Portability.NotIncluded].includes(findValueByKey(Portability, (product as Accident).portability)))
            || (Coverage.HOSPITAL_INDEMNITY === coverage
                && HospitalIndemnityPortability.NOT_INCLUDED !== findValueByKey(HospitalIndemnityPortability, (product as HospitalIndemnityBenefits).portability))
    }

    export function hasPreExistingLimitation(coverage: Coverage, product: Product): boolean {
        return Coverage.CRITICAL_ILLNESS === coverage
            || (Coverage.HOSPITAL_INDEMNITY === coverage
                && !!(product as HospitalIndemnityBenefits)?.preExistingConditionsV2?.included)
            || ([Coverage.SHORT_TERM_DISABILITY, Coverage.LONG_TERM_DISABILITY].includes(coverage)
                && !!(product as ShortTermDisability)?.preExistingConditions?.included)
    }

    export function hasMaternityLimitation(coverage: Coverage, product: Product): boolean {
        return (Coverage.HOSPITAL_INDEMNITY === coverage
                && HospitalIndemnityMaternity.FULL_MATERNITY !== findValueByKey(HospitalIndemnityMaternity, (product as HospitalIndemnityBenefits).maternity))
            || (Coverage.SHORT_TERM_DISABILITY === coverage
                && Maternity.FullMaternity !== findValueByKey(Maternity, (product as ShortTermDisability).maternity))
    }

    export function hasBeneficiaries(coverage: Coverage, product: Product) {
        return (Coverage.ACCIDENT === coverage && (product as Accident)?.accidentalDeathAndDismemberment)
            || [Coverage.LIFE, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage);
    }

    export function hasResources(coverage: Coverage) {
        return [Coverage.LIFE, Coverage.LONG_TERM_DISABILITY, Coverage.SHORT_TERM_DISABILITY, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage);
    }

    export function hasBooklet(coverage: Coverage) {
        return !isPaidLeave(coverage);
    }

    export function hasScheduleOfBenefits(coverage: Coverage) {
        return [Coverage.LONG_TERM_DISABILITY, Coverage.SHORT_TERM_DISABILITY, Coverage.CRITICAL_ILLNESS, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage);
    }

    export function hasBenefitsFlyer(coverage: Coverage) {
        return isPaidLeave(coverage);
    }

    export function hasUnderwriting(coverage: Coverage) {
        return [Coverage.CRITICAL_ILLNESS, Coverage.LIFE, Coverage.LONG_TERM_DISABILITY, Coverage.SHORT_TERM_DISABILITY, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage);
    }

    export function findBenefitsFlyerFormNumberFor(coverage: Coverage, workState: string) {
        if(!isPaidLeave(coverage)) {
            return null;
        }
        return `GP62729${workState}`;
    }

    export function findBenefitServiceNameFor(coverage: Coverage): string {
        return benefitServiceNameByCoverage.get(coverage) as string
    }

    export function findByBenefitServiceName(benefitServiceName: string): Coverage {
        return findMapKeyByValue(benefitServiceNameByCoverage, benefitServiceName) as Coverage;
    }

    export function hasLifeAccidentalDeathAndDismemberment(coverage: Coverage, product: Product): boolean {
        return [Coverage.LIFE, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage) &&
            ['accidentalDeathAndDismemberment', 'voluntaryAccidentalDeathAndDismemberment', 'spouseVoluntaryAccidentalDeathAndDismemberment']
            .map(accidentalDeathBenefitProperty => findValueByKey(AccidentalDeathAndDismemberment, (product as any)?.[accidentalDeathBenefitProperty]))
            .some(accidentalDeathBenefitProperty => [AccidentalDeathAndDismemberment.Occupational, AccidentalDeathAndDismemberment.NonOccupational].includes(accidentalDeathBenefitProperty))
    }

    export function hasAcceleratedBenefit(coverage: Coverage, product: Product): boolean {
        return [Coverage.LIFE, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage) &&
            ['acceleratedLife', 'acceleratedBenefit'].some(acceleratedLifeProperty => !!(product as any)?.[acceleratedLifeProperty])
    }

    export function hasElectronicFundsTransfer(coverage: Coverage): boolean {
        return ![Coverage.LIFE, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage);
    }

    export function findSourceSystemsFor(coverage: Coverage): SourceSystem[] {
        return [Coverage.PAID_FAMILY_LEAVE, Coverage.PAID_MEDICAL_LEAVE].includes(coverage) ? [SourceSystem.FINEOS_CLOUD] : [];
    }

    export function hasEmployeeAssistanceProgram(coverage: Coverage): boolean {
        return isDisability(coverage)
    }

    export function hasPhysicianStatement(coverage: Coverage): boolean {
        return isDisability(coverage);
    }

    export function hasTaxWithholding(coverage: Coverage): boolean {
        return isDisability(coverage);
    }

    export function hasSupplementalInformation(coverage: Coverage): boolean {
        return Coverage.LONG_TERM_DISABILITY === coverage;
    }

    export function hasSocialSecurity(coverage: Coverage): boolean {
        return Coverage.LONG_TERM_DISABILITY === coverage;
    }

    export function hasPregnancyClaimForm(coverage: Coverage): boolean {
        return Coverage.SHORT_TERM_DISABILITY === coverage;
    }

    export function hasTravelAssistance(coverage: Coverage): boolean {
        return Coverage.LIFE === coverage;
    }

    export function hasWillAndLegalDocumentCenter(coverage: Coverage): boolean {
        return isLife(coverage);
    }

    export function hasOpenEnrollmentBenefitIncrease(coverage: Coverage): boolean {
        return Coverage.VOLUNTARY_TERM_LIFE === coverage;
    }

    export function showBenefitsForSelfAccounting(coverage: Coverage): boolean {
        return isPaidLeave(coverage);
    }

    export function isPaidLeave(coverage: Coverage) {
        return [Coverage.PAID_FAMILY_LEAVE, Coverage.PAID_MEDICAL_LEAVE].includes(coverage);
    }

    export function findDepartmentExtensionFor(coverage: Coverage) {
        return isPaidLeave(coverage) ? '62477' : '';
    }

    function isDisability(coverage: Coverage) {
        return [Coverage.LONG_TERM_DISABILITY, Coverage.SHORT_TERM_DISABILITY].includes(coverage);
    }

    function isLife(coverage: Coverage) {
        return [Coverage.LIFE, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage);
    }

    function findStateSuffixFor(member: Member, coverage: Coverage) {
        return member?.benefit?.contractState === State.NY && Coverage.CRITICAL_ILLNESS === coverage ? '_NY' : '';
    }
}