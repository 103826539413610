import * as React from 'react';
import { PdsGlobalHeaderUserMenuLabel as PdsGlobalHeaderUserMenuLabelElement } from '@principal/design-system';
import { PdsGlobalHeaderUserMenuLabelProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsGlobalHeaderUserMenuLabel =
  createPdsReactComponent<PdsGlobalHeaderUserMenuLabelProps>()({
    tagName: 'pds-global-header-user-menu-label',
    elementClass: PdsGlobalHeaderUserMenuLabelElement,
    react: React,
  });
