export enum SourceSystem {
    CLAIMS_SERVICE = 'CLAIMS_SERVICE',
    CMS = 'CMS',
    FCS = 'FCS',
    FINEOS_CLOUD = 'FINEOS_CLOUD'
}

export namespace SourceSystem {
    export function isInClaimsService(sourceSystem: SourceSystem): boolean {
        return !sourceSystem || SourceSystem.CLAIMS_SERVICE === sourceSystem;
    }
}