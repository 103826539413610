import React from 'react';
import { PdsSkeletonLoader } from "@principal/design-system-react";

export type SpinnerProps = {
    children?: React.ReactNode
}

const LoadingSpinner = (props: SpinnerProps) => {
    return (
        <PdsSkeletonLoader/>
    )
};
export default LoadingSpinner;