import * as React from 'react';
import { EventName } from '@lit/react';
import { PdsGlobalHeaderLinkItem as PdsGlobalHeaderLinkItemElement } from '@principal/design-system';
import { PdsGlobalHeaderLinkItemProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsGlobalHeaderLinkItem =
  createPdsReactComponent<PdsGlobalHeaderLinkItemProps>()({
    tagName: 'pds-global-header-link-item',
    elementClass: PdsGlobalHeaderLinkItemElement,
    react: React,
    events: {
      onClick: 'pds-global-header-link-item-click' as EventName<CustomEvent>,
    },
  });
