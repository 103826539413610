import { html } from 'lit';
import { themedefault } from '@principal/design-system-tokens';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsCustomIcon } from '../PdsCustomIcon';

@customElement('pds-icon-bell-notification')
export class PdsIconBellNotification extends PdsCustomIcon {
  render() {
    return html`<svg
      width=${this.getSize(this.size)}
      height=${this.getSize(this.size)}
      viewBox="0 0 40 40"
      fill="none"
      strokeWidth="2"
      aria-hidden="true"
      display="block"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.3889 5.11668C20.194 4.9147 18.9694 4.97578 17.8005 5.29565C16.6316 5.61551 15.5465 6.18648 14.6209 6.96874C13.6954 7.75099 12.9515 8.72572 12.4413 9.82495C11.9311 10.9242 11.6667 12.1215 11.6667 13.3334C11.6667 19.4123 10.3618 23.4423 8.96321 26.0064C8.83638 26.2389 8.70907 26.4589 8.58225 26.6667H31.4175C30.5235 25.2019 29.6128 23.1477 29.0151 20.3479C28.8229 19.4478 29.3969 18.5622 30.2971 18.3701C31.1973 18.1779 32.0828 18.7519 32.275 19.652C32.9043 22.5999 33.9022 24.4719 34.6871 25.5744C35.0813 26.1281 35.4272 26.4952 35.6556 26.7114C35.7699 26.8196 35.8552 26.8905 35.9028 26.9282C35.9245 26.9454 35.9384 26.9557 35.9437 26.9596C36.5413 27.3699 36.8058 28.1207 36.5952 28.8162C36.3824 29.5192 35.7345 30 35 30H5.00005C4.2656 30 3.61773 29.5192 3.40489 28.8163C3.19432 28.1208 3.45872 27.3701 4.05619 26.9597C4.05619 26.9597 4.05895 26.9576 4.06096 26.9562C4.07379 26.9466 4.10355 26.9239 4.14792 26.8869C4.23661 26.813 4.38392 26.6818 4.57138 26.484C4.94542 26.0891 5.4839 25.4241 6.03689 24.4102C7.13827 22.391 8.33338 18.9211 8.33338 13.3333C8.3334 11.6367 8.70347 9.96047 9.41779 8.42154C10.1321 6.88262 11.1735 5.51801 12.4693 4.42285C13.7651 3.32769 15.2842 2.52834 16.9207 2.08052C18.5572 1.6327 20.2716 1.5472 21.9445 1.82997C22.8521 1.98339 23.4635 2.84351 23.3101 3.75111C23.1567 4.65871 22.2965 5.2701 21.3889 5.11668Z"
        fill="${this.color}"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2804 33.5583C17.0766 33.0965 18.0964 33.3675 18.5583 34.1637C18.7048 34.4163 18.9151 34.6259 19.1681 34.7717C19.4211 34.9174 19.708 34.9941 20 34.9941C20.292 34.9941 20.5788 34.9174 20.8318 34.7717C21.0848 34.6259 21.2951 34.4163 21.4416 34.1637C21.9035 33.3675 22.9234 33.0965 23.7196 33.5583C24.5158 34.0202 24.7868 35.0401 24.325 35.8363C23.8855 36.594 23.2546 37.2229 22.4955 37.6601C21.7365 38.0973 20.8759 38.3274 20 38.3274C19.124 38.3274 18.2635 38.0973 17.5044 37.6601C16.7454 37.2229 16.1145 36.594 15.675 35.8363C15.2131 35.0401 15.4842 34.0202 16.2804 33.5583Z"
        fill="${this.color}"
      />
      <circle
        cx="30"
        cy="9.33334"
        r="5"
        fill="${themedefault.SemanticBorderError}"
      />
    </svg>`;
  }
}
