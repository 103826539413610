import Actions from "../molecules/Actions";
import Claims from "../molecules/Claims";
import Benefits from "../molecules/Benefits";
import { useQueryParameters } from "../hooks/UseQueryParameters";
import { useCoverage } from "../hooks/useCoverage";
import { useEffect } from "react";
import { setBenefitKey } from "../service/BenefitKeyService";

const Overview = () => {
    const coverage = useCoverage();
    const queryParams = useQueryParameters();

    useEffect(() => {
        const benefitKey = queryParams.get('benefitKey');
        if(benefitKey) {
            setBenefitKey(coverage, benefitKey);
        }
    }, [coverage, queryParams])

    return (
        <>
            <Actions/>
            <Claims onlyRecent={true}/>
            <Benefits/>
        </>
    );
};

export default Overview;
