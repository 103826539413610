import React from "react";
import { PdsStatusIndicator } from "@principal/design-system-react";

export interface StatusButtonProps extends Omit<React.ComponentProps<typeof PdsStatusIndicator>, 'border'> {
}

const StatusButton = (props: StatusButtonProps) => {
    return (
        <PdsStatusIndicator {...props} variant={props.variant ?? 'info'}/>
    );
}
export default StatusButton;
