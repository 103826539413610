import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <AuthProvider {...buildOidcConfig()}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </AuthProvider>
    </React.StrictMode>
);

function buildOidcConfig() {
    return {
        onSigninCallback: async () => {
            window.location.hash = '';
            cleanUrl();
        },
        authority: process.env.REACT_APP_AUTHORITY_URL,
        client_id: process.env.REACT_APP_CLIENT_ID,
        response_type: 'code',
        redirect_uri: process.env.REACT_APP_REDIRECT_URL,
        scope: 'openid profile api://ebs/individual-experience/header.read',
        automaticSilentRenew: false,
        loadUserInfo: false,
        revokeTokensOnSignout: true,
        monitorSession: false,
        post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_URL,
        silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
        metadata: {
            issuer: process.env.REACT_APP_AUTHORITY_URL,
            authorization_endpoint: `${process.env.REACT_APP_AUTHORITY_URL}/v1/authorize`,
            userinfo_endpoint: `${process.env.REACT_APP_AUTHORITY_URL}/v1/userinfo`,
            end_session_endpoint: `${process.env.REACT_APP_AUTHORITY_URL}/v1/logout`,
            jwks_uri: `${process.env.REACT_APP_AUTHORITY_URL}/v1/keys`,
            token_endpoint: `${process.env.REACT_APP_AUTHORITY_URL}/v1/token`
        }
    } as AuthProviderProps
}

const cleanUrl = () => {
    const uri = window.location.toString();
    if (uri.indexOf('?') > 0) {
        const clean_uri = uri.substring(0, uri.indexOf('?'));
        window.history.replaceState({}, document.title, clean_uri);
    }
};
