import * as React from 'react';
import { PdsTable as PdsTableElement } from '@principal/design-system';
import { EventName } from '@lit/react';
import { PdsTableProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsTable = createPdsReactComponent<PdsTableProps>()({
  tagName: 'pds-table',
  elementClass: PdsTableElement,
  react: React,
  events: {
    pdsTableCollapseAll: 'pds-table-collapse-all' as EventName<CustomEvent>,
    pdsTableExpandAll: 'pds-table-expand-all' as EventName<CustomEvent>,
    onChange: 'change' as EventName<Event>,
    pdsTableChanged: 'pds-table-changed' as EventName<CustomEvent>,
  },
});
