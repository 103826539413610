import { LongTermDisability, ShortTermDisability } from "benefit-service-types";
import { DateTime } from "luxon";
import { ConditionType } from "../enums/ConditionType";

export function findBenefitStartDateFor(product: ShortTermDisability | LongTermDisability,
                                        conditionType: ConditionType,
                                        disabilityDate: DateTime,
                                        hospitalizationDate: DateTime): DateTime {
    const shortTerm = product as ShortTermDisability;
    if(shortTerm.firstDayHospital && hospitalizationDate.isValid) {
        if (hospitalizationDate < disabilityDate) {
            return disabilityDate;
        }
        return hospitalizationDate;
    }
    const longTerm = product as LongTermDisability;
    const eliminationPeriodBasis = longTerm.eliminationPeriodMode
        ? longTerm.eliminationPeriodMode.toLowerCase()
        : 'days'
    const eliminationPeriod = longTerm.eliminationPeriod
        ? longTerm.eliminationPeriod
        : findShortTermEliminationPeriodFor(shortTerm, conditionType);
    const startDateDayOffset = longTerm.eliminationPeriod ? 0 : 1;
    return disabilityDate.plus({
        [eliminationPeriodBasis]: eliminationPeriod
    }).minus({'days': startDateDayOffset});
}

export function findEliminationPeriodFor(product: ShortTermDisability | LongTermDisability,
                                        conditionType: ConditionType, hospitalizationDate: DateTime) {
    const shortTerm = product as ShortTermDisability;
    if(shortTerm.firstDayHospital && hospitalizationDate.isValid) {
        return 'the day you are hospitalized';
    }
    if(shortTerm.accidentEliminationPeriod) {
        return `${findShortTermEliminationPeriodFor(shortTerm, conditionType)} days`;
    }

    const longTerm = product as LongTermDisability;
    return `${longTerm.eliminationPeriod} ${longTerm.eliminationPeriodMode.toLowerCase()}`
}

function findShortTermEliminationPeriodFor(product: ShortTermDisability, conditionType: ConditionType) {
    return ConditionType.INJURY === conditionType ? product.accidentEliminationPeriod : product.sicknessEliminationPeriod
}