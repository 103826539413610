import React from "react";
import { PdsIconExternalLink } from "@principal/design-system-icons-react";
import { findAdobeSignLinkFor } from "../service/FormService";
import { PdsLink } from "@principal/design-system-react";

interface AdobeDocuSignLinkProps {
    documentId: string;
}

const AdobeDocuSignLink = (props: AdobeDocuSignLinkProps & Omit<React.HTMLProps<HTMLAnchorElement>, keyof AdobeDocuSignLinkProps>) => {
    return (
        <div>
            <PdsLink href={findAdobeSignLinkFor(props.documentId)} target="_blank">
                {props.children}
                <span slot="icon-right">
                    <PdsIconExternalLink size="default"/>
                </span>
            </PdsLink>
        </div>
);
}
export default AdobeDocuSignLink;
