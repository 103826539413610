import React, { ReactElement } from "react";
import "@pds-react/tab/dist/tab.min.css"
import currency from "currency.js";
import { Condition } from "../../enums/Condition";
import { Provision } from "benefit-service-types/lib/app/src/domain/enums/HospitalIndemnity/Provision";
import _ from "lodash";
import { Relationship } from "../../enums/Relationship";
import {
    HospitalIndemnityBenefitPercent
} from "benefit-service-types/lib/app/src/domain/enums/HospitalIndemnity/HospitalIndemnityBenefitPercent";
import LoadingSpinner from "../../atoms/LoadingSpinner";
import FlatTable from '../FlatTable';
import findConditionDescriptionFor = Condition.findConditionDescriptionFor;
import findConditionFromPlanTypeFor = Condition.findConditionFromPlanTypeFor;
import { useProduct } from "../../hooks/useProduct";
import { useCoverage } from "../../hooks/useCoverage";
import { Coverage } from "../../enums/Coverage";
import { BenefitsProps } from '../Benefits';
import BenefitsTab from "./BenefitsTab";

interface HospitalIndemnityBenefit {
    coverage: string
    condition: Condition
    amount: currency
    days: number,
    period: string,
    daySpan?: number,
    hideDays?: boolean
}

const HospitalIndemnityBenefits = (props: BenefitsProps) => {
    const coverage = useCoverage();
    const { loading: productLoading, data: product, error: productError } = useProduct()

    if(coverage !== Coverage.HOSPITAL_INDEMNITY) {
        return null;
    }

    let defaultCondition: Condition;

    return (
        <>{buildBenefits()}</>
    )

    function buildBenefits() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('member group error', productError)
            return <p>Could not load member information, please try again later.</p>;
        }
        return <>
            {buildBenefitTabs()}
        </>
    }

    function buildBenefitTabs() {
        defaultCondition = findConditionFromPlanTypeFor(product.planType)

        const headers = ["Confinement benefits", "Additional confinement benefits", "Mental health and substance abuse benefits",
            "Outpatient benefits", "Emergency care benefits", "Surgical benefits", "Other benefits"]
        const relationships = Object.values(Relationship) as Relationship[];
        const sectionsByRelationship = new Map(relationships.map((r: Relationship) =>
            [
                r, [
                    buildConfinementBenefits(headers[0], r),
                    buildAdditionalConfinementBenefits(headers[1], r),
                    buildMentalHealthAndSubstanceAbuseBenefits(headers[2], r),
                    buildOutpatientBenefits(headers[3], r),
                    buildEmergencyCareBenefits(headers[4], r),
                    buildSurgicalBenefits(headers[5], r),
                    buildOtherBenefits(headers[6], r)
                ]
            ]
        ))
        return <BenefitsTab {...props} headers={headers} sectionsByRelationship={sectionsByRelationship} />;
    }

    function buildConfinementBenefits(header: string, relationship: Relationship) {
        const benefits = [
            ...buildSicknessAndInjuryBenefitsFor('First day hospital', product.firstDayHospitalSickness, product.firstDayHospitalInjury),
            ...buildSicknessAndInjuryBenefitsFor('First day hospital intensive care unit', product.firstDayIntensiveCareUnitSickness, product.firstDayIntensiveCareUnitInjury),
            ...buildSicknessAndInjuryBenefitsFor('Daily hospital confinement', product.dailyHospitalSickness, product.dailyHospitalInjury),
            ...buildSicknessAndInjuryBenefitsFor('Daily hospital intensive care unit confinement', product.dailyIntensiveCareUnitSickness, product.dailyIntensiveCareUnitInjury),
            buildBenefitFor('Newborn nursery confinement', defaultCondition, product.newbornNursery)
        ] as HospitalIndemnityBenefit[]
        return buildBenefitsTableFor(header, benefits, relationship);
    }

    function buildAdditionalConfinementBenefits(header: string, relationship: Relationship) {
        const benefits = [
            buildBenefitFor('Rehabilitation facility', defaultCondition, product.rehabilitationFacility),
            buildBenefitFor('Skilled nursing facility', defaultCondition, product.skilledNursingFacility),
            buildBenefitFor('Hospice care', defaultCondition, product.hospiceCare, 'included', 'amount', 'maximumDaysPerLifetime'),
        ] as HospitalIndemnityBenefit[]
        return buildBenefitsTableFor(header, benefits, relationship);
    }

    function buildMentalHealthAndSubstanceAbuseBenefits(header: string, relationship: Relationship) {
        const benefits = [
            buildBenefitFor('Mental disorder inpatient treatment facility', defaultCondition, product.mentalDisorderTreatmentFacility),
            buildBenefitFor('Substance abuse inpatient treatment facility', defaultCondition, product.substanceAbuseTreatmentFacility),
            buildBenefitFor('Mental disorder outpatient treatment', defaultCondition, product.mentalDisorderOutpatientTherapy),
            buildBenefitFor('Substance abuse outpatient treatment', defaultCondition, product.substanceAbuseOutpatientTherapy),
        ] as HospitalIndemnityBenefit[]
        return buildBenefitsTableFor(header, benefits, relationship);
    }

    function buildOutpatientBenefits(header: string, relationship: Relationship) {
        const benefits = [
            buildBenefitFor('Physician visit', defaultCondition, product.physicianVisit, 'inOfficeIncluded', 'inOfficeAmount', 'daysPerPersonPerYear'),
            buildBenefitFor('Telemedicine visit', defaultCondition, product.physicianVisit, 'telemedicineIncluded', 'telemedicineAmount', 'daysPerPersonPerYear'),
            buildBenefitFor('Lab test or X-ray', defaultCondition, product.labTestOrXray),
            buildBenefitFor('Major diagnostic procedure (diagnostic radiology)', defaultCondition, product.majorDiagnosticProcedure),
            buildBenefitFor('Invasive diagnostic procedure', defaultCondition, product.invasiveDiagnosticProcedure),
            buildBenefitFor('Prescription drug (brand name)', defaultCondition, product.prescriptionDrug, 'brandNameIncluded', 'brandNameAmount', 'daysPerYear', false, 2),
            buildBenefitFor('Prescription drug (generic)', defaultCondition, product.prescriptionDrug, 'genericIncluded', 'genericAmount', 'daysPerYear', true),
            buildBenefitFor('Durable medical equipment (tier 1)', defaultCondition, product.durableMedicalEquipment, 'tierOneIncluded', 'tierOneAmount', 'daysPerYear', false, 3),
            buildBenefitFor('Durable medical equipment (tier 2)', defaultCondition, product.durableMedicalEquipment, 'tierTwoIncluded', 'tierTwoAmount', 'daysPerYear', true),
            buildBenefitFor('Durable medical equipment (tier 3)', defaultCondition, product.durableMedicalEquipment, 'tierThreeIncluded', 'tierThreeAmount', 'daysPerYear', true),
            buildBenefitFor('Home health service', defaultCondition, product.homeHealthServices, 'included', 'amount', 'maximumDaysPerLifetime'),
            buildBenefitFor('Therapy services', defaultCondition, product.therapyServices),
            buildBenefitFor('Chiropractic care', defaultCondition, product.chiropracticCare),
        ] as HospitalIndemnityBenefit[]
        return buildBenefitsTableFor(header, benefits, relationship);
    }

    function buildEmergencyCareBenefits(header: string, relationship: Relationship) {
        const benefits = [
            buildBenefitFor('Air ambulance', defaultCondition, product.airAmbulance),
            buildBenefitFor('Ground or water ambulance', defaultCondition, product.groundOrWaterAmbulance),
            ...buildSicknessAndInjuryBenefitsFor('Emergency room', product.emergencyRoomSickness, product.emergencyRoomInjury),
            buildBenefitFor('Observation room', defaultCondition, product.observationUnit),
            buildBenefitFor('Urgent care facility', defaultCondition, product.urgentCareFacility),
        ] as HospitalIndemnityBenefit[]
        return buildBenefitsTableFor(header, benefits, relationship);
    }

    function buildSurgicalBenefits(header: string, relationship: Relationship) {
        const benefits = [
            buildBenefitFor('Inpatient surgery', defaultCondition, product.inpatientSurgery),
            buildBenefitFor('Outpatient surgery performed in a hospital or ambulatory surgical center', defaultCondition, product.outpatientSurgeryAmbulatory),
            buildBenefitFor('Outpatient surgery performed in a physician office', defaultCondition, product.outpatientSurgeryPhysiciansOffice),
            buildBenefitFor('General anesthesia', defaultCondition, product.generalAnesthesia),
            buildBenefitFor('Blood products', defaultCondition, product.bloodProducts),
            buildBenefitFor('Second opinion', defaultCondition, product.secondOpinion),
        ] as HospitalIndemnityBenefit[]
        return buildBenefitsTableFor(header, benefits, relationship);
    }

    function buildOtherBenefits(header: string, relationship: Relationship) {
        const benefits = [
            buildBenefitFor('Medical travel', defaultCondition, product.medicalTravel, 'included', 'amount', 'roundTripsPerYear'),
            buildBenefitFor('Companion lodging', defaultCondition, product.companionLodging),
            buildBenefitFor('Doula care', defaultCondition, product.doulaCare, 'included', 'amount', 'visitsPerPregnancy'),
            buildBenefitFor('Family care', defaultCondition, product.familyCare),
            buildBenefitFor('Pet care', defaultCondition, product.petCare),
        ] as HospitalIndemnityBenefit[]
        return buildBenefitsTableFor(header, benefits, relationship);
    }

    function buildBenefitsTableFor(title: string, benefits: HospitalIndemnityBenefit[], relationship: Relationship) {
        const truthyBenefits = benefits.filter(b => b);
        return <div>
            {!truthyBenefits.length
                ? `No ${title.toLowerCase()} found.`
                : <FlatTable title={title}
                             headers={['Coverage', 'Condition', 'Maximum amount', 'Maximum period'].map(h => ({ header: h }))}
                             rows={truthyBenefits
                                 .map(b => {
                                     const benefitColumns = [
                                         b.coverage,
                                         findConditionDescriptionFor(b.condition),
                                         multiplyAmountByRelationshipFor(b.amount, relationship).format(),
                                     ] as (string | ReactElement)[];
                                     if(!b.hideDays) {
                                         benefitColumns.push(
                                             <div {...(b.hideDays ? {} : {...{rowSpan: b.daySpan}})}>
                                                 {b.days?.toString()} {b.period ? _.startCase(b.period).toLowerCase().replace(/s\s/gi, '(s) ') : ''}
                                             </div>
                                         )
                                     }
                                     return benefitColumns
                                 })}/>
            }
        </div>;
    }

    function buildSicknessAndInjuryBenefitsFor(coverage: string, sicknessProvision?: Provision, injuryProvision?: Provision) {
        const sicknessBenefit = buildBenefitFor(coverage, Condition.SICKNESS, sicknessProvision);
        const injuryBenefit = buildBenefitFor(coverage, Condition.INJURY, injuryProvision);
        if(sicknessBenefit && injuryBenefit) {
            sicknessBenefit.hideDays = false
            sicknessBenefit.daySpan = 2
            injuryBenefit.hideDays = true
        }
        return [
            sicknessBenefit,
            injuryBenefit
        ]
    }

    function buildBenefitFor(coverage: string, condition: Condition, provision?: any,
                             includedField: string = 'included', amountField: string = 'amount',
                             daysField: string = 'daysPerYear', hideDays: boolean = false, daySpan: number = 1): HospitalIndemnityBenefit | undefined {
        if(!Object.values(Condition).includes(condition as Condition)
            || !provision || !provision[includedField] || !provision[amountField]) {
            return;
        }
        return {
            coverage,
            condition,
            amount: currency(provision[amountField]),
            days: provision[daysField],
            period: daysField,
            hideDays,
            daySpan
        };
    }

    function multiplyAmountByRelationshipFor(amount: currency, relationship: Relationship) {
        if([Relationship.DOMESTIC_PARTNER, Relationship.SPOUSE].includes(relationship)) {
            return amount.multiply(findFactorFor(product.spouseBenefit));
        } else if(relationship === Relationship.CHILD) {
            return amount.multiply(findFactorFor(product.childBenefit));
        }
        return amount;
    }

    function findFactorFor(benefit: HospitalIndemnityBenefitPercent) {
        if (benefit === HospitalIndemnityBenefitPercent.TWENTY_FIVE_PERCENT_OF_EE) {
            return .25;
        } else if (benefit === HospitalIndemnityBenefitPercent.FIFTY_PERCENT_OF_EE) {
            return .5;
        }
        return 1
    }
}
export default HospitalIndemnityBenefits;
