import {
    PdsGrid,
    PdsGridItem,
    PdsHeading,
    PdsLayoutContainer,
    PdsLink,
    PdsSegmentedControl,
    PdsSegmentedControlItem
} from "@principal/design-system-react";
import React from "react";
import VirtualSupplyLink from "../atoms/VirtualSupplyLink";
import { Coverage } from "../enums/Coverage";
import { useCoverage } from "../hooks/useCoverage";
import LoadingSpinner from "../atoms/LoadingSpinner";
import { useProduct } from "../hooks/useProduct";
import findOnlineClaimFormUrlFor = Coverage.findOnlineClaimFormUrlFor;
import findPaperClaimFormNumberFor = Coverage.findPaperClaimFormNumberFor;
import findPortabilityRatesFormNumberFor = Coverage.findPortabilityRatesFormNumberFor;
import hasWellness = Coverage.hasWellness;
import hasPortability = Coverage.hasPortability;
import hasBeneficiaries = Coverage.hasBeneficiaries;
import { useMember } from "../hooks/useMember";
import hasLifeAccidentalDeathAndDismemberment = Coverage.hasLifeAccidentalDeathAndDismemberment;
import hasAcceleratedBenefit = Coverage.hasAcceleratedBenefit;
import hasElectronicFundsTransfer = Coverage.hasElectronicFundsTransfer;
import AdobeDocuSignLink from "../atoms/AdobeDocuSignLink";
import hasTaxWithholding = Coverage.hasTaxWithholding;
import hasSupplementalInformation = Coverage.hasSupplementalInformation;
import hasSocialSecurity = Coverage.hasSocialSecurity;
import hasPhysicianStatement = Coverage.hasPhysicianStatement;
import hasPregnancyClaimForm = Coverage.hasPregnancyClaimForm;

const FormsAndResources = () => {
    const coverage = useCoverage();
    const { loading: memberLoading, data: member, error: memberError } = useMember()
    const { loading: productLoading, data: product, error: productError } = useProduct()

    enum FormChoice {
        ONLINE = 'online',
        PAPER = 'paper'
    }

    const [formChoice, setFormChoice] = React.useState(FormChoice.ONLINE);
    const onlineForm = FormChoice.ONLINE === formChoice;

    return (
        <div id="forms">
            <PdsLayoutContainer>
                <div className="flex-gap">
                    <PdsHeading headingTag="h2">Forms and resources</PdsHeading>
                    <div>Common forms and resources for your policy. For additional forms go to <PdsLink
                        href="https://www.principal.com/help/help-individuals/find-form">Find a form</PdsLink>.
                        Para formularios en español: <PdsLink
                            href="https://www.principal.com/es/personas/formularios-para-personas">Formularios para personas</PdsLink>.
                    </div>
                    <PdsGrid variant="3up">
                        <PdsGridItem>
                            <strong>Claim and reimbursement forms</strong>
                            <div>
                                <PdsSegmentedControl id="form-choice">
                                    <PdsSegmentedControlItem id="online" onClick={() => {setFormChoice(FormChoice.ONLINE)}} isSegmentActive={onlineForm} ariaLabel="Online">Online</PdsSegmentedControlItem>
                                    <PdsSegmentedControlItem id="paper" onClick={() => {setFormChoice(FormChoice.PAPER)}} isSegmentActive={!onlineForm} ariaLabel="Paper">Paper</PdsSegmentedControlItem>
                                </PdsSegmentedControl>
                            </div>
                            {buildClaimForms()}
                            {buildWellness()}
                        </PdsGridItem>
                        <PdsGridItem>
                            <strong>Supplemental forms and resources</strong>
                            <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhCWKJQCxLc2yo4EkYpRNfa5dm6p_lQQQz0tutKsqbOIu8FVMBwcuSLP0Gl80BQuido*">Electronic consent</AdobeDocuSignLink>
                            {hasElectronicFundsTransfer(coverage) &&
                                <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhDX7h7xd0pO4kvB0pZ4IXvC5SDLSXlGscPD9UQXsz8Vk6yV1IxdvvbCkeBtc06s0MY*">Electronic Funds Transfer (EFT)</AdobeDocuSignLink>}
                            <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhARjfoUIpuxYYT5vjy5HV6mNjFpfnlb2yH9MsaVZrBTXpowl26kE16V4I4XuGp7Jck*">HIPAA</AdobeDocuSignLink>
                            {hasTaxWithholding(coverage) && <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhCw0x5Hx0DrDUz39zXc3giVkdzILbba6_6NJhApfWqxup2sGLUhmhRzf8UCLKWfXbg*">Tax withholding request</AdobeDocuSignLink>}
                            {hasSupplementalInformation(coverage) && <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhCu8zqqWEfEVxspocrAeRv7TK83zqjGX4VkhNQL6P8kUOE0X1srcCzdPk7NydyhojQ*">Supplemental information form</AdobeDocuSignLink>}
                            {hasSocialSecurity(coverage) && <>
                                <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhC0kxN-6LUrI0dlxPNQG3A_F2ZErxIhP9V9oG4aNlufwE7_aOGaQKhmBERXussKxSs*">Social Security consent</AdobeDocuSignLink>
                                <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhD2AlkF1QqDo1unMG0jJfdvT3RVdrfeZOumiEh1dTGK2bRKLAhz5sF0QdogC8xQtXc*">Social Security reimbursement agreement</AdobeDocuSignLink>
                            </>}
                            {buildBeneficiary()}
                        </PdsGridItem>
                        <PdsGridItem>
                            {buildPortability()}
                        </PdsGridItem>
                    </PdsGrid>
                </div>
            </PdsLayoutContainer>
        </div>
    )

    function buildClaimForms() {
        if(memberLoading) {
            return <LoadingSpinner/>
        } else if(memberError || !member) {
            console.log('member error', memberError)
            return <p>Could not load claim form link, please try again later.</p>;
        }
        const mainClaimForm = onlineForm
            ? <div><PdsLink href={findOnlineClaimFormUrlFor(coverage, member)}>Claim form and instructions</PdsLink></div>
            : buildPaperClaimFormLink();
        return <>
            {mainClaimForm}
            {buildAccidentalDeathAndDismemberment()}
            {buildAcceleratedBenefit()}
            {hasPhysicianStatement(coverage) && <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhDdIwS4vOpL5CC_xjPGBvhrSqoPJS_cmYft6j58ZTk0EUpcXWs_VUqjawtQzz3lV-g*">Attending physician's statement</AdobeDocuSignLink>}
        </>
    }

    function buildPaperClaimFormLink () {
        const formNumber = findPaperClaimFormNumberFor(coverage, member);
        if (!formNumber) {
            return <div><PdsLink href='https://www.principal.com/help/help-individuals/help-insurance'>Claim form and instructions</PdsLink></div>
        }
        return <>
            <AdobeDocuSignLink documentId={formNumber}>Claim form and instructions</AdobeDocuSignLink>
            {hasPregnancyClaimForm(coverage)
                && <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhDMXKaH5RQUVPkxYux7nGA8mPY8LyS3_Tc_Mwvl353j_KFOo1x5EuPHfhdyw1ovwF0*">Pregnancy claim form</AdobeDocuSignLink>}
            <small>If you wish to file a paper claim, click the above link to open the claim form. Then click options and Download PDF to print a blank claim form.</small>
        </>
    }

    function buildWellness() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('product error', productError)
            return <p>Could not load wellness/health screening information, please try again later.</p>;
        } else if(!hasWellness(coverage, product)) {
            return;
        }
        return onlineForm
            ? <div><PdsLink href={findOnlineClaimFormUrlFor(Coverage.WELLNESS, product)}>Wellness/health screening form</PdsLink></div>
            : <AdobeDocuSignLink documentId={Coverage.findPaperClaimFormNumberFor(Coverage.WELLNESS, member)}>Wellness/health screening form</AdobeDocuSignLink>
    }

    function buildBeneficiary() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('product error', productError)
            return <p>Could not load beneficiary information, please try again later.</p>;
        } else if(!hasBeneficiaries(coverage, product)) {
            return;
        }
        return <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhARYhOBD563C574LBrI7a6e9xwl-cUw3doxIQyKvX7HGNo0pfH_q2ZCzpMjnVQCbfU*">Beneficiary designation form</AdobeDocuSignLink>
    }

    function buildPortability() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('product error', productError)
            return <p>Could not load portability information, please try again later.</p>;
        } else if(!hasPortability(coverage, product)) {
            return;
        }
        const portabilityRatesFormNumber = findPortabilityRatesFormNumberFor(coverage, product);
        return <>
            <strong>Portability forms and resources</strong>
            <div><PdsLink href="https://www.principal.com/benefitcontinuation">Portability form</PdsLink></div>
            {portabilityRatesFormNumber
                ? <VirtualSupplyLink formNumber={portabilityRatesFormNumber}>Portability rates</VirtualSupplyLink>
                : <div>Portability rates (coming soon)</div>}
        </>
    }

    function buildAccidentalDeathAndDismemberment() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('product error', productError)
            return <p>Could not load accidental death and dismemberment information, please try again later.</p>;
        } else if(!hasLifeAccidentalDeathAndDismemberment(coverage, product)) {
            return;
        }
        return <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhD5Xd7jKclED8pa8bw0aeWcKzTU0A6FhZHGDvcw_rewHduUbbxqChpkLYZ4Cd4PTdU*">Accidental death and dismemberment claim form</AdobeDocuSignLink>
    }

    function buildAcceleratedBenefit() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('product error', productError)
            return <p>Could not load accelerated benefit information, please try again later.</p>;
        } else if(!hasAcceleratedBenefit(coverage, product)) {
            return;
        }
        return <AdobeDocuSignLink documentId="CBFCIBAA3AAABLblqZhCjXAHqspjM7h7SXKzLq1GmjIET8O_99OgT3eGmQ7p1wnCIgrB8usVYm8h8mPhMEx4*">Accelerated benefit claim form</AdobeDocuSignLink>
    }
}
export default FormsAndResources;
