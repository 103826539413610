import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFound from "./templates/error/NotFound";
import Header from "./molecules/Header";
import Overview from "./templates/Overview";
import ClaimSummary from "./templates/ClaimSummary";
import './App.css';
import "@principal/design-system/pds-styles.min.css";
import Footer from "./molecules/Footer";
import DiscountsAndServices from "./templates/DiscountsAndServices";
import { Path } from "./enums/Path";
import ClaimDetail from "./templates/ClaimDetail";
import { ProtectedRoute } from "./atoms/ProtectedRoute";
import Login from './atoms/Login';
import LoginCallback from "./atoms/LoginCallback";
import Unauthorized from "./templates/error/Unauthorized";
import { useAuth } from "react-oidc-context";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from 'apollo-upload-client';
import PageViewMetric from "./atoms/PageViewMetric";
import { GlobalHeader } from "@principal/individual-header-component";
import { Environment } from "@principal/aws-types";
import { findEnterpriseIdFor } from "./service/EnterpriseIdService";
import FormsAndResources from "./templates/FormsAndResources";

const App = () => {
    const { user } = useAuth();

    function buildClient() {
        const authLink = setContext(async (_, { headers }) => {
            const token = user?.access_token;
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : ''
                }
            };
        });
        const httpLink = createUploadLink({
            uri: process.env.REACT_APP_MEMBER_DASHBOARD_SERVICE_URL
        });
        return new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({
                typePolicies: {
                    Member: {
                        keyFields: ['privacyId']
                    },
                    Claim: {
                        keyFields: ['claimId']
                    }
                }
            })
        });
    }

    return (
        <ApolloProvider client={buildClient()}>
            <GlobalHeader eid={findEnterpriseIdFor(user)}
                          env={process.env.REACT_APP_ENV === 'prod' ? Environment.Prod : Environment.Staging}
                          getAccessToken={() => user?.access_token}
                          logger={console}/>
            <Routes>
                <Route path={Path.COVERAGE} element={<><Header/><ProtectedRoute/></>}>
                    <Route path={Path.OVERVIEW} element={<Overview/>}/>
                    <Route path={Path.CLAIMS} element={<ClaimSummary/>}/>
                    <Route path={Path.CLAIM_DETAIL} element={<ClaimDetail/>}/>
                    <Route path={Path.RESOURCES} element={<DiscountsAndServices/>}/>
                    <Route path={Path.FORMS} element={<FormsAndResources/>}/>
                </Route>
                <Route path={Path.LOGIN_CALLBACK} element={<LoginCallback/>} />
                <Route path={Path.LOGIN} element={<Login/>} />
                <Route path={Path.UNAUTHORIZED} element={<Unauthorized/>}/>
                <Route path={Path.WILDCARD} element={<NotFound/>}/>
            </Routes>
            <Footer/>
            <PageViewMetric />
        </ApolloProvider>
    );
}
export default App;