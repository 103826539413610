import { html, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import '../global-header-mobile-tray/global-header-mobile-tray';
import '../global-header-user-menu-dropdown-view/global-header-user-menu-dropdown-view';
import '../global-header-user-menu-label/global-header-user-menu-label';
import '../user-avatar/user-avatar';
import styles from './global-header-user-menu.scss?inline';

/**
 * @summary Principal's global header user menu. This component incorporates the pds-global-header-user-menu-dropdown-view for larger screens
 * and swaps to a mobile styling for smaller screens.
 *
 * @slot user-avatar Replace the default svg and initials with a user's image. Restricted to img and svg.
 * @slot default The user menu's menu content area.
 * @slot footer The user menu's footer content area
 */
@customElement('pds-global-header-user-menu', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderUserMenu extends PdsElement {
  /**
   * The full name to be placed in the user menu label
   *
   */
  @property()
  fullName: string;

  /**
   * Indicates if the user has notifications
   */
  @property()
  notifications: boolean = false;

  /**
   *
   * The intials to be displayed
   */
  @property()
  initials: string;

  /**
   * Tracks the open/closed state of the userm menu when it's in mobile view
   *
   */
  @state()
  isUserMenuMobileActive: boolean = false;

  @query('slot[name="user-avatar"')
  userAvatarSlot: HTMLSlotElement;

  /**
   * Flag for mobile view
   * @internal
   */
  isMobile() {
    if (
      ((this.responsiveViewportSize === 'xs' ||
        this.responsiveViewportSize === 'sm' ||
        this.responsiveViewportSize === 'md') &&
        !this.classList.contains('break-faster')) ||
      (this.responsiveViewportSize === 'xs' &&
        this.classList.contains('break-faster'))
    ) {
      return true;
    }
    return false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  firstUpdated() {
    this.setWindowResizeHandler();
  }

  openUserMenuMobile() {
    this.isUserMenuMobileActive = true;
    this.dispatchEvent(
      new CustomEvent('pds-global-header-user-menu-open', {
        bubbles: true,
        composed: true,
      }),
    );

    // Since the close button for the user mobile menu is in a different component, we need to listen to its
    // custom event and change the state accordingly
    this.addEventListener('pds-global-header-mobile-tray-close', (e: Event) => {
      e.stopPropagation();
      const customEvent = e as CustomEvent;

      this.dispatchEvent(
        new CustomEvent('pds-global-header-user-menu-tray-close', {
          bubbles: true,
          composed: true,
          detail: customEvent.detail,
        }),
      );
      this.isUserMenuMobileActive = false;
    });
  }

  getAvatar() {
    const avatar = this.userAvatarSlot.assignedElements()[0];
    let clonedAvatar: HTMLElement | null = null;

    if (avatar && (avatar.tagName === 'IMG' || avatar.tagName === 'SVG')) {
      clonedAvatar = avatar.cloneNode(true) as HTMLElement;
      clonedAvatar.setAttribute('slot', 'user-image');
    }

    return clonedAvatar;
  }

  getMobileMarkup() {
    return html`
      <slot
        name="user-avatar"
        allowed-elements="img, svg"
        @slotchange=${this.handleSlotValidation}
        style="display: none"
      ></slot>
        <button
          class="${this.classEl('mobile-button')}"
          ariaLabel="${this.translateText('user-menu')}"
          type="button"
          aria-haspopup="dialog"
          aria-expanded="${this.isUserMenuMobileActive}"
          @click=${this.openUserMenuMobile}
        >
          <pds-global-header-user-menu-label
            slot="label"
            fullName=${this.fullName}
            initials=${this.initials ? this.initials : nothing}
            notifications=${this.notifications ? 'true' : nothing}
            hideName="true"
            >${this.getAvatar()}</pds-global-header-user-menu-label
          >
        </button>
        <pds-global-header-mobile-tray
          variant="right"
          open="${this.isUserMenuMobileActive ? true : nothing}"
          style="--pds-global-header-tray-padding: 16px"
          ><span slot="main"
            ><span class="${this.classEl('mobile-tray-label')}"
              ><pds-global-header-user-menu-label
                slot="label"
                fullName=${this.fullName}
                initials=${this.initials ? this.initials : nothing}
                >${this.getAvatar()}</pds-global-header-user-menu-label
              ></span
            ><span class="${this.classEl('mobile-main-content')}"
              ><slot></slot></span></span
          ><slot name="footer" slot="footer"></slot
        ></pds-global-header-mobile-tray>
      </slot>
    `;
  }

  render() {
    if (!this.initials && this.slotEmpty('user-avatar')) {
      console.error(
        'UserMenu requires either user initials or an image in the user-avatar slot to render the component',
      );
      return nothing;
    }

    return html`${this.isMobile()
      ? this.getMobileMarkup()
      : html`<pds-global-header-user-menu-dropdown-view
          ><pds-global-header-user-menu-label
            slot="label"
            fullName=${this.fullName}
            initials=${this.initials}
            notifications=${this.notifications ? 'true' : nothing}
            ><slot name="user-avatar" slot="user-image" allowed-elements="img, svg"
            @slotchange=${this.handleSlotValidation}></slot
          ></pds-global-header-user-menu-label>
          <slot></slot>
          <slot name="footer" slot="footer">
        </pds-global-header-user-menu-dropdown-view>`}`;
  }
}
