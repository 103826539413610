import { AuthContextProps, useAuth } from "react-oidc-context";
import { useEffect } from "react";
import AuthLoader from "./AuthLoader";

const Login = () => {
    const auth: AuthContextProps = useAuth();

    const loading = auth.isLoading;
    const authenticated = auth.isAuthenticated;
    const signin = auth.signinRedirect;
    useEffect(() => {
        if(loading) {
            return
        } else if(!authenticated) {
            signin()
        }
    }, [authenticated, loading, signin])

    return <AuthLoader/>
}
export default Login;