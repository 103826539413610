import React, { ReactElement } from "react";
import "@pds-react/tab/dist/tab.min.css"
import currency from "currency.js";
import { Relationship } from "../../enums/Relationship";
import { snakeCaseToCapitalized } from "../../enums/FindKeyByValue";
import { Coverage } from "../../enums/Coverage";
import LoadingSpinner from "../../atoms/LoadingSpinner";
import FlatTable from '../FlatTable';
import { useProduct } from "../../hooks/useProduct";
import { useCoverage } from "../../hooks/useCoverage";
import { BenefitsProps } from '../Benefits';
import BenefitsTab from "./BenefitsTab";
import { CriticalIllnessBenefitPayable } from "benefit-service-types/lib/app/src/domain/enums/CriticalIllnessBenefitPayable";
import { useMember } from "../../hooks/useMember";
import { Dependent } from "../../domain/Dependent";
import { PdsSegmentedControlItem, PdsSegmentedControl, PdsButton } from "@principal/design-system-react";
import { Member } from "../../domain/Member";
import { PdsTooltip } from "@principal/design-system-react";
import { PdsIconHelpCircle } from "@principal/design-system-icons-react";
import _ from "lodash";

interface CriticalIllnessBenefit {
    coverage: string
    amounts: string[]
}

const CriticalIllnessBenefits = (props: BenefitsProps) => {
    const coverage = useCoverage();
    const { loading: memberLoading, data: member, error: memberError } = useMember()
    const { loading: productLoading, data: product, error: productError } = useProduct()

    enum AmountChoice {
        APPROVED = 'approved',
        PENDING = 'pending'
    }

    const amountChoiceByRelationship = new Map();
    for (const relationship of Object.values(Relationship)) {
        amountChoiceByRelationship.set(relationship, AmountChoice.APPROVED);
    }
    const [amountChoices, setAmountChoices] = React.useState(amountChoiceByRelationship);

    if(coverage !== Coverage.CRITICAL_ILLNESS) {
        return null;
    }

    return (
        <>{buildBenefits()}</>
    )

    function buildBenefits() {
        if(memberLoading || productLoading) {
            return <LoadingSpinner/>
        } else if(memberError || !member || productError || !product) {
            console.log('member error', memberError)
            console.log('product error', productError)
            return <p>Could not load member information, please try again later.</p>;
        }
        return <>
            {buildBenefitTabs()}
        </>
    }

    function buildBenefitTabs() {
        const benefitsPayable = product.benefitSchedule.benefitsPayable;
        const headers = [findBenefitNameFor()];
        const relationships = Object.values(Relationship) as Relationship[];
        const sectionsByRelationship = new Map(relationships.map((r: Relationship) =>
            [
                r, [buildBenefitsFor(benefitsPayable, r)]
            ]
        ))
        return <BenefitsTab {...props} headers={headers} sectionsByRelationship={sectionsByRelationship} />;
    }

    function buildBenefitsFor(benefitsPayable: CriticalIllnessBenefitPayable[], relationship: Relationship) {
        const memberOrDependent = Relationship.isDependent(relationship)
            ? member.dependents.find((d: Dependent) => d.relationship === relationship)
            : member
        if(!memberOrDependent) {
            return null;
        }
        const benefits = benefitsPayable
            .map((b: CriticalIllnessBenefitPayable) => buildBenefitFor(b, memberOrDependent))
            .filter(b => b) as CriticalIllnessBenefit[];
        return buildBenefitsTableFor(findBenefitNameFor(), memberOrDependent, benefits);
    }

    function buildBenefitsTableFor(title: string, memberOrDependent: Member | Dependent, benefits: CriticalIllnessBenefit[]) {
        return <div className="flex-gap">
            {buildAmountControlFor(memberOrDependent)}
            <FlatTable title={title}
                       headers={['Coverage', 'First occurrence', 'Additional occurrences'].map(h => ({ header: h }))}
                       rows={benefits.map(b => {
                               return [
                                   b.coverage,
                                   ...b.amounts,
                               ] as (string | ReactElement)[]
                           })}/>
        </div>;
    }

    function buildAmountControlFor(memberOrDependent: Member | Dependent) {
        if(!memberOrDependent.benefit.pendingAmount) {
            return;
        }
        const relationship = findRelationshipFor(memberOrDependent)
        const approvedAmounts = showApprovedAmounts(memberOrDependent);
        return <div className="flex-gap">
            <PdsSegmentedControl id={`amount-choice-${relationship}`}>
                <PdsSegmentedControlItem id={`approved-${relationship}`} onClick={() => amountChoiceOnClick(relationship, AmountChoice.APPROVED)} isSegmentActive={approvedAmounts} ariaLabel="Approved">Approved</PdsSegmentedControlItem>
                <PdsSegmentedControlItem id={`pending-${relationship}`} onClick={() => amountChoiceOnClick(relationship, AmountChoice.PENDING)} isSegmentActive={!approvedAmounts} ariaLabel="Pending">Pending</PdsSegmentedControlItem>
            </PdsSegmentedControl>
            <PdsTooltip placement='right'>
                <PdsButton slot="trigger" variant="icon" size="sm" ariaLabel="Proof of good health">
                    <PdsIconHelpCircle/>
                </PdsButton>
                Some of your benefit amounts are pending, requiring proof of good health. See your booklet for details.
            </PdsTooltip>
        </div>
    }

    function amountChoiceOnClick(relationship: Relationship, amountChoice: AmountChoice) {
        amountChoices.set(relationship, amountChoice)
        setAmountChoices(new Map(amountChoices));
    }

    function buildBenefitFor(benefitPayable: CriticalIllnessBenefitPayable, memberOrDependent: Member | Dependent): CriticalIllnessBenefit | null {
        if(Relationship.CHILD !== (memberOrDependent as Dependent).relationship && benefitPayable.isChildhoodOnlyCondition) {
            return null;
        }
        return {
            coverage: snakeCaseToCapitalized(benefitPayable.type),
            amounts: findAmountsFor(benefitPayable, memberOrDependent),
        };
    }

    function findAmountsFor(benefitPayable: CriticalIllnessBenefitPayable, memberOrDependent: Member | Dependent): string[] {
        if(!memberOrDependent) {
            return [];
        }
        const flatAmount = benefitPayable.flatAmount;
        const percentOfScheduleBenefit = benefitPayable.percentOfScheduleBenefit
        let firstOccurrence = flatAmount?.firstOccurrence || 0;
        let additionalOccurrence = flatAmount?.additionalOccurrence || 0;
        if(percentOfScheduleBenefit) {
            const benefit = memberOrDependent.benefit;
            const approvedAmounts = showApprovedAmounts(memberOrDependent);
            const amount = approvedAmounts ? benefit.approvedAmount : benefit.pendingAmount;
            firstOccurrence = percentOfScheduleBenefit.firstOccurrence / 100 * amount;
            additionalOccurrence = percentOfScheduleBenefit.additionalOccurrence / 100 * amount;
        }
        return [currency(firstOccurrence).format(), currency(additionalOccurrence).format()];
    }

    function findBenefitNameFor() {
        return `${_.capitalize(Coverage.findCoverageNameFor(coverage, member))} benefits`;
    }

    function showApprovedAmounts(memberOrDependent: Member | Dependent) {
        return AmountChoice.APPROVED === amountChoices.get(findRelationshipFor(memberOrDependent));
    }

    function findRelationshipFor(memberOrDependent: Member | Dependent) {
        return (memberOrDependent as Dependent).relationship || Relationship.YOURSELF;
    }
}

export default CriticalIllnessBenefits;
