import React, { useEffect } from "react";
import "@pds-react/tab/dist/tab.min.css"
import { Coverage } from "../../enums/Coverage";
import {
    PdsAlert,
    PdsButton,
    PdsDatePicker,
    PdsDatePickerInput,
    PdsSegmentedControl,
    PdsSegmentedControlItem,
    PdsSelect,
    PdsTooltip
} from "@principal/design-system-react";
import LoadingSpinner from "../../atoms/LoadingSpinner";
import { useProduct } from "../../hooks/useProduct";
import { useCoverage } from "../../hooks/useCoverage";
import { BenefitsProps } from '../Benefits';
import '@knight-lab/timelinejs/dist/css/timeline.css';
import { DateRangeType } from "../../enums/DateRangeType";
import { snakeCaseToCapitalized } from "../../enums/FindKeyByValue";
import { DateTime } from "luxon";
import { ConditionType } from "../../enums/ConditionType";
import { findBenefitStartDateFor, findEliminationPeriodFor } from "../../service/BenefitStartDateService";
import { findBenefitEndDateFor } from "../../service/BenefitEndDateService";
import { useMember } from "../../hooks/useMember";
import { findAmountFrequencyFor, findBenefitAmountFor } from "../../service/BenefitAmountService";
import { DateRange } from "../../domain/DateRange";
import {
    findPreExistingReviewEndDateFor,
    findPreExistingReviewStartDateFor,
    findTreatmentFreeStartDateFor
} from "../../service/PreExistingDateService";
import { PdsIconHelpCircle } from "@principal/design-system-icons-react";
import FlatTable from "../FlatTable";
import { findOwnOccupationEndDateFor } from "../../service/OwnOccupationDateService";
import TerminationAlert from "../../atoms/TerminationAlert";
import { Benefit } from "../../domain/Benefit";
import _ from "lodash";

const Timeline = require("@knight-lab/timelinejs");

const DisabilityBenefits = (props: BenefitsProps) => {
    const coverage = useCoverage();
    const { loading: memberLoading, data: member, error: memberError } = useMember();
    const { loading: productLoading, data: product, error: productError } = useProduct();

    enum TimelineChoice {
        TIMELINE = 'timeline',
        TABLE = 'table'
    }

    const [conditionType, setConditionType] = React.useState(ConditionType.SICKNESS);
    const [hospitalizationDate, setHospitalizationDate] = React.useState(DateTime.invalid('invalid'));
    const [disabilityDate, setDisabilityDate] = React.useState(DateTime.now());
    const [lastTreatmentDate, setLastTreatmentDate] = React.useState(DateTime.invalid('invalid'));
    const [timelineViewChoice, setTimelineViewChoice] = React.useState(TimelineChoice.TIMELINE);
    const benefitEffectiveDate = DateTime.fromISO(member?.benefit?.effectiveDate);
    const isTimelineView = TimelineChoice.TIMELINE === timelineViewChoice;
    const jobOrOccupation = Coverage.SHORT_TERM_DISABILITY === coverage ? 'job' : 'occupation';
    const benefitEndDateBasedOn = Coverage.LONG_TERM_DISABILITY === coverage ? ' (based on your age when you become disabled)': '';
    
    const callbackTimelineRanges = React.useCallback(buildTimelineRanges,
        [benefitEffectiveDate, conditionType, coverage, disabilityDate, hospitalizationDate, lastTreatmentDate, member, product, jobOrOccupation, benefitEndDateBasedOn]);
    
    useEffect(() => {
        if(!member || !product || !isTimelineView || isNotDisability(coverage)) {
            return;
        }

        const timelineConfig = {
            events: callbackTimelineRanges().map(r => ({
                start_date: {
                    year: r.startDate.year,
                    month: r.startDate.month,
                    day: r.startDate.day
                },
                end_date: r.endDate && {
                    year: r.endDate.year,
                    month: r.endDate.month,
                    day: r.endDate.day
                },
                text: {
                    headline: snakeCaseToCapitalized(r.type),
                    text: r.tooltip
                },
                background: {
                    color: r.color
                }
            }))
        };
        const timelineOptions = {
            font: './timeline.css'
        };

        // @ts-ignore
        window.timeline = new Timeline.Timeline('timeline-embed', timelineConfig, timelineOptions);
    }, [coverage, member, product, conditionType, hospitalizationDate, disabilityDate, lastTreatmentDate, benefitEffectiveDate, isTimelineView, callbackTimelineRanges]);

    if(isNotDisability(coverage)) {
        return null;
    }

    return (
        <>{buildBenefits()}</>
    )

    function buildBenefits() {
        if(memberLoading || productLoading) {
            return <LoadingSpinner/>
        } else if(memberError || !member || productError || !product) {
            console.log('member error', memberError)
            console.log('product error', productError)
            return <p>Could not load member information, please try again later.</p>;
        }
        const benefit = member.benefit;
        const showUnderwritingAlert = !Benefit.isTerminated(benefit) && !benefit.approvedAmount;
        return <div className="flex-gap">
            <div>You can use this tool to show how your disability benefits are calculated in a claim. Enter a few pieces of information
                regarding a potential claim, and we will display a timeline representing milestones in the claim decision and when payments
                may start/end.
            </div>
            {showUnderwritingAlert &&
                <PdsAlert variant="warning" hideDismissButton={true}>Your benefits have not yet been approved. You are currently going
                    through underwriting, which may require proof of good health.
                    The disability benefit calculator below refers to benefits that you may receive after being approved.</PdsAlert>}
            <TerminationAlert benefit={benefit} employmentStatus={member.employment.status}/>
            <div>
                <small>
                    This summary is a very brief description of your coverage. It is not an insurance contract or a
                    complete statement of the rights, benefits, limitations, and exclusions of the coverage. If there is a discrepancy
                    between the policy and this summary, the actual policy provision prevails.
                    For complete coverage details, refer to your policy booklet.
                </small>
            </div>
            <div>
                <PdsSegmentedControl id='timeline-view-choice'>
                    <PdsSegmentedControlItem id="timeline-choice-timeline" onClick={() => setTimelineViewChoice(TimelineChoice.TIMELINE)}
                                             isSegmentActive={isTimelineView} ariaLabel="Timeline view">
                        Timeline view
                    </PdsSegmentedControlItem>
                    <PdsSegmentedControlItem id="timeline-choice-table" onClick={() => setTimelineViewChoice(TimelineChoice.TABLE)}
                                             isSegmentActive={!isTimelineView} ariaLabel="Table view">
                        Table view
                    </PdsSegmentedControlItem>
                </PdsSegmentedControl>
            </div>
            {isTimelineView
                ? <div className="timeline">
                    <div id="timeline-embed"/>
                </div>
                : <div>
                    <FlatTable
                        headers={['Event', 'Start date', 'End date', 'Description'].map(h => ({ header: h }))}
                        rows={buildTimelineRanges().map((r: DateRange) => {
                            return [
                                snakeCaseToCapitalized(r.type),
                                r.startDate.toFormat('D'),
                                r.endDate?.toFormat('D'),
                                r.tooltip
                            ]
                        })}
                    />
                </div>
            }
            <div>
                <PdsSelect id="condition-type"
                           fieldId="condition-type"
                           name="conditionType"
                           value={conditionType}
                           required={true}
                           onChange={(e: Event) => {
                               const value = (e.target as any)?.value;
                               if (!value) {
                                   return;
                               }
                               setConditionType(value)
                           }}>
                    <span slot="label">
                        <label htmlFor="condition-type">Condition type</label>
                        <PdsTooltip placement='right'>
                            <PdsButton type="button" slot="trigger" variant="icon" size="sm" ariaLabel="Condition tool tip">
                                <PdsIconHelpCircle/>
                            </PdsButton>
                            <>
                                This is the type of event that causes your disability.<br/><br/>
                                Injury<br/>
                                A disability that occurs solely and directly because of an accidental injury and begins
                                within 180 days of the accident.<br/>
                                An accidental injury means an injury that results solely
                                from external, violent, and unintentional means.<br/><br/>
                                Sickness<br/>
                                A disability that occurs directly or indirectly because of disease, a mental health
                                condition, alcohol, drug or chemical abuse, dependency, or addiction.
                            </>
                        </PdsTooltip>
                    </span>
                    {Object.keys(ConditionType).map(conditionType => {
                        const condition = (ConditionType as any)[conditionType];
                        return <option value={condition} key={condition}>{condition}</option>
                    })}
                </PdsSelect>
            </div>
            {buildDateInput(
                'disability-date', 'Date of disability',
                <>
                    This is the date where, solely and directly because of the condition you selected above:<br/><br/>
                    you cannot perform the majority of the substantial and material duties of your own {jobOrOccupation},<br/>
                    or you are performing the duties of your own {jobOrOccupation} on a modified basis or any {jobOrOccupation} and are
                    unable to earn more than 80% of your predisability earnings.
                </>, 'Disability date tool tip',
                disabilityDate, setDisabilityDate, true, benefitEffectiveDate)}
            {product.preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod && buildDateInput(
                "last-treatment-date", 'Last treatment date',
                <>
                    The last treatment date is the last day you received treatment, consultation, care, or service,<br/>
                    or were prescribed or took prescription medication for any preexisting conditions.<br/>
                    If you have no preexisting conditions, leave this blank.
                </>, 'Last treatment tool tip',
                lastTreatmentDate, setLastTreatmentDate)}
            {product.firstDayHospital && buildDateInput(
                'hospitalization-date', 'Hospitalization date',
                <>
                    If you are hospitalized for your disabling condition, your elimination period becomes the first day of your
                    hospitalization.<br/>
                    If you are not going to be hospitalized for your condition, leave this blank.
                </>, 'Hospitalization tool tip',
                hospitalizationDate, setHospitalizationDate)}
        </div>
    }

    function buildTimelineRanges() {
        if(!product || !member) {
            return [];
        }
        const treatmentFreeStartDate = findTreatmentFreeStartDateFor(product, lastTreatmentDate);
        const preExistingReviewStartDate = findPreExistingReviewStartDateFor(product, benefitEffectiveDate, disabilityDate, lastTreatmentDate)
        const preExistingReviewEndDate = findPreExistingReviewEndDateFor(product, benefitEffectiveDate, disabilityDate, lastTreatmentDate)
        const benefitStartDate = findBenefitStartDateFor(product, conditionType, disabilityDate, hospitalizationDate);
        const benefitEndDate = findBenefitEndDateFor(product, DateTime.fromISO(member?.birthDate), disabilityDate, benefitStartDate);
        const benefitAmount = findBenefitAmountFor(coverage, product, member);
        const benefitAmountFrequency = findAmountFrequencyFor(coverage).toLowerCase();
        const eliminationPeriod = findEliminationPeriodFor(product, conditionType, hospitalizationDate);
        const ownOccupationEndDate = findOwnOccupationEndDateFor(product, benefitStartDate, benefitEndDate);

        return [
            {
                type: DateRangeType.PREEXISTING_REVIEW,
                startDate: preExistingReviewStartDate,
                endDate: preExistingReviewEndDate,
                tooltip: 'A preexisting condition is any sickness or injury, including all related conditions and complications, or a pregnancy, for which you received medical treatment, consultation, care, or services, or were prescribed or took prescription medications, from this date to your benefit start date.',
                color: '#002855'
            },
            {
                type: DateRangeType.TREATMENT_FREE,
                startDate: treatmentFreeStartDate,
                endDate: lastTreatmentDate,
                tooltip: 'The treatment free period is the time period where a preexisting review is not required if you received no treatment, consultation, care, or service, and no prescription medication was prescribed or taken for any preexisting conditions during a set time following your benefit effective date. It is a rolling period based on when you last received treatment for your conditions.',
                color: '#002855'
            },
            {
                type: DateRangeType.COVERAGE_EFFECTIVE,
                startDate: benefitEffectiveDate,
                tooltip: 'This is when you most recently became effective on your employer\'s policy after signing up.',
                color: '#004C97'
            },
            {
                type: DateRangeType.DATE_OF_DISABILITY,
                startDate: disabilityDate,
                tooltip: `This is the date where, solely and directly because of your ${conditionType.toLowerCase()}, you cannot perform the majority of the substantial and material duties of your own ${jobOrOccupation}, or you are performing the duties of your own ${jobOrOccupation} on a modified basis or any ${jobOrOccupation} and are unable to earn more than 80% of your predisability earnings. 
                You won't be paid right away. 
                First, your elimination period needs to be satisfied, which is the period before your benefits begin. 
                Your elimination period is ${eliminationPeriod}.`,
                color: '#002855'
            },
            {
                type: DateRangeType.BENEFITS,
                startDate: benefitStartDate,
                endDate: benefitEndDate,
                tooltip: `This is when your elimination period ends and your benefits begin. 
                Your policy has a maximum benefit duration through ${benefitEndDate.toFormat('DDD')}${benefitEndDateBasedOn} as long as you continue to meet the definition of disability. 
                ${(benefitAmount?.value && benefitAmountFrequency) ? ` According to our earnings information on file for you, your benefit will be $${benefitAmount} each ${benefitAmountFrequency} while still disabled, potentially reduced by other income.` : ''}`,
                color: '#003865'
            },
            {
                type: DateRangeType.OWN_OCCUPATION_PERIOD_END_DATE,
                startDate: ownOccupationEndDate,
                tooltip: 'This date is when the definition of disability changes. Before this date, your disability is defined by the specific duties of your occupation. After this date, you must be disabled from any occupation for which you are qualified based on education, training, and experience to remain eligible for benefits.',
                color: '#003865'
            }
        ].filter(d => d.startDate?.isValid && d.startDate <= benefitEndDate)
            .sort((o1, o2) => {
                if(!o1.startDate || !o2.startDate) {
                    return 0;
                }
                return o1.startDate.diff(o2.startDate).toMillis()
            }) as DateRange[];
    }

    function buildDateInput(id: string, label: string, tooltip: React.ReactElement, tooltipLabel: string,
                            field: DateTime, setField: Function, required: boolean = false, minimumDate?: DateTime) {
        return <div>
            <PdsDatePickerInput fieldId={id}
                                label={label}
                                name={_.camelCase(id)}
                                required={required}
                                helpText=""
                                value={field.isValid ? field.toFormat('MM/dd/yyyy') : ''}
                                onBlur={(e: Event) => {
                                    const value = (e.target as any)?.value;
                                    if (!value) {
                                        return;
                                    }
                                    const eventDate = DateTime.fromFormat(value, 'MM/dd/yyyy');
                                    if (minimumDate && minimumDate > eventDate) {
                                        setField(minimumDate)
                                        return;
                                    }
                                    setField(eventDate);
                                }}>
                <span slot="label">
                    <label htmlFor={id}>{label}</label>
                    <PdsTooltip placement='right'>
                        <PdsButton type="button" slot="trigger" variant="icon" size="sm" ariaLabel={tooltipLabel}>
                            <PdsIconHelpCircle/>
                        </PdsButton>
                        {tooltip}
                    </PdsTooltip>
                </span>
                <PdsDatePicker slot="datepicker"
                               disableFunction={isDateDisabledFor(minimumDate)}/>
            </PdsDatePickerInput>
        </div>
    }

    function isDateDisabledFor(minimumDate: DateTime | undefined) {
        return (date: Date) => {
            if (!minimumDate) {
                return false;
            }
            return DateTime.fromJSDate(date) < minimumDate;
        };
    }

    function isNotDisability(coverage: Coverage) {
        return ![Coverage.LONG_TERM_DISABILITY, Coverage.SHORT_TERM_DISABILITY].includes(coverage);
    }
}

export default DisabilityBenefits;
