import React, { ElementType, useEffect, useState } from "react";
import { findVirtualSupplyLinkFor } from "../service/FormService";
import { PdsLink } from "@principal/design-system-react";
import { filesize } from "filesize";
import axios from "axios";

interface VirtualSupplyLinkProps {
    formNumber: string;
    container?: ElementType;
}

const VirtualSupplyLink = (props: VirtualSupplyLinkProps & Omit<React.HTMLProps<HTMLAnchorElement>, keyof VirtualSupplyLinkProps>) => {
    const [fileSize, setFileSize] = useState('');
    const virtualSupplyUrl = findVirtualSupplyLinkFor(props.formNumber);

    useEffect(() => {
        async function loadFileSize() {
            try {
                const response = await axios.get(virtualSupplyUrl, { responseType: 'blob' });
                const fileSize = filesize(response.data.size, { standard: "jedec" });
                setFileSize(fileSize)
            } catch(ignored) {}
        }
        loadFileSize();
    }, [virtualSupplyUrl])

    const Container = props.container ?? 'div'

    return (
        <Container>
            <PdsLink href={virtualSupplyUrl} target="_blank">
                {props.children}
            </PdsLink>
            <b><small> PDF{fileSize ? `, ${fileSize}` : ''}</small></b>
        </Container>
);
}
export default VirtualSupplyLink;
