import { Table } from '@tanstack/table-core';
import * as React from 'react';
import { PdsPaginationItem } from '../pagination-item/pagination-item';
import { handleRowCollapse } from './data-table-react-utils';

export const handlePaginationClick = (
  e: CustomEvent,
  table: Table<any>,
  pdsTable: any,
  hasExpandableRows: boolean,
) => {
  if (hasExpandableRows) {
    pdsTable.current.classList.remove('pds-c-data-table--rendered');
  }

  if (e.detail.summary === 'fly-first') {
    table.setPageIndex(0);
  } else if (e.detail.summary === 'fly-last') {
    table.setPageIndex(table.getPageCount() - 1);
  } else if (
    table.getCanPreviousPage() &&
    e.detail.summary === 'step-backward' &&
    table.options.state.pagination
  ) {
    table.setPageIndex(table.options.state.pagination.pageIndex - 1);
  } else if (
    table.getCanNextPage() &&
    e.detail.summary === 'step-forward' &&
    table.options.state.pagination
  ) {
    table.setPageIndex(table.options.state.pagination.pageIndex + 1);
  }

  handleRowCollapse(pdsTable, hasExpandableRows);
};

export const handlePaginationItemClick = (
  hasExpandableRows: boolean,
  pdsTable: { current: { classList: { remove: (arg0: string) => void } } },
  table: Table<any>,
  pageNum: number,
) => {
  if (hasExpandableRows) {
    pdsTable.current.classList.remove('pds-c-data-table--rendered');
  }

  if (pageNum > 0) {
    table.setPageIndex(pageNum - 1);
  }

  handleRowCollapse(pdsTable, hasExpandableRows);
};

export const generatePaginationItems = (
  table: Table<any>,
  pdsTable: any,
  hasExpandableRows: boolean,
) => {
  const currentPage = table.getState().pagination.pageIndex + 1;
  const totalPages = table.getPageCount();

  const paginatorMarkupTemp: Array<any> = [];
  let renderedPaginationItems = 0;

  // iterate through array to build pagination items
  Array.from({
    length: totalPages,
  }).forEach((item, index) => {
    const pageNum = Number(index) + 1;
    if (
      renderedPaginationItems < 3 &&
      (currentPage === pageNum ||
        (currentPage - 2 <= pageNum && currentPage + 2 >= pageNum))
    ) {
      renderedPaginationItems += 1;
      paginatorMarkupTemp.push(
        <PdsPaginationItem
          key={pageNum}
          onClick={
            /* istanbul ignore next */ () => {
              handlePaginationItemClick(
                hasExpandableRows,
                pdsTable,
                table,
                pageNum,
              );
            }
          }
          pageNumber={pageNum}
          active={currentPage === pageNum}
        />,
      );
    }
  });
  return paginatorMarkupTemp;
};
