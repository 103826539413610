import { Coverage } from "../enums/Coverage";
import { LocalStorageKey } from "../enums/LocalStorageKey";

export function isSelfAccounting(coverage: Coverage): boolean {
    const benefitKey = findBenefitKeyFor(coverage);
    return isSelfAccountingKey(benefitKey);
}

export function isSelfAccountingKey(benefitKey: string | null | undefined) {
    return !!benefitKey && parseInt(benefitKey) < 0;
}

export function findBenefitKeyFor(coverage: Coverage): string {
    return findBenefitKeys()[coverage];
}

export function setBenefitKey(coverage: Coverage, benefitKey: string) {
    const localStorageKey = LocalStorageKey.BENEFIT_KEYS;
    window.localStorage.setItem(localStorageKey, JSON.stringify({
        ...findBenefitKeys(),
        [coverage]: benefitKey
    }));
}

function findBenefitKeys() {
    return JSON.parse(window.localStorage.getItem(LocalStorageKey.BENEFIT_KEYS) || '{}');
}