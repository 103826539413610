import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './decorator.scss?inline';

/**
 * @summary A styled decorator
 *
 * @slot default This slot holds the content to display underneath the decorator
 */
@customElement('pds-decorator', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsDecorator extends PdsElement {
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * - **default** grows larger at default viewport
   * - **slower** grows larger at a larger viewport than the default
   */
  @property()
  break: 'slower' | 'default' = 'default';

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      [`break-${this.break}`]: !!this.break,
    };
  }

  render() {
    return html`<div class=${this.getClass()}></div>
      <slot class="${this.classEl(this.variant)}-heading"></slot>`;
  }
}
