import * as React from 'react';
import { Cell, flexRender, Header } from '@tanstack/react-table';
import { ColumnDef, Table, createColumnHelper } from '@tanstack/table-core';
import { filter, onlyText } from 'react-children-utilities';
import {
  PdsIconChevronDown,
  PdsIconChevronUp,
} from '@principal/design-system-icons-react';
import { JSX } from 'react/jsx-runtime';

export type ColumnMetaData = {
  align: string;
  hidden: boolean;
  type: string;
  borders?: boolean;
  width?: string;
  syncId?: string;
};

export const FILTERABLE_CONTENT = 'filterableContent';

export function handleRowCollapse(pdsTable: any, hasExpandableRows: boolean) {
  if (
    hasExpandableRows &&
    pdsTable &&
    pdsTable.current &&
    pdsTable.current.wrapper
  ) {
    setTimeout(() => {
      pdsTable.current.handleCollapseAll(false);
      pdsTable.current.wrapper.dispatchEvent(new Event('change'));
      pdsTable.current.classList.add('pds-c-data-table--rendered');
    }, 0);
  }
}

export function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(
    /* istanbul ignore next */ () => {
      shouldSkipRef.current = false;
    },
    [],
  );

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export function getSlottedColumnDataModel(columnSlotElements: any) {
  const columnHelper = createColumnHelper<any>();
  const columnObjArr: ColumnDef<any, unknown>[] = [];
  columnSlotElements.forEach((column: any) => {
    if (
      column &&
      column.props &&
      column.props.columnId &&
      !column.props.dataSyncId
    ) {
      // Create a TanStack accessor column for regular table columns
      columnObjArr.push(
        columnHelper.accessor(column.props.columnId, {
          id: column.props.columnId,
          cell: (info: any) => info.cell.getValue() || column.props.children,
          enableSorting: !column.props.disableSort,
          header: column.props.header || column.props.children,
          meta: {
            align: column.props.align,
            hidden: column.props.hidden,
            type: column.props.type,
            width: column.props.width,
            syncId: column.props.dataSyncId,
          },
        }),
      );
    } else if (column.props && column.props.dataSyncId) {
      // Create a TanStack accessor column for table input columns
      columnObjArr.push(
        columnHelper.accessor(column.props.columnId, {
          id: column.props.columnId,
          cell: (info) => {
            // set up event for slotted subcomponents
            const onBlur = /* istanbul ignore next */ (e: any) => {
              const target = e.target as HTMLInputElement;
              if (
                target &&
                // @ts-expect-error TS doesn't know about syncId
                info.row.original[info.column.columnDef.meta?.syncId] !==
                  target.value &&
                (target.tagName.toLowerCase() === 'pds-text-input' ||
                  target.tagName.toLowerCase() === 'pds-select')
              ) {
                // @ts-expect-error TS doesn't know about updateData
                info.table.options.meta?.updateData(
                  info.row.index,
                  // @ts-expect-error TS doesn't know about syncId
                  info.column.columnDef.meta.syncId,
                  (e.target as HTMLInputElement)?.value,
                );
              } else if (
                target &&
                target.tagName.toLowerCase() === 'pds-switch' &&
                // @ts-expect-error TS doesn't know about syncId
                info.row.getValue(info.column.columnDef.meta?.syncId) !==
                  (target.checked ? 'on' : 'off')
              ) {
                const switchState = (e.target as HTMLInputElement)?.checked
                  ? 'on'
                  : 'off';

                // @ts-expect-error TS doesn't know about updateData
                info.table.options.meta?.updateData(
                  info.row.index,
                  // @ts-expect-error TS doesn't know about syncId
                  info.column.columnDef.meta.syncId,
                  switchState,
                );
              }
            };

            const renderableComponent = filter(
              column.props.children,
              (item: React.ReactNode) => {
                if (
                  (item as React.ReactElement).props &&
                  (item as React.ReactElement).props.slot
                ) {
                  return (item as React.ReactElement).props.slot === 'input';
                }
                return false;
              },
            );

            const AddExtraProps = (
              Component: React.ReactNode[],
              extraProps: JSX.IntrinsicAttributes,
            ) => {
              const NewComponent = React.cloneElement(
                Component[0] as React.ReactElement,
                extraProps,
              );
              return NewComponent;
            };

            let syncIdValue = '';
            if (
              info.column.columnDef.meta &&
              // @ts-expect-error TS doesn't know about syncId
              info.column.columnDef.meta.syncId
            ) {
              // @ts-expect-error TS doesn't know about syncId
              syncIdValue = info.column.columnDef.meta.syncId;
            }

            return (
              <div onBlur={onBlur} id={`slotted-input-${info.cell.id}`}>
                {AddExtraProps(renderableComponent, {
                  // @ts-expect-error TS doesn't know about value
                  value: info.row.getValue(syncIdValue),
                  checked: info.row.original[syncIdValue] === 'on',
                })}
              </div>
            );
          },
          enableSorting: !column.props.disableSort,
          header:
            column.props.header ||
            filter(column.props.children, (item: React.ReactNode) => {
              if (
                (item as React.ReactElement).props &&
                (item as React.ReactElement).props.slot
              ) {
                return (item as React.ReactElement).props.slot !== 'input';
              }
              return true;
            }),
          meta: {
            align: column.props.align,
            hidden: column.props.hidden,
            type: column.props.type,
            width: column.props.width,
            syncId: column.props.dataSyncId,
          },
        }),
      );
    } else {
      // Create a TanStack display column
      columnObjArr.push(
        columnHelper.display({
          id: column.props.columnId,
          cell: column.props.children,
          enableSorting: !column.props.disableSort,
          header: column.props.header || onlyText(column.props.children),
          meta: {
            align: column.props.align,
            hidden: column.props.hidden,
            type: column.props.type,
            width: column.props.width,
            syncId: column.props.dataSyncId,
          },
        }),
      );
    }
  });

  // include filterable content column
  columnObjArr.push({
    id: FILTERABLE_CONTENT,
    accessorKey: FILTERABLE_CONTENT,
    meta: {
      hidden: true,
    },
    header: 'Filterable content (hidden)',
  });

  return columnObjArr;
}

export function getSlottedRowDataModel(rowSlotElements: any) {
  const rowObjArr: {}[] = [];

  if (rowSlotElements.length > 0) {
    rowSlotElements.forEach((element: any) => {
      if (element) {
        const row = element;
        const subRow: any[] = [];
        let rowObj = {};
        // build data for the row
        row.props.children.forEach((cellElement: any) => {
          if (cellElement.props && cellElement.props.cellId) {
            const cell = cellElement;
            rowObj = {
              ...rowObj,
              [cell.props.cellId]: cell.props.children,
            };
          } else if (Array.isArray(cellElement.props.children)) {
            subRow.push(cellElement);
          }
        });
        if (subRow.length > 0) {
          const subRowObjArr: {}[] = [];
          subRow.forEach((subRowInstance) => {
            let subRowObj = {};
            subRowInstance.props.children.forEach((subRowElement: any) => {
              if (Object.keys(subRowElement.props).length > 0) {
                if (subRowElement.props.cellId === FILTERABLE_CONTENT) {
                  subRowObj = {
                    ...subRowObj,
                    [subRowElement.props.cellId]: onlyText(
                      subRowElement as React.ReactNode,
                    ),
                  };
                } else {
                  subRowObj = {
                    ...subRowObj,
                    [subRowElement.props.cellId]: [
                      subRowElement.props.children,
                    ],
                  };
                }
              }
            });
            subRowObjArr.push(subRowObj);
          });
          rowObj = { ...rowObj, subRows: subRowObjArr };
        }
        rowObjArr.push(rowObj);
      }
    });
  }
  return rowObjArr;
}

export function altFlexRender<TProps extends object>(
  Comp: ((props: TProps) => string) | string | undefined,
  altFlexProps: TProps,
) {
  return typeof Comp === 'function' ? Comp(altFlexProps) : Comp;
}

export function getColumns(
  tableColumns: any[],
  setTableColumns: {
    (value: React.SetStateAction<ColumnDef<any>[]>): void;
    (value: React.SetStateAction<ColumnDef<any>[]>): void;
    (value: React.SetStateAction<ColumnDef<any>[]>): void;
    (value: React.SetStateAction<ColumnDef<any>[]>): void;
    (arg0: any): void;
  },
  subcomponentGeneratedColumns: any[] | undefined,
) {
  if (
    tableColumns &&
    tableColumns.length === 0 &&
    subcomponentGeneratedColumns &&
    subcomponentGeneratedColumns.length > 0
  ) {
    setTableColumns(subcomponentGeneratedColumns);
  }
  return tableColumns;
}

export function getTableData(
  tableData: any[],
  setTableData: (arg0: any[]) => void,
  subcomponentGeneratedData: any[],
) {
  if (
    tableData &&
    tableData.length === 0 &&
    subcomponentGeneratedData &&
    subcomponentGeneratedData.length !== 0
  ) {
    setTableData(subcomponentGeneratedData);
  }
  return tableData;
}

export const handleSortClick = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  header: typeof Header,
  table: Table<any>,
  pdsTable: any,
  hasExpandableRows: boolean,
) => {
  if (hasExpandableRows) {
    pdsTable.current.classList.remove('pds-c-data-table--rendered');
  }

  header.column.toggleSorting();

  handleRowCollapse(pdsTable, hasExpandableRows);

  const customEvent = new CustomEvent('pds-data-table-sorted', {
    bubbles: true,
    composed: true,
    detail: {
      id: header.column.id,
      // showing "next" sorting order to show what the sort really just changed to, or 'none' for no sort direction
      sort: header.column.getNextSortingOrder() || 'none',
    },
  });

  // Note: We are aware of the "Uncaught TypeError: Converting circular structure to JSON" error that occurs in the console on Storybook and it can
  // be ignored. This error does not affect the functionality of the component and is simply due to how storybook is stringifying the event object.
  event.target.dispatchEvent(customEvent);
};

export const getHeaderCellMarkup = (
  disableAllSorting: boolean,
  header: typeof Header,
  table: Table<any>,
  pdsTable: any,
  hasExpandableRows: boolean,
) => {
  const headerData = flexRender(
    header.column.columnDef.header,
    header.getContext(),
  );

  if (!disableAllSorting && header.column.getCanSort()) {
    return (
      <button
        type="button"
        className="pds-c-data-table--sortable-header"
        onClick={(event) =>
          handleSortClick(event, header, table, pdsTable, hasExpandableRows)
        }
      >
        <span className="pds-c-data-table__column-header-text">
          {headerData}
        </span>
        <span
          className="pds-c-data-table__sort-chevrons"
          data-sort={
            header.column.getIsSorted() ? header.column.getIsSorted() : ''
          }
        >
          <PdsIconChevronUp size="sm" />
          <PdsIconChevronDown size="sm" />
          &nbsp;
        </span>
      </button>
    );
  }
  return (
    <div>
      <div className="pds-c-data-table__column-header-text">{headerData}</div>
    </div>
  );
};

export const getCellMarkup = (cell: typeof Cell) => {
  const styles: { minWidth?: string; maxWidth?: string; width?: string } = {};
  if (
    cell.column.columnDef.meta &&
    (cell.column.columnDef.meta as ColumnMetaData).width
  ) {
    styles.minWidth = (cell.column.columnDef.meta as ColumnMetaData).width;
    styles.maxWidth = (cell.column.columnDef.meta as ColumnMetaData).width;
    styles.width = (cell.column.columnDef.meta as ColumnMetaData).width;
  }

  const classNames = `${
    cell.column.columnDef.meta &&
    ((cell.column.columnDef.meta as ColumnMetaData).type === 'display' ||
      (cell.column.columnDef.meta as ColumnMetaData).borders === true)
      ? 'pds-c-data-table__display-cell'
      : 'pds-c-data-table__accessor-cell'
  } ${
    cell.column.columnDef.meta &&
    (cell.column.columnDef.meta as ColumnMetaData).align
      ? `pds-c-data-table--align-${
          (cell.column.columnDef.meta as ColumnMetaData).align
        }`
      : `pds-c-data-table--align-left`
  } ${
    cell.column.columnDef.meta &&
    (cell.column.columnDef.meta as ColumnMetaData).hidden === true
      ? 'pds-c-data-table__hidden-filter-text'
      : ''
  } ${
    (cell.column.columnDef.meta as ColumnMetaData)?.type
      ? `pds-c-data-table__
        ${(cell.column.columnDef.meta as ColumnMetaData).type}-cell`
      : ''
  }`;
  return (
    <td
      key={cell.id}
      style={styles}
      id={cell.id}
      data-row={cell.row.id}
      data-sync-id={(cell.column.columnDef.meta as ColumnMetaData)?.syncId}
      className={classNames}
    >
      <div className="pds-c-data-table__cell-text">
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    </td>
  );
};
