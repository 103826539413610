import * as React from 'react';
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  ColumnFiltersState,
  SortingState,
} from '@tanstack/react-table';
import { filter, onlyText } from 'react-children-utilities';
import { useTranslation, I18nextProvider } from 'react-i18next';
import '@principal/design-system/css/data-table/data-table.scss';
import {
  PdsIconAlertCircle,
  PdsIconSearch,
} from '@principal/design-system-icons-react';
import { nothing } from 'lit';
import { PdsTable } from '../table/table';
import { PdsGrid } from '../grid/grid';
import { PdsGridItem } from '../grid-item/grid-item';
import { PdsSelect } from '../select/select';
import { PdsPagination } from '../pagination/pagination';
import { PdsDataTableProps } from '../../built-component-props';
import i18next from '../../../../i18n';
import { FilterInput } from './data-table-filter-input';
import { LoadingTable } from './data-table-loading-table';
import {
  getSlottedColumnDataModel,
  getSlottedRowDataModel,
  useSkipper,
  ColumnMetaData,
  getColumns,
  altFlexRender,
  getHeaderCellMarkup,
  getCellMarkup,
  getTableData,
} from './data-table-react-utils';
import {
  generatePaginationItems,
  handlePaginationClick,
  handlePaginationItemClick,
} from './data-table-pagination';

export const PdsDataTable = React.forwardRef<React.Ref<any>, PdsDataTableProps>(
  (props: PdsDataTableProps, ref?: React.Ref<any>) => {
    const { t } = useTranslation();
    const {
      data,
      children,
      columns,
      disableAllSorting = false,
      expandAllOnLoad = false,
      fixedHeight,
      globalFilter,
      hideCaption = false,
      hideFilter = false,
      hoverableRows = false,
      loadingColumnLength = 4,
      pageSize = 5,
      paginationSelectArray = [pageSize, 10, 25],
      paginationVariant = 'default',
      removeBorder = false,
      stickyColumn = false,
      stickyHeader = false,
      striped = 'default',
      errorInFetchingData = false,
      noAvailableData = false,
    } = props;

    const [columnFilters, setColumnFilters] = React.useState<
      typeof ColumnFiltersState
    >([]);
    const [tableGlobalFilter, setTableGlobalFilter] =
      React.useState(globalFilter);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hasExpandableRows, setHasExpandableRows] = React.useState(Boolean);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sorting, setSorting] = React.useState<typeof SortingState>([]);
    const [tableData, setTableData] = React.useState(data || []);
    const [tableColumns, setTableColumns] = React.useState(columns || []);
    const [caption, setCaption] = React.useState('');
    const [subcomponentGeneratedData, setSubcomponentGeneratedData] =
      React.useState([]);
    const paginator = React.useRef(null);
    const pdsTable: any = React.useRef<HTMLElement>(null);
    const [subcomponentGeneratedColumns, setSubcomponentGeneratedColumns] =
      React.useState([]);
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
    const sortStringObj = {
      asc: 'ascending',
      desc: 'descending',
    };

    let liveRegionText = '';
    let sortDirection: string;

    React.useEffect(() => {
      if (data) {
        setTableData(data);
      }
    }, [data]);

    React.useEffect(() => {
      if (columns) {
        setTableColumns(columns);
      }
    }, [columns]);

    React.useEffect(() => {
      if (
        tableGlobalFilter === '' &&
        pdsTable &&
        pdsTable.current &&
        pdsTable.current.wrapper
      ) {
        pdsTable.current.handleCollapseAll(false);
        pdsTable.current.wrapper.dispatchEvent(new Event('change'));
      } else {
        pdsTable.current?.handleExpandAll(false);
      }
    }, [tableGlobalFilter]);

    // get data from slotted PdsDataTableColumns subcomponents
    React.useMemo(() => {
      let pdsDataTableColumnSubcomponents;
      let columnSlotDataModel: any;

      const pdsDataTableColumnSubcomponentsContainer = filter(
        children,
        (child: any) => {
          if (
            child &&
            child.props &&
            child.props.slot &&
            child.props.slot === 'columns'
          ) {
            return (
              child &&
              child.props &&
              child.props.slot &&
              child.props.slot === 'columns'
            );
          }
          return false;
        },
      );

      if (pdsDataTableColumnSubcomponentsContainer.length === 1) {
        // get PdsDataTableColumn subcomponents
        pdsDataTableColumnSubcomponents = filter(
          (pdsDataTableColumnSubcomponentsContainer[0] as React.ReactElement)
            ?.props.children,
          (child: any) => {
            if (child.props && child.props.columnId) {
              return child.props && child.props.columnId;
            }
            return false;
          },
        );
        if (pdsDataTableColumnSubcomponents.length > 0) {
          columnSlotDataModel = getSlottedColumnDataModel(
            pdsDataTableColumnSubcomponents,
          );

          if (columnSlotDataModel.length > 0) {
            setSubcomponentGeneratedColumns(columnSlotDataModel);
          }
        }
      } else if (pdsDataTableColumnSubcomponentsContainer.length > 1) {
        // eslint-disable-next-line no-console
        console.error(
          'Only one pdsDataTableColumnsSubcomponent component is allowed',
        );
      }
    }, [children]);

    // get data from slotted PdsDataTableRows subcomponents
    React.useMemo(() => {
      let pdsDataTableRowSubcomponents;
      let rowSlotDataModel: any = [];

      const pdsDataTableRowSubcomponentsContainer = filter(
        children,
        (child: any) => {
          if (child.props && child.props.slot) {
            return (
              child.props && child.props.slot && child.props.slot === 'rows'
            );
          }
          return false;
        },
      );

      if (pdsDataTableRowSubcomponentsContainer.length === 1) {
        // get PdsDataTableRow subcomponents
        pdsDataTableRowSubcomponents = filter(
          (pdsDataTableRowSubcomponentsContainer[0] as any).props.children,
          (child: any) => {
            if (child.props && child.props.children) {
              return child.props && child.props.children;
            }
            return false;
          },
        );
        if (pdsDataTableRowSubcomponents.length > 0) {
          rowSlotDataModel = getSlottedRowDataModel(
            pdsDataTableRowSubcomponents,
          );

          if (rowSlotDataModel.length > 0) {
            setSubcomponentGeneratedData(rowSlotDataModel);
          }
        }
      } else if (pdsDataTableRowSubcomponentsContainer.length > 1) {
        // eslint-disable-next-line no-console
        console.error('Only one pdsDataTableRows subcomponent is allowed');
      }
    }, [children]);

    // get PdsHeading subcomponent for caption and set caption
    const PdsDataTableCaption = filter(children, (child: any) => {
      if (child.props && child.props.slot) {
        return child.props.slot === 'caption';
      }
      return false;
    });

    React.useEffect(() => {
      setCaption(onlyText(PdsDataTableCaption));
    }, [PdsDataTableCaption]);

    React.useEffect(() => {
      if (subcomponentGeneratedColumns.length > 0) {
        setTableColumns(subcomponentGeneratedColumns);
      }
    }, [subcomponentGeneratedColumns]);

    React.useEffect(() => {
      if (subcomponentGeneratedData.length > 0) {
        setTableData(subcomponentGeneratedData);
      }
    }, [subcomponentGeneratedData]);

    React.useEffect(() => {
      const dataChangedEvent = new CustomEvent('pds-data-table-data-changed', {
        bubbles: true,
        composed: true,
        detail: {
          data: tableData,
        },
      });
      (pdsTable.current as Element)?.dispatchEvent(dataChangedEvent);
    });

    const getData = () => {
      return getTableData(tableData, setTableData, subcomponentGeneratedData);
    };

    // build Tanstack table
    const table = useReactTable({
      data: getData(),
      columns: getColumns(
        tableColumns,
        setTableColumns,
        subcomponentGeneratedColumns,
      ),
      state: {
        columnFilters,
        globalFilter: tableGlobalFilter,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setTableGlobalFilter,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getSubRows: (row: { subRows: [] }) => row.subRows,
      getPaginationRowModel: getPaginationRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      getFacetedMinMaxValues: getFacetedMinMaxValues(),
      autoResetPageIndex,
      meta: {
        getData,
        updateData: /* istanbul ignore next */ (
          rowIndex: number,
          columnId: number,
          value: string,
        ) => {
          // Skip page index reset until after next rerender
          skipAutoResetPageIndex();
          setTableData((old) =>
            old.map((row: {}, index: number) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex]!,
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
        },
      },
    });

    React.useEffect(() => {
      if (paginator.current) {
        (paginator.current as Element).addEventListener(
          'pds-pagination-click',
          (e) =>
            handlePaginationClick(
              e as CustomEvent,
              table,
              pdsTable,
              hasExpandableRows,
            ),
        );
      }
    });

    React.useEffect(() => {
      if (paginationVariant === 'none') {
        table.setPageSize(9999);
      } else {
        table.setPageSize(pageSize);
      }
    }, [table, pageSize, paginationVariant]);

    if (pdsTable.current) {
      (pdsTable.current as HTMLElement).classList.add(
        'pds-c-data-table--rendered',
        'pds-c-data-table--table-visible',
      );

      if (stickyColumn) {
        (pdsTable.current as HTMLElement).setAttribute('stickyColumn', '');
      }

      if (stickyHeader) {
        (pdsTable.current as HTMLElement).setAttribute('stickyHeader', '');
      }

      if (hoverableRows) {
        (pdsTable.current as HTMLElement).setAttribute('hoverableRows', '');
      }

      if (removeBorder) {
        (pdsTable.current as HTMLElement).setAttribute('removeBorder', '');
      }

      if (expandAllOnLoad) {
        if (hasExpandableRows) {
          pdsTable.current.handleExpandAll(false);
        }
      }
    }

    React.useEffect(() => {
      setHasExpandableRows(table.getCanSomeRowsExpand());
    }, [table]);

    // making sortDirection state results in React too many re-renders error
    const returnSortString = (sortValue: string | boolean): 'asc' | 'desc' => {
      if (sortValue === 'desc') {
        sortDirection = t('descending');
        return 'desc';
      }
      sortDirection = t('ascending');
      return 'asc';
    };

    // making updateLiveRegion state results in React too many re-renders error
    const updateLiveRegion = (headerName: string) => {
      liveRegionText = t('data-table-live-region-text', {
        caption,
        headerName,
        sortDirection,
      });
    };

    const currentColumns = getColumns(
      tableColumns,
      setTableColumns,
      subcomponentGeneratedColumns,
    );
    if (!currentColumns || currentColumns.length === 0) {
      return (
        <div ref={ref} className="pds-c-data-table__wrapper">
          <LoadingTable columns={loadingColumnLength} rows={pageSize} />
        </div>
      );
    }

    const returnStateMarkup = () => {
      if (errorInFetchingData) {
        return (
          <tr>
            <td colSpan={currentColumns.length}>
              <div className="pds-c-data-table__error-state-container">
                <div className="pds-c-data-table_error-state">
                  <div className="pds-c-data-table__alert-circle">
                    <PdsIconAlertCircle />
                  </div>
                  <div className="pds-c-data-table__error-text">
                    <span>{t('error-displaying-table-data')}</span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        );
      }

      if (noAvailableData) {
        return (
          <tr>
            <td colSpan={currentColumns.length}>
              <div className="pds-c-data-table__no-data-state-container">
                <div className="pds-c-data-table_no-data-state">
                  <span className="pds-c-data-table__no-data-text">
                    {t('no-available-table-data')}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        );
      }

      return nothing;
    };

    return (
      <I18nextProvider i18n={i18next}>
        <div ref={ref} className="pds-c-data-table__wrapper">
          <PdsGrid
            break="faster"
            variant={!hideCaption && !hideFilter ? '2up' : 'default'}
            className="pds-c-data-table__header"
          >
            {hideCaption ? null : (
              <PdsGridItem>
                <div aria-hidden="true">{PdsDataTableCaption}</div>
              </PdsGridItem>
            )}
            {hideFilter ? null : (
              <PdsGridItem>
                <div>
                  <FilterInput
                    value={tableGlobalFilter ?? ''}
                    onChange={(value) => setTableGlobalFilter(String(value))}
                  >
                    <span slot="suffix">
                      <PdsIconSearch />
                    </span>
                  </FilterInput>
                </div>
              </PdsGridItem>
            )}
          </PdsGrid>
          <PdsTable
            striped={striped}
            fixedHeight={fixedHeight}
            className={`pds-c-data-table ${
              removeBorder ? '' : 'pds-c-data-table--with-border'
            }`}
            ref={pdsTable}
          >
            <table className="pds-c-table">
              <caption className="pds-c-data-table__caption">{caption}</caption>
              <thead>
                {table
                  .getHeaderGroups()
                  .map((headerGroup: { id: number; headers: any[] }) => (
                    <tr
                      key={headerGroup.id}
                      className={
                        hasExpandableRows
                          ? 'pds-c-data-table__expandable-row-spacer'
                          : ''
                      }
                    >
                      {headerGroup.headers.map((header) => {
                        let sortString;
                        if (typeof header.column.getIsSorted() === 'string') {
                          sortString =
                            sortStringObj[
                              returnSortString(header.column.getIsSorted())
                            ];
                          const colName = altFlexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          );
                          updateLiveRegion(onlyText(colName));
                        }
                        return (
                          <th
                            // @ts-expect-error TS doesn't like width prop
                            width={
                              !hasExpandableRows &&
                              header.column.columnDef.meta &&
                              (header.column.columnDef.meta as ColumnMetaData)
                                .width
                                ? (
                                    header.column.columnDef
                                      .meta as ColumnMetaData
                                  ).width
                                : ''
                            }
                            key={header.id}
                            colSpan={header.colSpan}
                            aria-sort={sortString || ''}
                            className={`${
                              header.column.columnDef.meta &&
                              (header.column.columnDef.meta as ColumnMetaData)
                                .borders === true
                                ? 'pds-c-data-table__display-cell'
                                : 'pds-c-data-table__accessor-cell'
                            } ${
                              header.column.getCanSort()
                                ? 'pds-c-data-table--sortable-header-th'
                                : ''
                            } ${
                              header.column.columnDef.meta &&
                              (header.column.columnDef.meta as ColumnMetaData)
                                .align
                                ? `pds-c-data-table--align-${
                                    (
                                      header.column.columnDef
                                        .meta as ColumnMetaData
                                    ).align
                                  }`
                                : 'pds-c-data-table--align-left'
                            } ${
                              header.column.columnDef.meta &&
                              (header.column.columnDef.meta as ColumnMetaData)
                                .hidden === true
                                ? 'pds-c-data-table__hidden-filter-text'
                                : ''
                            } ${
                              (header.column.columnDef.meta as ColumnMetaData)
                                ?.type
                                ? `pds-c-data-table__${
                                    (
                                      header.column.columnDef
                                        .meta as ColumnMetaData
                                    ).type
                                  }-cell`
                                : ''
                            }`}
                          >
                            {header.isPlaceholder
                              ? ''
                              : getHeaderCellMarkup(
                                  disableAllSorting,
                                  header,
                                  table,
                                  pdsTable,
                                  hasExpandableRows,
                                )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
              </thead>
              <tbody>
                {errorInFetchingData || noAvailableData
                  ? returnStateMarkup()
                  : table &&
                    table
                      .getRowModel()
                      .rows.map(
                        (row: {
                          getCanExpand: () => boolean;
                          id: any;
                          getVisibleCells: () => any[];
                          subRows: any[];
                        }) => {
                          if (row.getCanExpand()) {
                            // this is a collapsible row
                            // aria-rowindex value must be >= 1, see https://www.w3.org/TR/wai-aria-1.1/#aria-rowindex
                            return (
                              <tr
                                key={row.id}
                                className="pds-c-table__expandable-row"
                                role="row"
                                aria-rowindex={Number(row.id) + 1}
                              >
                                <td colSpan={5}>
                                  <div className="pds-c-table__expandable-row-wrapper">
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      className="pds-c-table__expandable-row-table"
                                    >
                                      <tbody>
                                        <tr key={row.id}>
                                          {table &&
                                            row
                                              .getVisibleCells()
                                              .map((cell) =>
                                                getCellMarkup(cell),
                                              )}
                                        </tr>
                                        {table &&
                                          row.subRows.map((subRow) => {
                                            if (
                                              Object.keys(
                                                subRow.original,
                                              ).includes('fullWidth')
                                            ) {
                                              return (
                                                <tr key={subRow.id}>
                                                  <td
                                                    colSpan={
                                                      table.getHeaderGroups()[0]
                                                        .headers.length
                                                    }
                                                  >
                                                    {subRow.original.fullWidth}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                            return (
                                              <tr key={subRow.id}>
                                                {table &&
                                                  subRow
                                                    .getVisibleCells()
                                                    .map((cell: any) =>
                                                      getCellMarkup(cell),
                                                    )}
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                          // this is a regular row
                          // aria-rowindex value must be >= 1, see https://www.w3.org/TR/wai-aria-1.1/#aria-rowindex
                          return (
                            <tr
                              key={row.id}
                              className={
                                hasExpandableRows
                                  ? 'pds-c-data-table__expandable-row-spacer'
                                  : ''
                              }
                              role="row"
                              aria-rowindex={Number(row.id) + 1}
                            >
                              {table &&
                                row
                                  .getVisibleCells()
                                  .map((cell) => getCellMarkup(cell))}
                            </tr>
                          );
                        },
                      )}
              </tbody>
            </table>
            <span
              className="pds-c-data-table__live-region pds-u-sr-only"
              aria-live="polite"
            >
              {liveRegionText}
            </span>
          </PdsTable>
          <div className="pds-c-data-table__footer">
            {paginationVariant === 'none' ? null : (
              <>
                <div className="pds-c-data-table__show-entries">
                  {table.getFilteredRowModel().rows.length === 0 ? (
                    t('no-entries')
                  ) : (
                    <>
                      {t('show')}{' '}
                      <PdsSelect
                        className="pds-c-data-table__pagination-select"
                        name="paginationSelect"
                        size="sm"
                        hideLabel
                        label={t('number-rows-displayed')}
                        value={table.getState().pagination.pageSize.toString()}
                        onChange={(e) => {
                          table.setPageSize(
                            Number((e.target as HTMLSelectElement)?.value),
                          );
                          handlePaginationItemClick(
                            hasExpandableRows,
                            pdsTable,
                            table,
                            -1,
                          );
                        }}
                      >
                        {paginationSelectArray.map((pageSizeSelectValue) => (
                          <option
                            key={pageSizeSelectValue}
                            value={pageSizeSelectValue}
                          >
                            {pageSizeSelectValue}
                          </option>
                        ))}
                      </PdsSelect>
                      {t('of')} {table.getFilteredRowModel().rows.length}{' '}
                      {table.getFilteredRowModel().rows.length > 1
                        ? t('entries')
                        : t('entry')}
                    </>
                  )}
                </div>
                <PdsPagination
                  ref={paginator}
                  variant={paginationVariant}
                  className="pds-c-data-table__paginator"
                  backwardDisabled={!table.getCanPreviousPage()}
                  forwardDisabled={!table.getCanNextPage()}
                >
                  {generatePaginationItems(table, pdsTable, hasExpandableRows)}
                </PdsPagination>
              </>
            )}
          </div>
        </div>
      </I18nextProvider>
    );
  },
);
