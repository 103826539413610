import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/no-relative-packages
import translations from '../../localization/translations';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    resources: translations,
    detection: {
      lookupLocalStorage: 'pds-language',
      lookupQuerystring: 'lang',
      order: [
        'localStorage',
        'htmlTag',
        'querystring',
        'cookie',
        'sessionStorage',
        'navigator',
      ],
    },
  });

i18next.languages = ['en', 'es'];

export default i18next;
