import * as React from 'react';
import { EventName } from '@lit/react';
import { PdsGlobalHeaderUserMenuDropdownView as PdsGlobalHeaderUserMenuDropdownViewElement } from '@principal/design-system';
import { PdsGlobalHeaderUserMenuDropdownViewProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsGlobalHeaderUserMenuDropdownView =
  createPdsReactComponent<PdsGlobalHeaderUserMenuDropdownViewProps>()({
    tagName: 'pds-global-header-user-menu-dropdown-view',
    elementClass: PdsGlobalHeaderUserMenuDropdownViewElement,
    react: React,
    events: {
      onGlobalHeaderUserMenuOpen:
        'pds-global-header-user-menu-open' as EventName<CustomEvent>,
      onGlobalHeaderUserMenuClose:
        'pds-global-header-user-menu-close' as EventName<CustomEvent>,
    },
  });
