import * as React from 'react';
import { PdsIconSearch } from '@principal/design-system-icons-react';
import { PdsTextInput } from '../text-input/text-input';

export const FilterInput = ({
  value: initialValue,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <PdsTextInput
      className="pds-c-data-table__filter-input"
      name="suffixedInput"
      size="sm"
      label="Filter table"
      hideLabel
      id="tableFilter"
      value={value}
      onChange={/* istanbul ignore next */ (e: any) => setValue(e.target.value)}
    >
      <PdsIconSearch slot="suffix" />
    </PdsTextInput>
  );
};
