export enum EmploymentStatus {
    ACTIVELY_AT_WORK = 'ACTIVELY_AT_WORK',
    RETIRED = 'RETIRED',
    STATE_CONTINUATION = 'STATE_CONTINUATION',
    COBRA_CONTINUATION = 'COBRA_CONTINUATION',
    UNKNOWN = 'UNKNOWN'
}

export namespace EmploymentStatus {
    export function isContinuation(employmentStatus: EmploymentStatus = EmploymentStatus.UNKNOWN) {
        return [EmploymentStatus.STATE_CONTINUATION, EmploymentStatus.COBRA_CONTINUATION].includes(employmentStatus);
    }
}