import { DependentType } from "../domain/claims/disability/DependentType";

export enum Relationship {
    YOURSELF = 'YOURSELF',
    SPOUSE = 'SPOUSE',
    DOMESTIC_PARTNER = 'DOMESTIC_PARTNER',
    CHILD = 'CHILD'
}

const headerByRelationship = new Map([
    [Relationship.YOURSELF, 'Yourself'],
    [Relationship.SPOUSE, 'Your spouse'],
    [Relationship.DOMESTIC_PARTNER, 'Your partner'],
    [Relationship.CHILD, 'Your dependents'],
])

const relationshipByDependentType = new Map([
    [DependentType.SPOUSE, Relationship.SPOUSE],
    [DependentType.CHILD, Relationship.CHILD],
    [null, Relationship.YOURSELF],
    [undefined, Relationship.YOURSELF]
])

export namespace Relationship {
    export function findRelationshipHeaderFor(relationship: Relationship) {
        return headerByRelationship.get(relationship)
    }
    export function findRelationshipFor(dependentType?: DependentType | null) {
        return relationshipByDependentType.get(dependentType);
    }
    export function isDependent(relationship: Relationship) {
        return [Relationship.DOMESTIC_PARTNER, Relationship.SPOUSE, Relationship.CHILD].includes(relationship);
    }
}