import { LongTermDisability, PlanType, ShortTermDisability } from "benefit-service-types";
import { Member } from "../domain/Member";
import currency from "currency.js";
import { Employment } from "../domain/Employment";
import { WageFrequency } from "../enums/WageFrequency";
import { Coverage } from "../enums/Coverage";
import { findValueByKey } from "../enums/FindKeyByValue";

export function findBenefitAmountFor(coverage: Coverage, product: ShortTermDisability | LongTermDisability, member: Member): currency {
    const shortTerm = product as ShortTermDisability
    const longTerm = product as LongTermDisability
    switch (findValueByKey(PlanType, product.planType)) {
        case PlanType.FLAT:
            let amount = currency(shortTerm.flatAmount!!);
            if(!shortTerm.sixtySixPercentLimit) {
                return amount;
            }
            const twoThirdsOfWeeklySalary = findTwoThirdsOfWeeklySalary(member)
            amount = currency(Math.min(twoThirdsOfWeeklySalary.value, amount.value))
            return currency(Math.max(amount.value, shortTerm.minimumBenefitAmount!!))
        case PlanType.PERCENT_OF_SALARY:
            const wage = coverage === Coverage.SHORT_TERM_DISABILITY ? findWeeklySalary(member) : findMonthlySalary(member)
            const percentOfSalary = wage.multiply(product.benefitPercent!!).divide(100).value;
            return currency(Math.min(percentOfSalary,
                shortTerm.maximumAmount || longTerm.maximumBenefitAmount || percentOfSalary))
        case PlanType.INCREMENT:
            return currency(member.benefit.approvedAmount)
    }
    return currency(0)
}

export function findAmountFrequencyFor(coverage: Coverage): WageFrequency {
    return coverage === Coverage.SHORT_TERM_DISABILITY ? WageFrequency.WEEK : WageFrequency.MONTH
}

function findTwoThirdsOfWeeklySalary(member: Member): currency {
    const weeklyWage = findWeeklySalary(member);
    return weeklyWage.multiply(2).divide(3);
}

function findWeeklySalary(member: Member) {
    const yearlyWage = findAnnualSalary(member)
    return yearlyWage.divide(52);
}

function findMonthlySalary(member: Member) {
    const yearlyWage = findAnnualSalary(member)
    return yearlyWage.divide(12);
}

function findAnnualSalary(member: Member) {
    const employment = member.employment;
    const wagePerPayPeriod = currency(employment.wage || 0);
    const wageFrequency = employment.wageFrequency;
    if(!wagePerPayPeriod || !wageFrequency) {
        return currency(0);
    }
    const payPeriodsPerYear = findNumberOfPayPeriodsForYear(employment, wageFrequency);
    return wagePerPayPeriod.multiply(payPeriodsPerYear);
}

function findNumberOfPayPeriodsForYear(employment: Employment, wageFrequency: WageFrequency): number {
    switch (wageFrequency) {
        case WageFrequency.HOUR:
            return 52 * Math.floor(employment.hoursWorkedPerWeek || 0);
        case WageFrequency.WEEK:
            return 52;
        case WageFrequency.BIWEEKLY:
            return 26;
        case WageFrequency.SEMIMONTHLY:
            return 24;
        case WageFrequency.MONTH:
            return 12;
        case WageFrequency.YEAR:
            return 1;
    }
}
