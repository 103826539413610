import React from "react";
import { Benefit } from "../domain/Benefit";
import { PdsAlert } from "@principal/design-system-react";
import { DateTime } from "luxon";
import { EmploymentStatus } from "../enums/EmploymentStatus";

interface TerminationAlertProps {
    benefit: Benefit;
    employmentStatus?: EmploymentStatus;
}

const TerminationAlert = (props: TerminationAlertProps & Omit<React.HTMLProps<HTMLAnchorElement>, keyof TerminationAlertProps>) => {
    const benefit = props.benefit;
    if(!Benefit.isTerminated(benefit)) {
        return null;
    }
    const terminationDate = benefit.terminationDate;
    const employmentStatus = props.employmentStatus
    const asOfDate = EmploymentStatus.isContinuation(employmentStatus) ? '' : ` as of ${DateTime.fromISO(terminationDate).toLocaleString()}`
    return (
        <PdsAlert variant='error' hideDismissButton={true}>
            {`This coverage has been terminated${asOfDate}. You will have access to your benefit summary for up to 3 months after your termination date, and your claim activity for up to 24 months after your last claim payment.`}
        </PdsAlert>
    );
}
export default TerminationAlert;
