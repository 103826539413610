import React, { ElementType } from "react";
import { PdsButton } from "@principal/design-system-react";
import fileDownload from "js-file-download";
import { Buffer } from "buffer";
import { filesize } from "filesize";

interface DownloadableLinkProps {
    fileName: string
    base64DownloadableContent: string;
    container?: ElementType;
}

const DownloadableLink = (props: DownloadableLinkProps & Omit<React.HTMLProps<HTMLAnchorElement>, keyof DownloadableLinkProps>) => {
    const Container = props.container ?? 'div';
    let buffer: Buffer | undefined = undefined;
    try {
        buffer = Buffer.from(props.base64DownloadableContent, 'base64');
    } catch (ignored) {}
    const byteLength = buffer?.byteLength;
    const fileSize = byteLength ? filesize(byteLength, { standard: "jedec" }) : undefined;

    return (
        <Container>
            <PdsButton link="default"
                       type="button"
                       removeLinkPadding={true}
                       onClick={() => {
                           if (buffer) {
                               fileDownload(buffer, props.fileName)
                           }
                       }}>
                {props.children}
            </PdsButton>
            <b><small> PDF{fileSize ? `, ${fileSize}` : ''}</small></b>
        </Container>
    );
}

export default DownloadableLink;
