import { PdsLayoutContainer, PdsGrid, PdsGridItem, PdsSidebar, PdsTextInput, PdsHeading } from "@principal/design-system-react";
import { DateTime } from "luxon";
import { Claim } from "../domain/Claim";
import { Document } from "../domain/Document";
import ClaimStatusButton from "../atoms/ClaimStatusButton";
import { Relationship } from "../enums/Relationship";
import { findKeyByValue, snakeCaseToCapitalized } from "../enums/FindKeyByValue";
import { PdsFileUpload } from "@principal/design-system-react";
import Benefits from "../molecules/Benefits";
import { useParams } from "react-router-dom";
import { useClaims } from "../hooks/useClaims";
import LoadingSpinner from "../atoms/LoadingSpinner";
import React, { useRef, useState } from "react";
import {
    FIND_CLAIM_ANALYST_QUERY,
    FIND_CLAIM_DOCUMENT_QUERY,
    FIND_CLAIMS_QUERY,
    FIND_EXPLANATION_OF_BENEFITS_QUERY,
    UPLOAD_CLAIM_DOCUMENT_MUTATION
} from "../atoms/Queries";
import { PdsIconAlertTriangle, PdsIconCheckCircle } from "@principal/design-system-icons-react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import pds from '../pds.module.scss';
import { ClaimStatus } from "../enums/ClaimStatus";
import DataTable from "../molecules/DataTable";
import { Payment } from "../domain/Payment";
import { PdsButton } from "@principal/design-system-react";
import { PdsModal } from "@principal/design-system-react";
import fileDownload from 'js-file-download'
import { Buffer } from "buffer";
import { DisabilityClaim } from "../domain/claims/disability/DisabilityClaim";
import FlatTable from "../molecules/FlatTable";
import { Coverage } from "../enums/Coverage";
import findPaperClaimFormNumberFor = Coverage.findPaperClaimFormNumberFor;
import { useCoverage } from "../hooks/useCoverage";
import { SourceSystem } from "../enums/claims/disability/SourceSystem";
import isInNewClaimSystem = SourceSystem.isInClaimsService;
import { useMember } from "../hooks/useMember";
import AdobeDocuSignLink from "../atoms/AdobeDocuSignLink";
import findDepartmentExtensionFor = Coverage.findDepartmentExtensionFor;
import { Party } from "../enums/Party";

const ClaimDetail = () => {
    const { claimId } = useParams();
    const coverage = useCoverage();
    const { loading: memberLoading, data: member, error: memberError } = useMember()
    const { loading: claimsLoading, data: claims, error: claimsError, variables } = useClaims()
    const { loading: analystLoading, data: analystData, error: analystError } = useQuery(FIND_CLAIM_ANALYST_QUERY, { variables: { claimId } })
    const [findExplanationOfBenefits] = useLazyQuery(FIND_EXPLANATION_OF_BENEFITS_QUERY)
    const [findClaimDocument] = useLazyQuery(FIND_CLAIM_DOCUMENT_QUERY)
    const [uploadClaimDocument, { loading: uploadLoading, data: uploadData, error: uploadError }] = useMutation(UPLOAD_CLAIM_DOCUMENT_MUTATION, {
        context: {
            headers: {
                'Apollo-Require-Preflight': true
            }
        }
    })

    const [paymentModalContents, setPaymentModalContents] = useState(<></>);
    const [file, setFile] = useState(null as any as File);
    const [fileDescription, setFileDescription] = useState('');

    const paymentModalRef = useRef(null) as React.RefObject<any>;
    const fileUploadRef = useRef(null) as React.RefObject<any>;

    const claim = claims?.find((c: Claim) => c.claimId === claimId)
    const submissionDate = claim?.submitted?.toLocaleString(DateTime.DATE_SHORT);
    const departmentExtension = findDepartmentExtensionFor(coverage)
    const phoneNumber = `800-245-1522${departmentExtension ? ` x${departmentExtension}` : ''} (Español: 800-243-1404)`;
    return (
        <>
            <div id="claim" className="pds-u-background-brand-gradient-subtle">
                <PdsLayoutContainer>
                    {buildClaim()}
                </PdsLayoutContainer>
            </div>
            <div id="recent-updates">
                <PdsLayoutContainer>
                    {buildRecentUpdates()}
                </PdsLayoutContainer>
            </div>
            <Benefits relationship={claim?.relationship}/>
            <div id="payments" className="pds-u-background-subtle">
                <PdsLayoutContainer>
                    {buildPayments()}
                    <PdsModal modalTitle="Payment benefits"
                              ref={paymentModalRef}>
                        {paymentModalContents}
                    </PdsModal>
                </PdsLayoutContainer>
            </div>
        </>
    );

    function buildClaim() {
        if(claimsLoading) {
            return <LoadingSpinner/>
        } else if(claimsError) {
            console.log('claims error', claimsError)
            return <p>Could not load claim information, please try again later.</p>;
        } else if(!claim) {
            return <p>Claim not found.</p>
        }
        const relationship = claim.relationship;
        return <div className="flex-gap">
            <PdsGrid variant="3up">
                <PdsGridItem>
                    <PdsHeading headingTag="h2">{claim.type} claim {claim.claimId}</PdsHeading>
                </PdsGridItem>
                <PdsGridItem>
                </PdsGridItem>
                <PdsGridItem>
                    <ClaimStatusButton claim={claim} style={{ pointerEvents: 'none' }}/>
                </PdsGridItem>
            </PdsGrid>
            <PdsGrid variant="3up">
                <PdsGridItem>
                    {claim.person && <>
                        <strong>
                            Person:{Relationship.YOURSELF === relationship
                            ? ''
                            : ` ${snakeCaseToCapitalized(findKeyByValue(Relationship, relationship))}`}
                        </strong>
                        <div>{claim.person}</div>
                    </>}
                </PdsGridItem>
                <PdsGridItem>
                    <strong>Submitted:</strong>
                    <div>{submissionDate}</div>
                </PdsGridItem>
                <PdsGridItem>
                    <strong>Total paid:</strong>
                    <div>{claim.amount.format()}</div>
                    <small>Represents all payments made on claim to date and should not be considered final unless status is "Approved".</small>
                </PdsGridItem>
            </PdsGrid>
        </div>
    }

    function buildRecentUpdates() {
        return <>
            {buildClaimStatus()}
            {buildDocuments()}
        </>;
    }

    function buildClaimStatus() {
        if(claimsLoading) {
            return <LoadingSpinner/>
        } else if(claimsError) {
            console.log('claims error', claimsError)
            return <p>Could not load claim information, please try again later.</p>;
        } else if(!claim) {
            return <p>Claim not found.</p>
        }
        const claimStatus = claim.status;
        const requestedInformation = claim.requestedInformation;
        return <PdsGrid variant="2up">
            <PdsGridItem>
                <div className="flex-gap">
                    <PdsHeading headingTag="h2">Recent updates</PdsHeading>
                    {claimStatus === ClaimStatus.DENIED &&
                        <div>
                            <div className="pds-u-flex-align-start">
                                <PdsIconCheckCircle size="default" color={pds['red']}/>
                                <strong>&nbsp;Claim denied</strong>
                            </div>
                            <div> Call {phoneNumber} for more information about the decision or to request to reopen this claim.</div>
                        </div>}
                    {claimStatus === ClaimStatus.APPROVED &&
                        <div>
                            <div className="pds-u-flex-align-start">
                                <PdsIconCheckCircle size="default" color={pds['green']}/>
                                <strong>&nbsp;Claim approved</strong>
                            </div>
                            <div>Your claim has been approved. You can view information about your claim payments below.</div>
                        </div>}
                    {claimStatus === ClaimStatus.NEEDS_MORE_INFORMATION && <>
                        <div>
                            <div className="pds-u-flex-align-start">
                                <PdsIconAlertTriangle size="default" color={pds['orange']}/>
                                <strong>&nbsp;Additional information requested</strong>
                            </div>
                            <div className="pds-u-padding-bottom-8">We need more information from the following parties to review your claim:</div>
                            {
                                [...requestedInformation].map(([party, requestedInformationByParty]) =>
                                    <>
                                        <div>From your {party}:</div>
                                        <ul>
                                            {requestedInformationByParty.map(information => <li>{information}</li>)}
                                        </ul>
                                    </>)
                            }
                            {requestedInformation.get(Party.EMPLOYER)?.length &&
                                <div>
                                    If you filed online, share the link you received in your email with your employer to submit their claim details.
                                    Otherwise, send them this paper form to file their portion of your claim: {buildPaperFormLink(coverage)}
                                </div>
                            }
                        </div>
                    </>}
                    <div>
                        <div>
                            <strong>Claim received {submissionDate}</strong>
                        </div>
                        <div>We will review your claim and collect any additional information. We'll contact you as soon as the review is
                            complete.
                        </div>
                    </div>
                </div>
            </PdsGridItem>
            <PdsGridItem>
                <div className="pds-u-background-brand-gradient-strong pds-u-typography-color-inverted-default pds-u-padding-20 flex-gap"
                     style={{ borderRadius: '10px' }}>
                    <PdsHeading headingTag="h3">Claim analyst</PdsHeading>
                    {buildClaimAnalyst()}
                </div>
            </PdsGridItem>
        </PdsGrid>;
    }

    function buildPaperFormLink(coverage: Coverage) {
        if(memberLoading) {
            return <LoadingSpinner/>
        } else if(memberError) {
            console.log('member error', memberError)
            return <p>Could not load claim form link, please try again later.</p>;
        }
        return <AdobeDocuSignLink documentId={findPaperClaimFormNumberFor(coverage, member)}>Paper claim form</AdobeDocuSignLink>;
    }

    function buildDocuments() {
        if(claim && !isInNewClaimSystem(claim.sourceSystem)) {
            return;
        }
        const disableUpload = !claim || uploadLoading;
        return <>
            <hr/>
            <PdsGrid variant="2up">
                <PdsGridItem>
                    <div className="flex-gap">
                        <PdsHeading headingTag="h2">Documents</PdsHeading>
                        <div>
                            Thank you for providing the following documents. Click on the name of the document to view it. New or
                            updated files can be uploaded.
                        </div>
                        {buildDocumentLinks()}
                    </div>
                </PdsGridItem>
                <PdsGridItem>
                    <div>
                        <PdsFileUpload id="document-upload"
                                       name="documentUpload"
                                       helpText="Files can be PDF, PNG, or JPG no larger than 4mb"
                                       accept='.png,.jpeg,.jpg,.pdf'
                                       size={4096}
                                       ref={fileUploadRef}
                                       pdsFileUploadChange={async (event: Event) => {
                                           const file = (event as CustomEvent).detail?.summary?.[0];
                                           if (!file) {
                                               console.error('File could not be read. Please try again.');
                                               return;
                                           }
                                           setFile(file)
                                       }}
                                       pdsFileUploadRemove={() => {
                                           setFile(null as any as File);
                                       }}
                                       disabled={disableUpload}/>
                        <PdsSidebar>
                            <div>
                                <PdsTextInput
                                    id="file-description"
                                    name="fileDescription"
                                    label="File description"
                                    value={fileDescription}
                                    onChange={(event) => {
                                        setFileDescription((event.target as HTMLInputElement)?.value)
                                    }}
                                />
                            </div>
                            <div slot="right-sidebar" className='pds-u-flex-align-end'>
                                <PdsButton type="button"
                                           disabled={disableUpload}
                                           onClick={async (e) => {
                                               e.preventDefault();
                                               if (!file) {
                                                   return;
                                               }
                                               try {
                                                   const fileName = file.name;
                                                   await uploadClaimDocument({
                                                       variables: {
                                                           uploadDocumentInput: {
                                                               claimId,
                                                               name: fileDescription ? `${fileDescription}.${findExtensionFor(fileName)}` : fileName,
                                                               type: 'CLAIMS'
                                                           },
                                                           file: await convertToBase64(file)
                                                       },
                                                       update: (cache, { data: { uploadClaimDocument } }) => {
                                                           cache.updateQuery({ query: FIND_CLAIMS_QUERY, variables }, (data) => ({
                                                               findClaims: data.findClaims.map((c: DisabilityClaim) => {
                                                                   if (c.claimId === claimId) {
                                                                       return {
                                                                           ...c,
                                                                           documents: [...c.documents, uploadClaimDocument]
                                                                       }
                                                                   }
                                                                   return c
                                                               })
                                                           }));
                                                       }
                                                   })
                                                   fileUploadRef.current.uploadedFilesArray = [];
                                                   setFileDescription("")
                                                   setFile(null as any as File)
                                               } catch (e) {
                                                   console.log('upload error', e)
                                               }
                                           }}>
                                    Upload
                                </PdsButton>
                            </div>
                        </PdsSidebar>
                    </div>
                    {uploadData && !uploadLoading && !uploadError && <div>Document has been uploaded.</div>}
                </PdsGridItem>
            </PdsGrid>
        </>
        }

        function buildDocumentLinks() {
                                          if (claimsLoading) {
                                          return <LoadingSpinner/>
                                      } else if(claimsError) {
                                          console.log('claims error', claimsError)
                                          return <p>Could not load claim information, please try again later.</p>;
                                      } else if(!claim) {
                                          return <p>Claim not found.</p>
                                      }
                                          return claim.documents
                                          .sort((d1: Document, d2: Document) => d2.creationDate.toMillis() - d1.creationDate.toMillis())
                                          .map((d: Document) =>
                                          <div>
                                              <PdsButton link="default"
                                                         type="button"
                                                         removeLinkPadding={true}
                                                         onClick={async () => {
                                                             const claimDocumentResponse = await findClaimDocument({
                                                                 variables: {
                                                                     claimId,
                                                                     sharepointItemId: d.sharepointItemId
                                                                 }
                                                             });
                                                             const claimDocument = claimDocumentResponse.data.findClaimDocument;
                                                             fileDownload(Buffer.from(claimDocument.content, 'base64'), claimDocument.name)
                                                         }}>
        {`${d.name} (${d.creationDate.toLocaleString(DateTime.DATE_SHORT)})`}
        </PdsButton>
    </div>)
    }

    function buildClaimAnalyst() {
        const assistance = `For assistance with your claim, please call ${phoneNumber} or e-mail SBDClaims@principal.com.`;
        if(claim && !isInNewClaimSystem(claim.sourceSystem)) {
            return assistance;
        } else if(analystLoading) {
            return <LoadingSpinner/>
        } else if(analystError) {
            console.log('analyst error', analystError)
            return 'Could not load claim analyst information, please try again later.';
        }
        const analyst = analystData.findClaimAnalyst
        if(!analyst) {
            return `This claim has not yet been assigned to an analyst. ${assistance}`
        }
        return <div>
            <div>{analyst.firstName}</div>
            <div>800-245-1522 x{analyst.phoneNumber?.replace(/\D/gi, '').slice(-5)} (Español: 800-243-1404)</div>
            <div>{analyst.emailAddress}</div>
        </div>;
    }

    function buildPayments() {
        return <>
            <PdsHeading headingTag="h2">Payment summary</PdsHeading>
            {buildPaymentTable()}
        </>
    }

    function buildPaymentTable() {
        if(claimsLoading) {
            return <LoadingSpinner/>
        } else if(claimsError) {
            console.log('claims error', claimsError)
            return <p>Could not load claim information, please try again later.</p>;
        } else if(!claim) {
            return <p>Claim not found.</p>
        }
        const headers = ['Date paid', 'Payment amount', 'View EOB', 'Benefits']
        const tableProps = {
            title: "Payments",
            headers,
            rows: claim.payments
                .sort((p1: Payment, p2: Payment) => p2.effectiveDate.toMillis() - p1.effectiveDate.toMillis())
                .map((p: Payment) => buildPaymentRow(p))
        }
        return claim.payments?.length ? <DataTable {...tableProps} /> : 'No payments found.'
    }

    function buildPaymentRow(payment: Payment) {
        return [payment.effectiveDate.toLocaleString(DateTime.DATE_SHORT),
            payment.amount.format(),
            <PdsButton type="button" onClick={async () => {
                const explanationOfBenefits = await findExplanationOfBenefits(
                    { variables: { claimId, coverage: findKeyByValue(Coverage, coverage), paymentId: payment.paymentId } });
                const explanationOfBenefitsContent = explanationOfBenefits.data.findExplanationOfBenefits.content
                fileDownload(Buffer.from(explanationOfBenefitsContent, 'base64'), 'ExplanationOfBenefits.pdf')
            }} disabled={!payment.explanationOfBenefitsDocumentInstanceId}>Download</PdsButton>,
            <PdsButton type="button" onClick={() => {
                setPaymentModalContents(<FlatTable headers={[{ header: 'Benefit' }, { header: 'Amount' }]}
                                                   rows={payment.benefits.map(b => [b.benefit, `$${b.amount}`])}/>);
                paymentModalRef.current.openModal()
            }} disabled={!payment.benefits?.length}>
                View benefits
            </PdsButton>
        ]
    }

    function findExtensionFor(filename: string): string | undefined {
        return filename.split('.').pop();
    }

    function convertToBase64(blob: Blob) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob)
            reader.onerror = error => console.log('error reading file: ', error);
        })
    }
};

export default ClaimDetail;
