import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsLink } from '../link/link';
import styles from './global-header-link-item.scss?inline';

/**
 * @summary This component renders a styled anchor tag for the global header. For use within the global header only.
 *
 * @slot **default** A slot for the main content
 * @slot **icon-left** This slot holds an icon to the left of the link. Icons are restricted to the PDS icon sets only
 * @slot **icon-right** This slot holds an icon to the right of the link. Icons are restricted to the PDS icon sets only
 *
 * @fires pds-global-header-link-item-click A custom event dispatched on link click
 */
@customElement('pds-global-header-link-item', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderLinkItem extends PdsLink {
  /**
   * This indicates if the user is currently on the page that the link points to
   */
  @property({ type: Boolean })
  current: boolean = false;

  @property({ type: String })
  variant: 'default';

  @property({ type: String })
  size: 'default';

  @property({ type: Boolean })
  download: false;

  @property({ type: String })
  ariaCurrent: 'page' | 'true';

  @property({ type: String })
  role: 'link';

  @property({ type: String })
  // @ts-expect-error Needed to override the PdsLink prop so users don't set this prop on the global header link element
  type: null;

  @property({ type: String })
  button: '';

  @property({ type: Boolean })
  hover: false;

  firstUpdated(): void {
    // Catch the pds-link-click event on the link and dispatch a new event
    this.addEventListener('pds-link-click', (e: Event) => {
      e.stopPropagation();
      const customEvent = e as CustomEvent;

      this.dispatchEvent(
        new CustomEvent('pds-global-header-link-item-click', {
          bubbles: true,
          composed: true,
          detail: customEvent.detail,
        }),
      );
    });
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      // @ts-ignore Lit is fine with this, but TS throws an error
      ...super.classNames,
      current: !!this.current,
    };
  }
}
