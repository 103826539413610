import { QueryHookOptions, QueryResult } from "@apollo/client/react/types/types";
import { OperationVariables, useQuery } from "@apollo/client";
import { useCoverage } from "./useCoverage";
import { DocumentNode, TypedDocumentNode } from "@apollo/client/core";
import { findBenefitKeyFor } from "../service/BenefitKeyService";

export function useBenefitKeyQuery(query: DocumentNode | TypedDocumentNode<any, any>, options?: QueryHookOptions<any, any>): QueryResult {
    const coverage = useCoverage();

    return useQuery(query, {
        ...(options || {}),
        variables: {
            benefitKey: findBenefitKeyFor(coverage),
            ...(options?.variables || {})
        } as OperationVariables
    });
}