import * as React from 'react';
import { PdsTable } from '../table/table';
import { PdsSkeletonLoader } from '../skeleton-loader/skeleton-loader';

export const LoadingTable = ({
  columns,
  rows,
}: {
  columns: number;
  rows: number;
}) => {
  return (
    <div className="pds-c-data-table--rendered">
      <PdsTable>
        <table className="pds-c-table">
          <thead>
            <tr>
              {[...Array(columns)].map((header, index) => (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <th key={index}>
                  <PdsSkeletonLoader
                    variant="heading"
                    className="pds-data-table__heading-loader"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(rows)].map((row, index) => (
              <tr key={index}>
                {[...Array(columns)].map((cell, cellIndex) => (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <td key={cellIndex}>
                    <PdsSkeletonLoader className="pds-data-table__body-loader" />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </PdsTable>
    </div>
  );
};
