export enum Path {
    COVERAGE = '/:coverage',
    OVERVIEW = '',
    CLAIMS = 'claims',
    CLAIM_DETAIL = 'claims/:claimId',
    RESOURCES = 'resources',
    FORMS = 'forms',
    LOGIN = '/login',
    LOGIN_CALLBACK = '/login-callback',
    UNAUTHORIZED = '/unauthorized',
    WILDCARD = '*'
}