import * as React from 'react';
import { EventName } from '@lit/react';
import { PdsGlobalHeader as PdsGlobalHeaderElement } from '@principal/design-system';
import { PdsGlobalHeaderProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsGlobalHeader = createPdsReactComponent<PdsGlobalHeaderProps>()({
  tagName: 'pds-global-header',
  elementClass: PdsGlobalHeaderElement,
  react: React,
  events: {
    onHandleClick: 'pds-global-header-click' as EventName<CustomEvent>,
  },
});
