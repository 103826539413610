import { PdsFooter } from "@principal/design-system-react"
import { PdsLayoutContainer } from "@principal/design-system-react";
import React from "react";

const Footer = () => (
    <>
        <PdsLayoutContainer>
            <small>
                Insurance products issued by Principal Life Insurance Company®, a member of the Principal Financial Group®, Des Moines, IA 50392.
            </small>
            <br/>
            <small>
                The information provided via this site is not a (1) guarantee of payment, (2) verification of benefits or coverage or (3) authorization or pre-authorization to perform services. Claims paid and not yet paid may affect remaining amounts payable. If there is a discrepancy between the policy and this information, the actual policy provision prevails. This information does not contain all of the qualifications and limitations that apply to your plan or policy. Please refer to your benefit booklet-certificate for more complete benefit information. Your benefit booklet-certificate (and any applicable riders) will further explain the provisions of your coverage as well as exclusions, restrictions, and limitations. To obtain information regarding benefits for a specific procedure or service, please contact us.
            </small>
        </PdsLayoutContainer>
        <PdsFooter />
    </>
)
export default Footer;
