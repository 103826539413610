import { LongTermDisability, ShortTermDisability } from "benefit-service-types";
import { DateTime, DurationLike } from "luxon";
import { Cadence } from "benefit-service-types/lib/app/src/domain/enums/Cadence";

export function findPreExistingReviewStartDateFor(product: ShortTermDisability | LongTermDisability,
                                                  benefitEffectiveDate: DateTime, disabilityDate: DateTime,
                                                  lastTreatmentDate: DateTime): DateTime | null {
    if(doesNotHavePreExisting(product, benefitEffectiveDate, disabilityDate, lastTreatmentDate)) {
        return null;
    }
    const preExistingConditions = product.preExistingConditions;
    return benefitEffectiveDate
        .minus(findDurationLikeFor(preExistingConditions.beforeEffectiveDatePeriod, preExistingConditions.beforeEffectiveDateCadence))
}

export function findPreExistingReviewEndDateFor(product: ShortTermDisability | LongTermDisability,
                                                benefitEffectiveDate: DateTime, disabilityDate: DateTime,
                                                lastTreatmentDate: DateTime): DateTime | null {
    if(doesNotHavePreExisting(product, benefitEffectiveDate, disabilityDate, lastTreatmentDate)) {
        return null;
    }
    return benefitEffectiveDate.minus({days: 1});
}

export function findTreatmentFreeStartDateFor(product: ShortTermDisability | LongTermDisability, lastTreatmentDate: DateTime): DateTime | null {
    const preExistingConditions = product.preExistingConditions
    if(!preExistingConditions?.included || !preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod || !preExistingConditions.afterEffectiveDateWithoutTreatmentCadence) {
        return null;
    }
    return lastTreatmentDate
        .minus(findDurationLikeFor(preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod, preExistingConditions.afterEffectiveDateWithoutTreatmentCadence));
}

function doesNotHavePreExisting(product: ShortTermDisability | LongTermDisability,
                                benefitEffectiveDate: DateTime, disabilityDate: DateTime, lastTreatmentDate: DateTime) {
    const preExistingConditions = product.preExistingConditions
    if(!preExistingConditions?.included) {
        return true;
    }
    const disabilityBeginsWithinDate = benefitEffectiveDate
        .plus(findDurationLikeFor(preExistingConditions.disabilityBeginsWithinPeriod, preExistingConditions.disabilityBeginsWithinCadence))
        .plus({days: 1});
    if(disabilityDate > disabilityBeginsWithinDate) {
        return true;
    }
    if(!preExistingConditions.afterEffectiveDateWithoutTreatmentCadence || !preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod) {
        return false;
    }
    const treatmentFreeStartDate = lastTreatmentDate
        .minus(findDurationLikeFor(preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod, preExistingConditions.afterEffectiveDateWithoutTreatmentCadence))
    return treatmentFreeStartDate >= benefitEffectiveDate;
}

function findDurationLikeFor(period: number, cadence: Cadence): DurationLike {
    return {
        [cadence.toLowerCase()]: period
    }
}
