import { HospitalIndemnityPlanType } from "benefit-service-types/lib/app/src/domain/enums/HospitalIndemnity/HospitalIndemnityPlanType";

export enum Condition {
    SICKNESS,
    INJURY,
    SICKNESS_AND_INJURY
}

const conditionDescriptionByCondition = new Map([
    [Condition.SICKNESS, 'Sickness'],
    [Condition.INJURY, 'Injury'],
    [Condition.SICKNESS_AND_INJURY, 'Sickness/Injury']
])

const conditionByPlanType = new Map([
    [HospitalIndemnityPlanType.SICKNESS_ONLY, Condition.SICKNESS],
    [HospitalIndemnityPlanType.INJURY_ONLY, Condition.INJURY],
    [HospitalIndemnityPlanType.SICKNESS_AND_INJURY, Condition.SICKNESS_AND_INJURY]
])

export namespace Condition {
    export function findConditionDescriptionFor(condition: Condition) {
        return conditionDescriptionByCondition.get(condition)
    }

    export function findConditionFromPlanTypeFor(planType: HospitalIndemnityPlanType): Condition {
        return conditionByPlanType.get(planType) as Condition
    }
}