import { QueryResult } from "@apollo/client/react/types/types";
import { useBenefitKeyQuery } from "./useBenefitKeyQuery";
import { FIND_BENEFIT_DOCUMENT_QUERY } from "../atoms/Queries";
import { BenefitDocumentType } from "../enums/BenefitDocumentType";
import {useCoverage} from "./useCoverage";
import {findKeyByValue} from "../enums/FindKeyByValue";
import {Coverage} from "../enums/Coverage";

export function useBenefitDocument(documentType: BenefitDocumentType): QueryResult {
    const coverage = useCoverage()
    const queryResult = useBenefitKeyQuery(FIND_BENEFIT_DOCUMENT_QUERY,
        { variables: { coverage: findKeyByValue(Coverage, coverage), documentType } });
    const findBenefitDocument = queryResult.data?.findBenefitDocument;
    if(findBenefitDocument) {
        queryResult.data = findBenefitDocument
    }
    return queryResult;
}
