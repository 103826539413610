import { snakeCaseToCapitalized } from "./FindKeyByValue";

export enum EvidenceOfInsurabilityStatus {
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
    CLOSED_OUT = "CLOSED_OUT",
    EOI_REQUESTED = "EOI_REQUESTED",
    INFO_REQUESTED_EMPLOYEE = "INFO_REQUESTED_EMPLOYEE",
    PENDING = "PENDING",
    PENDING_PRINCIPAL = "PENDING_PRINCIPAL",
    INFO_REQUESTED_PHYSICIAN = "INFO_REQUESTED_PHYSICIAN",
    UNKNOWN = "UNKNOWN",
}

const definitionsByEvidenceOfInsurabilityStatus = new Map([
    [EvidenceOfInsurabilityStatus.APPROVED, 'Your coverage has been approved.'],
    [EvidenceOfInsurabilityStatus.DECLINED, 'Declination details have been sent to you.'],
    [EvidenceOfInsurabilityStatus.CLOSED_OUT, 'Part of the application process is completion of an Evidence of Insurability (EOI) questionnaire that was emailed to you in order to assess proof of applicant health. Since we have not received the requested EOI within 30 days, we\'ve closed out this request assuming you are no longer interested.'],
    [EvidenceOfInsurabilityStatus.EOI_REQUESTED, 'An EOI is needed for the coverage you requested.'],
    [EvidenceOfInsurabilityStatus.INFO_REQUESTED_EMPLOYEE, 'We\'re currently reviewing the Evidence of Insurability submitted. We have requested additional information from you before we\'re able to make a decision.'],
    [EvidenceOfInsurabilityStatus.PENDING, 'To complete our review of your request, we need a little more information. Please provide your proof of health by completing your Evidence of Insurability (EOI) online through the "Complete your EOI online" link below.'],
    [EvidenceOfInsurabilityStatus.PENDING_PRINCIPAL, 'Your request for coverage shown as pending is being reviewed. Additional medical information may be requested. You\'ll receive a status update soon.'],
    [EvidenceOfInsurabilityStatus.INFO_REQUESTED_PHYSICIAN, 'We\'ve reviewed your Evidence of Insurability and have requested additional information from your medical provider.'],
    [EvidenceOfInsurabilityStatus.UNKNOWN, '']
]);

export namespace EvidenceOfInsurabilityStatus {
    export function findStatusIndicatorColorFor(evidenceOfInsurabilityStatus: EvidenceOfInsurabilityStatus): "default" | "warning" | "info" | "negative" | "neutral" {
        if(EvidenceOfInsurabilityStatus.DECLINED === evidenceOfInsurabilityStatus) {
            return 'negative';
        } else if(EvidenceOfInsurabilityStatus.CLOSED_OUT === evidenceOfInsurabilityStatus) {
            return 'neutral';
        } else if([EvidenceOfInsurabilityStatus.EOI_REQUESTED, EvidenceOfInsurabilityStatus.INFO_REQUESTED_EMPLOYEE].includes(evidenceOfInsurabilityStatus)) {
            return 'warning';
        } else if(EvidenceOfInsurabilityStatus.APPROVED === evidenceOfInsurabilityStatus) {
            return 'default';
        }
        return 'info';
    }

    export function findDefinitionFor(evidenceOfInsurabilityStatus: EvidenceOfInsurabilityStatus): string {
        return definitionsByEvidenceOfInsurabilityStatus.get(evidenceOfInsurabilityStatus) as string;
    }

    export function isPendingEmployee(evidenceOfInsurabilityStatus: EvidenceOfInsurabilityStatus): boolean {
        return [EvidenceOfInsurabilityStatus.EOI_REQUESTED, EvidenceOfInsurabilityStatus.INFO_REQUESTED_EMPLOYEE, EvidenceOfInsurabilityStatus.PENDING,
            EvidenceOfInsurabilityStatus.UNKNOWN].includes(evidenceOfInsurabilityStatus);
    }

    export function findStatusFor(evidenceOfInsurabilityStatus: EvidenceOfInsurabilityStatus): string {
        if (EvidenceOfInsurabilityStatus.EOI_REQUESTED === evidenceOfInsurabilityStatus) {
            return 'EOI requested';
        } else if (EvidenceOfInsurabilityStatus.INFO_REQUESTED_EMPLOYEE === evidenceOfInsurabilityStatus) {
            return 'Information requested from you';
        } else if (EvidenceOfInsurabilityStatus.PENDING_PRINCIPAL === evidenceOfInsurabilityStatus) {
            return 'Pending Principal';
        } else if (EvidenceOfInsurabilityStatus.INFO_REQUESTED_PHYSICIAN === evidenceOfInsurabilityStatus) {
            return 'Information requested from your physician';
        }
        return snakeCaseToCapitalized(evidenceOfInsurabilityStatus);
    }
}
