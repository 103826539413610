import { html, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './user-avatar.scss?inline';

/**
 * @summary User avatar with indicator for application notifications.  For use within the global header only
 *
 * @slot media Replaces the default svg with a user's image, restricted to svg, img
 */
@customElement('pds-user-avatar', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsUserAvatar extends PdsElement {
  /**
   * Flag to indicate if the user has notifications in the consuming application
   */
  @property()
  hasNotifications: boolean = false;

  /**
   * User initials for user avatar. Truncated if the consumer passes in more than 2 initials
   */
  @property()
  initials?: string;

  /**
   * Tracks to see if the media slot is actually empty or not to render the initials
   * @internal
   */
  @property()
  mediaSlotIsEmpty: boolean = true;

  /**
   * The media slot element
   */
  @query('slot[name="media"]')
  mediaSlot: HTMLSlotElement;

  updated() {
    // Takes the slot inside the media slot and checks if it is an image or svg
    if (this.mediaSlot && this.mediaSlot.assignedElements().length > 0) {
      const media = this.mediaSlot.assignedElements()[0] as HTMLSlotElement;
      if (media.tagName === 'SLOT') {
        const slot = media.assignedElements()[0] as HTMLSlotElement;
        if (slot && (slot.tagName === 'IMG' || slot.tagName === 'SVG')) {
          this.mediaSlotIsEmpty = false;
        }
      }
    }
  }

  render() {
    if (!this.initials && this.slotEmpty('media')) {
      console.error(
        'UserAvatar requires either user initials or an image in the media slot to render the component',
      );
      return nothing;
    }

    if (this.initials && this.initials.length !== 2) {
      console.warn('Initials in user-avatar should contain two characters');
      this.initials =
        this.initials.length > 2
          ? this.initials.substring(0, 2)
          : this.initials;
    }

    return html`<span class=${this.getClass()}>
      <span class="${this.classEl('media')}">
        ${this.initials && (this.slotEmpty('media') || this.mediaSlotIsEmpty)
          ? html`<span>${this.initials.toUpperCase()}</span>`
          : nothing}
        <slot
          name="media"
          allowed-elements="img, svg, slot, span"
          @slotchange=${this.handleSlotValidation}
        ></slot>
      </span>
      ${this.hasNotifications
        ? html`<span class="${this.classEl('notification-identifier')}"></span>`
        : nothing}
    </span>`;
  }
}
