import * as React from 'react';
import { PdsIconFileX as PdsIconFileXElement } from '@principal/design-system-icons-web';
import { IconSize, ValidIconColor } from '../../../utils/icon-utilities';
import { createPdsReactComponent } from '../../../../../react/src/lib/create-pds-react-component';

interface IconProps {
  size?: IconSize;
  color?: ValidIconColor;
}

export const PdsIconFileX = createPdsReactComponent<IconProps>()({
  tagName: 'pds-icon-file-x',
  elementClass: PdsIconFileXElement,
  react: React,
});
