import {
    PdsDataTable,
    PdsDataTableColumn,
    PdsDataTableColumns,
} from '@principal/design-system-react';
import React, { useEffect, useRef } from 'react';

export type DataTableProps = {
    title?: string,
    headers: string[]
    rows: any[][]
} & Omit<React.ComponentProps<typeof PdsDataTable>, "headers">

const DataTable = (props: DataTableProps) => {
    const tableRef = useRef(null) as React.RefObject<any>

    useEffect(() => {
        const tableInterval = setInterval(() => {
            const table = tableRef.current?.querySelector('table');
            if(table) {
                table.setAttribute('style', 'background-color: white')
                clearInterval(tableInterval)
            }
        }, 10)
        return () => clearInterval(tableInterval)
    }, [tableRef])

    return (
        <PdsDataTable data={props.rows} ref={tableRef}>
            <PdsDataTableColumns slot="columns">
                {props.headers.map((header, index) => {
                    return (
                        <PdsDataTableColumn columnId={index.toString()}>
                            <div>{header}</div>
                        </PdsDataTableColumn>);
                })}
            </PdsDataTableColumns>
        </PdsDataTable>)
};
export default DataTable;