import { LongTermDisability, OccupationPeriod, Product } from "benefit-service-types";
import { DateTime } from "luxon";
import {findValueByKey} from "../enums/FindKeyByValue";

export function findOwnOccupationEndDateFor(product: Product,
                                            benefitStartDate: DateTime,
                                            benefitEndDate: DateTime): DateTime | null {
    let ownOccupationPeriod = (product as LongTermDisability).ownOccupationPeriod
    if(!ownOccupationPeriod) {
        return null;
    }
    ownOccupationPeriod = findValueByKey(OccupationPeriod, ownOccupationPeriod);
    if(ownOccupationPeriod === OccupationPeriod.None) {
        return benefitStartDate
    } else if(ownOccupationPeriod === OccupationPeriod.EndOfBenefitDuration) {
        return benefitEndDate
    }
    let years = 0;
    if(ownOccupationPeriod === OccupationPeriod.OneYear) {
        years = 1
    } else if(ownOccupationPeriod === OccupationPeriod.TwoYear) {
        years = 2
    } else if(ownOccupationPeriod === OccupationPeriod.ThreeYear) {
        years = 3
    } else if(ownOccupationPeriod === OccupationPeriod.FiveYear) {
        years = 5
    } else if(ownOccupationPeriod === OccupationPeriod.TenYear) {
        years = 10
    }
    return benefitStartDate.plus({ years });
}