import { Coverage } from "../enums/Coverage";
import { BenefitOption } from "./BenefitOption";
import { State } from "../enums/State";
import { BenefitStatus } from "../enums/BenefitStatus";
import { DateTime } from "luxon";
import { EmployeeAssistanceProgramProvision } from "../enums/EmployeeAssistanceProgramProvision";
import { EvidenceOfInsurabilityStatus } from "../enums/EvidenceOfInsurabilityStatus";

export interface Benefit {
    coverage: Coverage,
    approvedAmount: number,
    pendingAmount: number,
    accidentalDeathAndDismembermentApprovedAmount?: number,
    accidentalDeathAndDismembermentPendingAmount?: number,
    contractState: State,
    status: BenefitStatus,
    effectiveDate: string,
    terminationDate: string,
    employeeAssistanceProgramProvision: EmployeeAssistanceProgramProvision,
    benefitOptions: BenefitOption[],
    evidenceOfInsurabilityStatus?: EvidenceOfInsurabilityStatus,
    evidenceOfInsurabilityUrl?: string
}

export namespace Benefit {
    export function isTerminated(benefit: Benefit): boolean {
        return (DateTime.fromISO(benefit.terminationDate) <= DateTime.now()
            && benefit.status === BenefitStatus.TERMINATED);
    }
}