import { AuthContextProps, useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { Path } from "../enums/Path";
import { useEffect } from "react";
import AuthLoader from "./AuthLoader";
import { LocalStorageKey } from "../enums/LocalStorageKey";

const LoginCallback = () => {
    const auth: AuthContextProps = useAuth();
    const navigate = useNavigate();

    const loading = auth.isLoading;
    const authenticated = auth.isAuthenticated;
    useEffect(() => {
        if(loading) {
            return;
        } else if(!authenticated) {
            navigate(Path.UNAUTHORIZED, { replace: true })
        } else {
            navigate(window.localStorage.getItem(LocalStorageKey.PREVIOUS_LOCATION)!, { replace: true })
        }
    }, [loading, authenticated, navigate])

    return <AuthLoader/>
}
export default LoginCallback;