import _ from "lodash";

export function findKeyByValue(enumType: any, value: any): string {
    const valueIndex = Object.values(enumType).indexOf(value);
    return Object.keys(enumType)[valueIndex];
}

export function findMapKeyByValue<K,V>(map: Map<K,V>, searchValue: V): K | undefined {
    for (let [key, value] of map.entries()) {
        if (searchValue === value)
            return key as K;
    }
}

export function findValueByKey(enumType: any, key: any): any {
    const keyIndex = Object.keys(enumType).indexOf(key);
    return Object.values(enumType)[keyIndex];
}

export function snakeCaseToCapitalized(value: string): string {
    return _.capitalize(value?.replace(/_/g, ' '))
}

export function snakeCaseToTitleCase(value: string): string {
    return _.startCase(_.toLower(value?.replace(/_/g, ' ')))
}

export function snakeCaseToLowerCase(value: string): string {
    return _.toLower(value?.replace(/_/g, ' '))
}