import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Path } from "../enums/Path";
import AuthLoader from "./AuthLoader";
import { LocalStorageKey } from "../enums/LocalStorageKey";

export const ProtectedRoute = () => {
    const auth = useAuth();
    const location = useLocation();

    if(auth.isLoading) {
        return <AuthLoader/>
    } else if(!auth.isAuthenticated) {
        window.localStorage.setItem(LocalStorageKey.PREVIOUS_LOCATION, location.pathname + location.search);
        return <Navigate to={Path.LOGIN} replace={true}/>;
    }
    return <Outlet />;
};