import * as React from 'react';
import { PdsUserAvatar as PdsUserAvatarElement } from '@principal/design-system';
import { PdsUserAvatarProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsUserAvatar = createPdsReactComponent<PdsUserAvatarProps>()({
  tagName: 'pds-user-avatar',
  elementClass: PdsUserAvatarElement,
  react: React,
});
