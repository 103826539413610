import * as React from 'react';
import { EventName } from '@lit/react';
import { PdsGlobalHeaderMobileTray as PdsGlobalHeaderMobileTrayElement } from '@principal/design-system';
import { PdsGlobalHeaderMobileTrayProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsGlobalHeaderMobileTray =
  createPdsReactComponent<PdsGlobalHeaderMobileTrayProps>()({
    tagName: 'pds-global-header-mobile-tray',
    elementClass: PdsGlobalHeaderMobileTrayElement,
    react: React,
    events: {
      onMobileTrayClose:
        'pds-global-header-mobile-tray-close' as EventName<CustomEvent>,
    },
  });
