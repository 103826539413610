import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './number-badge.scss?inline';

/**
 * @summary A component that renders a number badge
 */
@customElement('pds-number-badge', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsNumberBadge extends PdsElement {
  /**
   * The number to be displayed. Numbers greater than 99 will show 99+.
   */
  @property({ type: Number })
  count: number = 0;

  /**
   * @internal
   */
  get classNames() {
    return {};
  }

  render() {
    return this.count < 100
      ? html`<span class=${this.getClass()}>${this.count}</span>`
      : html`<span class=${this.getClass()}>99+</span>`;
  }
}
