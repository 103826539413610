export default {
  en: {
    translation: {
      ascending: 'ascending',
      descending: 'descending',
      'data-table-live-region-text':
        '{{caption}} table, column {{headerName}} is now sorted by {{sortDirection}} values',
      'column-headers-buttons-sortable':
        'column headers with buttons are sortable',
      'video-duration-is': 'Video duration is',
      'minutes-and': 'minutes and',
      'seconds-long': 'seconds long',
      'view-video-transcript': 'View video transcript',
      close: 'close',
      breadcrumbs: 'breadcrumbs',
      'escape-exit-calendar': 'Press escape to exit the calendar.',
      'previous-year': 'Previous year',
      'next-year': 'Next year',
      'previous-month': 'Previous month',
      'next-month': 'Next month',
      'show-today': 'Show today',
      'clear-value': 'Clear value',
      'choose-date': 'Choose date',
      'please-follow-format': `Please follow the {{format}} format.`,
      'please-enter-valid-date': 'Please enter a valid date.',
      'press-down-for-calendar':
        'Press the down arrow key to access the calendar.',
      error: 'ERROR',
      'please-refresh-browser':
        'Please refresh your browser or try again in a few minutes.',
      'sorry-no-permission':
        'Sorry! It looks like you don’t have permission to access this page.',
      'sorry-broken-link':
        'We’re sorry. It looks like the link you followed may be broken or the page may have been moved.',
      'sorry-site-unavailable':
        'Sorry! This part of our site is currently unavailable, we’re working on resolving the issue as quickly as possible.',
      'well-thats-puzzling':
        'Well, that’s <span class="{{spanClass}}">puzzling.</span>',
      'well-shoot':
        '<span class="{{spanClass}}">Well, shoot.</span> Our system doesn’t recognize you.',
      'uh-oh-small-glitch':
        '<span class="{{spanClass}}">Uh-oh.</span> We’re experiencing a small glitch.',
      'bump-in-the-road':
        'Looks like there’s a <span class="{{spanClass}}">bump</span> in the road.',
      'attach-files': 'Attach files',
      'attach-file': 'Attach file',
      'drop-file-to-attach':
        'Drop a file to attach, or <button class={{buttonClass}}>browse.</button>',
      'drop-files-to-attach':
        'Drop files to attach, or <button class={{buttonClass}}>browse.</button>',
      'browse-files': 'Browse files',
      cancel: 'Cancel',
      'replace-file-confirmation':
        'Are you sure you want to replace this file:',
      with: 'With',
      replace: 'Replace',
      'no-files-attached': 'No files have been attached.',
      'please-ensure-file-formats':
        'Please make sure your files are in one of the following formats',
      'please-ensure-file-size': 'Please make sure your files are below',
      'file-could-not-be-uploaded': 'The following file could not be uploaded',
      'try-again': 'Try again',
      'remove-attachment': 'Remove attachment',
      'show-more': 'Show more',
      'show-less': 'Show less',
      loading: 'loading...',
      'expand-collapse-row': 'Expand/collapse row',
      'trouble-logging-in': 'Trouble logging in?',
      'get-help': 'Get help',
      'link-to-principal-homepage': 'Link to Principal homepage',
      contact: 'Contact',
      footer: 'Footer',
      'terms-of-use': 'Terms of use',
      disclosures: 'Disclosures',
      privacy: 'Privacy',
      security: 'Security',
      'report-fraud': 'Report fraud',
      'securities-offered':
        'Securities offered through Principal Securities, Inc.,',
      'member-sipc': 'member SIPC',
      'principal-on-twitter': 'Principal on Twitter',
      'principal-on-youtube': 'Principal on YouTube',
      'principal-on-facebook': 'Principal on Facebook',
      'principal-on-linkedin': 'Principal on LinkedIn',
      'back-to-content': 'Back to content',
      'mobile-navigation-menu': 'Mobile navigation menu',
      'hang-on-a-moment': 'Hang on a moment...',
      'few-more-seconds': 'Just a few more seconds!',
      'session-expired': 'Your session has expired.',
      'logged-out': "You've logged out.",
      'we-logged-you-out': "To keep your account secure, we've logged you out.",
      'not-what-you-wanted': 'Not what you wanted to do?',
      'log-back-in': 'Log back in',
      'go-to-first-page': 'go to first page',
      'go-to-previous-page': 'go to previous page',
      'go-to-next-page': 'go to next page',
      'go-to-last-page': 'go to last page',
      pagination: 'pagination',
      'go-to-page': 'go to page',
      'log-out': 'Log out',
      'log-in': 'Log in',
      'skip-to-content': 'Skip to content',
      search: 'search',
      notifications: 'notifications',
      messages: 'Messages',
      'other-alerts': 'Other alerts',
      menu: 'Menu',
      'secondary-navigation': 'secondary navigation',
      'logout-alert': 'Logout alert!',
      'error-displaying-table-data': 'Error displaying table data',
      minutes: 'minutes',
      'stay-logged-in': 'Stay logged in',
      seconds: 'seconds',
      'one-moment': 'One moment',
      'dismiss-the-timer': 'dismiss the timer',
      'to-save-your-work': 'to save your work before you get logged out.',
      'sorry-something-wrong-session':
        'Sorry! Something went wrong on our end when trying to extend your session. Give it another try, or ',
      'still-there': "Still there? You're about to be logged out.",
      'auto-log-you-out-warning':
        "To keep your account secure, we'll automatically log you out in ",
      dismiss: 'Dismiss',
      minute: 'minute',
      'time-remaining': '{{time}} remaining',
      progress: 'progress',
      completed: 'completed',
      incomplete: 'incomplete',
      'filter-table': 'Filter table',
      'no-available-table-data': 'No available table data',
      show: 'Show',
      'no-entries': 'No entries',
      'number-rows-displayed': 'Number of rows to display in table',
      of: 'of',
      entries: 'entries',
      entry: 'entry',
      'contact-us': 'Contact us',
      'help-topics': 'Help topics',
      'call-us-at': 'Call us at',
      'principal-legal-links': 'Principal legal links',
      'social-media': 'Social media',
      'utility-menu': 'utility menu',
      'user-menu': 'user menu',
      'dropdown-button': 'dropdown-button',
    },
  },
  es: {
    translation: {
      ascending: 'ascendentes',
      descending: 'descendentes',
      'data-table-live-region-text':
        '{{caption}} tabla, la columna {{headerName}} está ordenada por valores {{sortDirection}}',
      'column-headers-buttons-sortable':
        'los encabezados de columna con botones se pueden ordenar',
      'video-duration-is': 'La duración del vídeo es de',
      'minutes-and': 'minutos y',
      'seconds-long': 'segundos',
      'view-video-transcript': 'Ver la transcripción del video',
      close: 'cerrar',
      breadcrumbs: 'ayuda a la navegación',
      'escape-exit-calendar':
        'Para salir del calendario pulsa la tecla escape.',
      'previous-year': 'Año anterior',
      'next-year': 'Año siguiente',
      'previous-month': 'Mes anterior',
      'next-month': 'Mes siguiente',
      'show-today': 'Mostrar la fecha de hoy',
      'clear-value': 'Borrar la fecha',
      'choose-date': 'Elegir fecha',
      'please-follow-format': 'Utiliza el formato {{format}}, por favor.',
      'please-enter-valid-date': 'Por favor, ingresa una fecha válida.',
      'press-down-for-calendar':
        'Para acceder al calendario pulsa la flecha hacia abajo.',
      error: 'ERROR',
      'please-refresh-browser':
        'Actualice su navegador o inténtelo de nuevo en unos minutos.',
      'sorry-no-permission':
        '¡Arrepentido! Parece que no tienes permiso para acceder a esta página.',
      'sorry-broken-link':
        'Lo sentimos. Parece que el enlace que siguió puede estar roto o la página puede haber sido movida.',
      'sorry-site-unavailable':
        'Arrepentido! Esta parte de nuestro sitio no está disponible actualmente, estamos trabajando para resolver el problema lo más rápido posible.',
      'well-thats-puzzling':
        'Bueno, eso es <span class="{{spanClass}}">desconcertante.</span>',
      'well-shoot':
        '<span class="{{spanClass}}">Bueno, dispara.</span> Nuestro sistema no te reconoce.',
      'uh-oh-small-glitch':
        '<span class="{{spanClass}}">Uh-oh.</span> Estamos experimentando una pequeña falla.',
      'bump-in-the-road':
        'Parece que hay un <span class="{{spanClass}}">chichón</span> en la carretera.',
      'attach-files': 'Adjuntar archivos',
      'attach-file': 'Adjuntar archivo',
      'drop-file-to-attach':
        'Arrastra un archivo para adjuntarlo o <button class={{buttonClass}}>búscalo en tu equipo.</button>',
      'drop-files-to-attach':
        'Arrastra el archivo para adjuntarlos o <button class={{buttonClass}}>búscalo en tus archivos.</button>',
      'browse-files': 'Búsqueda de archivos',
      cancel: 'Cancelar',
      'replace-file-confirmation':
        '¿Está seguro de que desea reemplazar el siguiente archivo?',
      with: 'Con',
      replace: 'Reemplazar',
      'no-files-attached': 'No se adjuntó ningún archivo.',
      'please-ensure-file-formats':
        'Por favor, asegúrate de que tus archivos estén en alguno de los siguientes formatos',
      'please-ensure-file-size': 'Asegúrese de que sus archivos estén debajo',
      'file-could-not-be-uploaded': 'El siguiente archivo no se pudo cargar',
      'try-again': 'Intenta de nuevo',
      'remove-attachment': 'Eliminar adjunto',
      'show-more': 'Mostrar más',
      'show-less': 'Ver menos',
      loading: 'cargando...',
      'expand-collapse-row': 'Expandir/contraer fila',
      'trouble-logging-in': '¿Tienes problemas para iniciar sesión?',
      'get-help': 'Consigue ayuda',
      'link-to-principal-homepage': 'Bienvenida a la página principal',
      contact: 'Comunícate con nosotros',
      footer: 'Pie de página',
      'terms-of-use': 'Términos de uso',
      disclosures: 'Declaraciones',
      privacy: 'Privacidad',
      security: 'Seguridad',
      'report-fraud': 'Reporte de fraudes',
      'securities-offered':
        'Los valores son ofrecidos a través de Principal Securities, Inc.,',
      'member-sipc': 'miembro de la SIPC',
      'principal-on-twitter': 'Principal en Twitter',
      'principal-on-youtube': 'Principal en YouTube',
      'principal-on-facebook': 'Principal en Facebook',
      'principal-on-linkedin': 'Principal en LinkedIn',
      'back-to-content': 'Volver al contenido',
      'mobile-navigation-menu': 'Menú de navegación móvil',
      'hang-on-a-moment': 'Espera un momento...',
      'few-more-seconds': '¡Solo unos segundos más!',
      'session-expired': 'Tu sesión venció.',
      'logged-out': 'Cerraste sesión.',
      'we-logged-you-out':
        'Para mantener tu cuenta segura, hemos cerrado tu sesión.',
      'not-what-you-wanted': '¿No era lo que querías hacer?',
      'log-back-in': 'Iniciar sesión de nuevo',
      'go-to-first-page': 'ir a la primera página',
      'go-to-previous-page': 'ir a la página anterior',
      'go-to-next-page': 'ir a la página siguiente',
      'go-to-last-page': 'ir a la última página',
      pagination: 'paginación',
      'go-to-page': 'ir a la página',
      'log-out': 'Cerrar sesión',
      'log-in': 'Ingresar',
      'skip-to-content': 'Saltar al contenido',
      search: 'buscar',
      notifications: 'notificaciones',
      messages: 'Mensajes',
      'other-alerts': 'Otras alertas',
      menu: 'Menú',
      'secondary-navigation': 'secundario navigation',
      'logout-alert': '¡Aviso de cierre de sesión!',
      minutes: 'minutos',
      'stay-logged-in': 'Mantener la sesión',
      'error-displaying-table-data': 'Error al mostrar los datos de la tabla',
      seconds: 'segundos',
      'one-moment': 'Un momento',
      'dismiss-the-timer': 'ignora el temporizador',
      'to-save-your-work': ' y guarda tu trabajo.',
      'sorry-something-wrong-session':
        '¡Disculpa! Algo salió mal al tratar de alargar tu sesión. Antes de que se te cierre la sesión, inténtalo nuevamente o ',
      'still-there': '¿Aún estás aquí? Pronto se te cerrará la sesión.',
      'auto-log-you-out-warning':
        'Por la seguridad de tu cuenta, cerraremos tu sesión en ',
      dismiss: 'Ignorar',
      minute: 'minuto',
      'time-remaining': 'El tiempo que te queda es {{time}}',
      progress: 'progreso',
      completed: 'paso completo',
      incomplete: 'paso incompleto',
      'filter-table': 'Filtrar tabla',
      'no-available-table-data': 'No hay datos de tabla disponibles',
      show: 'Mostrar',
      'no-entries': 'No hay registros',
      'number-rows-displayed': 'Número de filas que aparecerán en la tabla',
      of: 'de',
      entries: 'registros',
      entry: 'registro',
      'contact-us': 'Comunícate con nosotros',
      'help-topics': 'Temas de ayuda',
      'call-us-at': 'Llamenos al',
      'principal-legal-links': 'Principales enlaces legales',
      'social-media': 'Medios de comunicación social',
      'utility-menu': 'menú de utilidades',
      'user-menu': 'Menú del Usuario',
      'dropdown-button': 'botón desplegable',
    },
  },
};
