import * as React from 'react';
import { EventName } from '@lit/react';
import { PdsGlobalHeaderUserMenu as PdsGlobalHeaderUserMenuElement } from '@principal/design-system';
import { PdsGlobalHeaderUserMenuProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsGlobalHeaderUserMenu =
  createPdsReactComponent<PdsGlobalHeaderUserMenuProps>()({
    tagName: 'pds-global-header-user-menu',
    elementClass: PdsGlobalHeaderUserMenuElement,
    react: React,
    events: {
      onGlobalHeaderUserMenuOpen:
        'pds-global-header-user-menu-open' as EventName<CustomEvent>,
      onGlobalHeaderUserMenuClose:
        'pds-global-header-user-menu-close' as EventName<CustomEvent>,
      onGlobalHeaderUserMenuTrayClose:
        'pds-global-header-user-menu-tray-close' as EventName<CustomEvent>,
    },
  });
