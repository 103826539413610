export enum EmployeeAssistanceProgramProvision {
    NOT_INCLUDED = 'NOT_INCLUDED',
    CORE = 'CORE',
    PREMIER = 'PREMIER'
}

export namespace EmployeeAssistanceProgramProvision {
    export function findProgramNameFor(employeeAssistanceProgramProvision: EmployeeAssistanceProgramProvision | undefined) {
        if (EmployeeAssistanceProgramProvision.CORE === employeeAssistanceProgramProvision) {
            return 'Principal Core';
        } else if (EmployeeAssistanceProgramProvision.PREMIER === employeeAssistanceProgramProvision) {
            return 'your organization\'s name'
        }
        return '';
    }

    export function findPhoneNumberFor(employeeAssistanceProgramProvision: EmployeeAssistanceProgramProvision | undefined) {
        if (EmployeeAssistanceProgramProvision.CORE === employeeAssistanceProgramProvision) {
            return '800-450-1327';
        } else if (EmployeeAssistanceProgramProvision.PREMIER === employeeAssistanceProgramProvision) {
            return '800-356-7089'
        }
        return '';
    }
}
