export enum ClaimStatus {
    PENDING_REVIEW,
    IN_PROGRESS,
    NEEDS_MORE_INFORMATION,
    APPROVED,
    DENIED
}

const statusButtonTypeByStatus = new Map<ClaimStatus, "default" | "warning" | "info" | "negative">([
    [ClaimStatus.PENDING_REVIEW, "info"],
    [ClaimStatus.IN_PROGRESS, "info"],
    [ClaimStatus.NEEDS_MORE_INFORMATION, "warning"],
    [ClaimStatus.APPROVED, "default"],
    [ClaimStatus.DENIED, "negative"]
])

export namespace ClaimStatus {
    export function findStatusButtonTypeFor(claimStatus: ClaimStatus): "default" | "warning" | "info" | "negative" | undefined {
        return statusButtonTypeByStatus.get(claimStatus)
    }
}