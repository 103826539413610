import * as React from 'react';
import { EventName } from '@lit/react';
import { PdsGlobalHeaderDropdownItem as PdsGlobalHeaderDropdownItemElement } from '@principal/design-system';
import { PdsGlobalHeaderDropdownItemProps } from '../../built-component-props';
import { createPdsReactComponent } from '../../create-pds-react-component';

export const PdsGlobalHeaderDropdownItem =
  createPdsReactComponent<PdsGlobalHeaderDropdownItemProps>()({
    tagName: 'pds-global-header-dropdown-item',
    elementClass: PdsGlobalHeaderDropdownItemElement,
    react: React,
    events: {
      onGlobalHeaderDropdownItemOpen:
        'pds-global-header-dropdown-item-open' as EventName<CustomEvent>,
      onGlobalHeaderDropdownItemClose:
        'pds-global-header-dropdown-item-close' as EventName<CustomEvent>,
    },
  });
