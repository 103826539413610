import { html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { UnsafeHTMLDirective, unsafeHTML } from 'lit/directives/unsafe-html.js';
import { DirectiveResult } from 'lit/async-directive.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './error-page.scss?inline';
import '../layout-container/layout-container';
import '../heading/heading';
import '../text-passage/text-passage';
import '../link/link';

/**
 * @summary This component renders an error page used in simple full-page error scenarios
 */
@customElement('pds-error-page', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsErrorPage extends PdsElement {
  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  firstUpdated() {
    this.setWindowResizeHandler();
    this.getLinkSize();
  }

  /**
   * Style errorCode
   * - **403** renders the error-page used to indicate the authentication failure
   * - **404** renders the error-page used to indicate the broken link or if the page is moved
   * - **500** renders the error-page used to indicate the server is unavailable due to internal configuration issue or a temporary glitch
   * - **503** renders the error-page used to indicate if the site is unreachable, usually due to planned maintenance
   */
  @property()
  errorCode: '403' | '404' | '500' | '503' = '500';

  /**
   * Text can be passed to the link
   */
  @property()
  linkText: String;

  /**
   * A href can be passed to the link
   */
  @property()
  linkHref: String;

  /**
   * variant for layout container
   * - **narrow** renders the layout-container narrower than the default
   */
  @property({ type: String })
  layoutContainerVariant?: 'default' | 'narrow' = 'default';

  /**
   * The content that goes in the text passage within the error page
   * @internal
   */
  @state()
  textPassageContent: String;

  /**
   * The heading for the error page
   * @internal
   */
  @state()
  heading: DirectiveResult<typeof UnsafeHTMLDirective>;

  /**
   * The size of the link in the error page
   * @internal
   */
  @state()
  linkSize: String;

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.errorCode]: !!this.errorCode,
    };
  }

  loadErrorPageContent() {
    if (this.errorCode === '404') {
      this.heading = unsafeHTML(
        this.translateText('well-thats-puzzling', {
          spanClass: this.classEl('styled-text'),
          interpolation: { escapeValue: false },
        }),
      );
      this.textPassageContent = this.translateText('sorry-broken-link');
    } else if (this.errorCode === '403') {
      this.heading = unsafeHTML(
        this.translateText('well-shoot', {
          spanClass: this.classEl('styled-text'),
          interpolation: { escapeValue: false },
        }),
      );
      this.textPassageContent = this.translateText('sorry-no-permission');
    } else if (this.errorCode === '500') {
      this.heading = unsafeHTML(
        this.translateText('uh-oh-small-glitch', {
          spanClass: this.classEl('styled-text'),
          interpolation: { escapeValue: false },
        }),
      );
      this.textPassageContent = this.translateText('please-refresh-browser');
    } else if (this.errorCode === '503') {
      this.heading = unsafeHTML(
        this.translateText('bump-in-the-road', {
          spanClass: this.classEl('styled-text'),
          interpolation: { escapeValue: false },
        }),
      );
      this.textPassageContent = this.translateText('sorry-site-unavailable');
    }
  }

  getLinkSize() {
    if (
      this.responsiveViewportSize === 'xs' ||
      this.responsiveViewportSize === 'sm'
    ) {
      this.linkSize = 'lg';
    } else {
      this.linkSize = 'xl';
    }
  }

  render() {
    return html`<div class=${this.getClass()}>
      <pds-layout-container
        variant="${this.layoutContainerVariant}"
        removePadding="all"
        class="${this.classEl('layout-container')}"
      >
        <div class="${this.classEl('container')}">
          <div class="${this.classEl('content-container')}">
            <div>
              ${this.loadErrorPageContent()}
              <pds-text-passage>
                <span class="${this.classEl('status-code')}"
                  >${this.translateText('error')} ${this.errorCode}</span
                >
                <pds-heading
                  headingTag="h1"
                  variant="display-default"
                  class="${this.classEl('heading')}"
                >
                  ${this.heading}
                </pds-heading>
                <div class="${this.classEl('text-passage')}">
                  ${this.textPassageContent}
                </div>
              </pds-text-passage>
            </div>
            <div>
              <pds-link
                variant="emphasis-inverted"
                href=${this.linkHref}
                size=${this.linkSize}
                ><span>${this.linkText}</span></pds-link
              >
            </div>
          </div>
          <div class="${this.classEl('svg-container')}">
            <div class="${this.classEl('svg')}"></div>
          </div>
        </div>
      </pds-layout-container>
    </div>`;
  }
}
