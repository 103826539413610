import { snakeCaseToCapitalized } from "../../FindKeyByValue";
import { State } from "../../State";

export enum BenefitType {
    ACCIDENT = 'ACCIDENT',
    ACCIDENT_WELLNESS = 'ACCIDENT_WELLNESS',
    CRITICAL_ILLNESS = 'CRITICAL_ILLNESS',
    CRITICAL_ILLNESS_WELLNESS = 'CRITICAL_ILLNESS_WELLNESS',
    HOSPITAL_INDEMNITY_WELLNESS = 'HOSPITAL_INDEMNITY_WELLNESS',
    HOSPITAL_INDEMNITY = 'HOSPITAL_INDEMNITY',
    LIFE = 'LIFE',
    LIFE_WAIVER = 'LIFE_WAIVER',
    LONG_TERM_DISABILITY = 'LONG_TERM_DISABILITY',
    PAID_FAMILY_LEAVE = 'PAID_FAMILY_LEAVE',
    PAID_MEDICAL_LEAVE = 'PAID_MEDICAL_LEAVE',
    SHORT_TERM_DISABILITY = 'SHORT_TERM_DISABILITY'
}

export namespace BenefitType {
    export function hasWaiverOfPremiums(benefitType: BenefitType): boolean {
        return BenefitType.LIFE_WAIVER === benefitType;
    }

    export function findClaimTypeFor(benefitType: BenefitType, contractState: State): string {
        if (benefitType === BenefitType.LIFE_WAIVER) {
            return 'Life continuation during disability'
        } else if ([BenefitType.CRITICAL_ILLNESS, BenefitType.CRITICAL_ILLNESS_WELLNESS].includes(benefitType) && State.NY === contractState) {
            return snakeCaseToCapitalized(benefitType.replace('CRITICAL_ILLNESS', 'SPECIFIED_DISEASE'));
        }
        return snakeCaseToCapitalized(benefitType);
    }

    export function isWellness(benefitType: BenefitType) {
        return [BenefitType.ACCIDENT_WELLNESS, BenefitType.CRITICAL_ILLNESS_WELLNESS, BenefitType.HOSPITAL_INDEMNITY_WELLNESS].includes(benefitType);
    }

    export function isLife(benefitType: BenefitType) {
        return [BenefitType.LIFE, BenefitType.LIFE_WAIVER].includes(benefitType);
    }
}